import "./textarea.css";
// Import React dependencies.
import React, { useCallback, useEffect, useState } from "react";
// Import the Slate editor factory.
import {
  Editor,
  Element,
  Location,
  Node,
  Range,
  Text,
  Transforms,
  createEditor,
} from "slate";
// Import the Slate components and React plugin.
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import {
  ShortCodeToEmote,
  UnicodeToShortCode,
  emoji_rx,
} from "./emoteDatabase";
import { deserializeText } from "./deserializer";
import { serialize } from "./serializer";
import { EmoteComponent } from "../emote/emote";
import emojiAware from 'emoji-aware';
import { useNavigate } from "react-router-dom";



const CodeElement = (props) => {
  return (
    <pre {...props.attributes}>
      <code>{props.children}</code>
    </pre>
  );
};

const DefaultElement = (props) => {
  return <span {...props.attributes}>{props.children}</span>;
};

const ParagraphElement = (props) => {
  return <div {...props.attributes}>{props.children}</div>;
};

const HashtagElement = (props) => {
  return (
    <span className="textAreaHashtag" {...props.attributes}>
      {props.children}
    </span>
  );
};

const CustomEditor = {
  isBoldMarkActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.bold === true,
      universal: true,
    });

    return !!match;
  },

  isCodeBlockActive(editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === "code",
    });

    return !!match;
  },

  toggleBoldMark(editor) {
    const isActive = CustomEditor.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true }
    );
  },

  toggleCodeBlock(editor) {
    const isActive = CustomEditor.isCodeBlockActive(editor);
    Transforms.setNodes(
      editor,
      { type: isActive ? null : "code" },
      { match: (n) => Editor.isBlock(editor, n) }
    );
  },

  insertEmote(editor, url) {
    const element = { type: "emote", url, children: [{ text: "" }] };
    Transforms.insertNodes(editor, element);
  },
};

function EmoteElement(props) {
  const { element } = props;
  const { text } = element.children[0];

  let emoteData = ShortCodeToEmote(String(text).substring(1, text.length - 1));

  if (!emoteData) return <DefaultElement {...props} children={text} />;

  const onClickEmote = (event) => {
    event.preventDefault();
  };

  return (
    <span data-name={text} className="textAreaEmote" {...props.attributes} onClick={onClickEmote}>
      <EmoteComponent emote={text} emoteData={emoteData} />
      {props.children}
    </span>
  );
}

const TextArea = React.forwardRef(({
  readOnly = false,
  placeholder,
  onChange: onChangeProps,
  onKeyDown: onKeyDownProps,
  onKeyUp: onKeyUpProps,
  value = deserializeText(""),
}, ref) => {
  const [editor] = useState(() =>
    withEmoteDetection(withReact(createEditor()))
  );

  function addText(text) {
    Transforms.insertNodes(editor, { text })
  }

  React.useImperativeHandle(ref, () => ({
    addText
  }));

  const [placeholderVisible, setPlaceholderVisible] = useState(true);

  function withEmoteDetection(editor) {
    const { isInline, isVoid } = editor;

    editor.isInline = (element) => {
      return element.type === "emote" ||
        element.type === "hashtag" ||
        element.type === "mention" ||
        element.italic ||
        element.bold
        ? true
        : isInline(element);
    };

    editor.isVoid = (element) => {
      return element.type === "emote" || element.type === "mention"
        ? true
        : isVoid(element);
    };

    const { normalizeNode } = editor;

    editor.normalizeNode = (entry) => {
      const [node, path] = entry;

      switch (node.type) {
        case "hashtag":
          if (node.text.includes(" ")) {
            Transforms.select(editor, path);

            Transforms.insertNodes(editor, [
              { ...node, text: node.text.replaceAll(" ", "") },
              { text: " " },
            ]);
          }
          break;
      }
      // Fall back to the original `normalizeNode` to enforce other constraints.
      normalizeNode(entry);
    };

    return editor;
  }

  const MentionElement = (props) => {
    useEffect(() => {
      const { location } = props.leaf;

      //Transforms.select(editor, location);
    });

    const {text} = props;
    const {text: mention} = text;
    const navigate = useNavigate();

    const handleClick = (event) => {
      const username = mention.slice(1, mention.length);
      navigate("/app/user/" + username);
    }

    return (
      <span className="textAreaMention" {...props.attributes} onClick={handleClick}>
        {props.children}
      </span>
    );
  };

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "code":
        return <CodeElement {...props} />;
      case "emote":
        return <EmoteElement {...props} />;
      case "paragraph":
        return <ParagraphElement {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  // Define a React component to render leaves with bold text.
  const Leaf = (props) => {
    const { leaf } = props;

    return (
      <span
        {...props.attributes}
        style={{
          fontWeight: leaf.bold ? "bold" : "normal",
          fontStyle: leaf.italic ? "italic" : "normal",
        }}
      >
        {props.children}
      </span>
    );
  };

  const renderLeaf = useCallback((props) => {
    switch (props.leaf.type) {
      case "hashtag":
        return <HashtagElement {...props} />;
      case "mention":
        return <MentionElement {...props} />;
      default:
        return <Leaf {...props} />;
    }
  }, []);

  function onKeyDown(event) {
    if(onKeyDownProps)
      onKeyDownProps(event);
    
    if (event.ctrlKey) {
      switch (event.key) {
        // When "`" is pressed, keep our existing code block logic.
        case "`": {
          event.preventDefault();
          CustomEditor.toggleCodeBlock(editor);
          break;
        }

        // When "B" is pressed, bold the text in the selection.
        case "b": {
          event.preventDefault();
          CustomEditor.toggleCodeBlock(editor);
          break;
        }
      }
    }
  }

  function onChange(newValue) {
    let allEmpty = true;

    for (let i = 0; i < newValue.length; i++) {

      const { children } = newValue[i];
      let currentNode = 0;
      children.forEach((node) => {
        let text = node.text;

        let match;

        function emoteParser(text) {
          const emoteRegex = /:(\w+):/g;

          if ((match = emoteRegex.exec(text)) !== null) {
            const { index } = match;
            const end = index + match[0].length;

            const emoteName = match[0];

            if (!ShortCodeToEmote(match[1])) return;

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: index },
              focus: { path: [i, currentNode], offset: end },
            });

            const emoteNode = {
              type: "emote",
              children: [{ text: emoteName }],
            };

            Transforms.insertNodes(editor, [emoteNode, { text: ' ' }]);
          }
        }

        function italicParser(text) {
          const italicRegex = /(?<!\*)\*([^*]+)\*(?!\*)(?!\s*\*)/g;

          if ((match = italicRegex.exec(text)) !== null) {
            if (node.italic) return;
            if (!String(match[1]).trim()) return;

            const { index } = match;
            const text = match[0];
            const end = index + text.length;

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: index },
              focus: { path: [i, currentNode], offset: end },
            });

            Transforms.insertNodes(editor, [
              { italic: true, text },
              { text: " " },
            ]);
          }

          if (node.italic && !italicRegex.exec(text)) {
            const { text } = node;

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: 0 },
              focus: { path: [i, currentNode], offset: text.length },
            });

            Transforms.insertNodes(editor, [{ italic: false, text }]);
          }
        }

        function boldParser(text) {
          const boldRegex = /\*\*([^*]+)\*\*/g;

          if ((match = boldRegex.exec(text)) !== null) {
            if (node.bold) return;
            if (!String(match[1]).trim()) return;

            const { index } = match;
            const end = index + match[0].length;

            const text = match[0];

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: index },
              focus: { path: [i, currentNode], offset: end },
            });

            Transforms.insertNodes(editor, [
              { bold: true, text, index },
              { text: " " },
            ]);
          }

          if (node.bold && !boldRegex.exec(text)) {
            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: 0 },
              focus: { path: [i, currentNode], offset: text.length },
            });

            Transforms.insertNodes(editor, [{ text }]);
          }
        }

        function emoteUnicode(text) {
          var emoji_extract_or_remove_regex = new RegExp(
            "(?:" + emoji_rx + ")+",
            "g"
          );

          if ((match = emoji_extract_or_remove_regex.exec(text)) !== null) {
            const { index } = match;
            const end = index + match[0].length;
            const emoteArray = emojiAware.onlyEmoji(match[0]);

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: index },
              focus: { path: [i, currentNode], offset: end },
            });

            const nodes = [];

            for (let i = 0; i < emoteArray.length; i++) {
              const data = UnicodeToShortCode(emoteArray[i]);

              if (data) {
                nodes.push({
                  type: "emote",
                  children: [{ text: `:${data}:` }],
                });
              }
            }

            nodes.push({ type: "text", text: " " });

            Transforms.insertNodes(editor, nodes);
          }
        }

        function hashtagParser(text) {
          const hashtagRegex = /#[^\s#]+/g;

          if ((match = hashtagRegex.exec(text)) !== null) {
            if (node.type === "hashtag") return;
            if (!String(match[1]).trim()) return;

            const { index } = match;
            const text = match[0];
            const end = index + text.length;

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: index },
              focus: { path: [i, currentNode], offset: end },
            });

            Transforms.insertNodes(editor, [
              { type: "hashtag", text },
              { text: "" },
            ]);
          }

          if (node.type === "hashtag" && !hashtagRegex.exec(text)) {
            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: 0 },
              focus: { path: [i, currentNode], offset: text.length },
            });

            Transforms.insertNodes(editor, [{ text }]);
          }
        }

        function mentionParser(text) {
          const mentionRegex = /@(\w+)/g;

          if ((match = mentionRegex.exec(text)) !== null) {
            if (node.type === "mention") return;
            const { index } = match;
            const end = index + match[0].length;

            const text = match[0];

            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: index },
              focus: { path: [i, currentNode], offset: end },
            });

            Transforms.delete(editor);

            const emoteNode = {
              type: "mention",
              text,
              location: {
                anchor: { path: [i, currentNode], offset: index },
                focus: { path: [i, currentNode], offset: end },
              },
            };

            Transforms.insertNodes(editor, [emoteNode, { text: " " }]);
          }

          if (node.type === "mention" && !mentionRegex.exec(text)) {
            Transforms.select(editor, {
              anchor: { path: [i, currentNode], offset: 0 },
              focus: { path: [i, currentNode], offset: text.length },
            });

            Transforms.insertNodes(editor, [{ text }]);
          }
        }

        mentionParser(text);

        hashtagParser(text);

        emoteUnicode(text);

        emoteParser(text);

        italicParser(text);

        boldParser(text);

        currentNode++;
      });
    }

    if (onChangeProps) {
      onChangeProps(newValue);
    }
  }

  useEffect(() => {
    let allEmpty = true;

    for (let i = 0; i < value.length; i++) {
      const isEmpty = (nodes) => {
        for (let i = 0; i < nodes.length; i++) {
          const n = nodes[i];
          if (n.text) return false;
          if (n.type == "emote") return false;
          if (n.type == "hashtag") return false;
        }

        return true;
      };

      const isTextEmpty = isEmpty(value[i].children);

      allEmpty = allEmpty && isTextEmpty;
    }

    setPlaceholderVisible(allEmpty);
  }, [value])

  function Placeholder() {
    return (
      <div className="textAreaPlaceholder">
        <span>{placeholder}</span>
      </div>
    );
  }

  const handleCopy = (e) => {
    e.preventDefault();
    // Function to get currently selected nodes

    const content = serialize(editor.children, editor.selection);
    navigator.clipboard.writeText(content);
  };

  // Render the Slate context.
  return (
    <Slate value={value} onChange={onChange} editor={editor}>
      {placeholderVisible ? <Placeholder /> : null}
      <Editable
        readOnly={readOnly}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUpProps}
        onCopy={handleCopy}
      />
    </Slate>
  );
});

function InlineArea() {
  
}

export default TextArea;
