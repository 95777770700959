import moment from 'moment/moment';
import styles from './devices.module.css';
import { IconExit } from '../../../../../shared/img/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { SpinnerLoader } from '../../../../../shared/components/SpinnerLoader';
import TranslatableText from '../../../../../localization/components/translatableText';
import { setTitle } from '../../../../../hooks/setTitle';

export function SettingsDevices({ client }) {
    setTitle("Dispositivos conectados");

    const [currentDevice, setCurrentDevice] = useState(null);
    const [otherDevices, setOtherDevices] = useState(null);

    useEffect(() => {
        if (!currentDevice || !otherDevices) {
            client.getDevices().then((response) => {
                const { devices } = response.data;

                const otherDevices = devices.filter((device) => {
                    if (device.IsCurrent) {
                        setCurrentDevice(device);
                        return false;
                    }

                    return true;
                });

                setOtherDevices(otherDevices);
            })
        }
    });

    function handleDelete(id) {
        if (currentDevice.idSession === id) {
            console.log("deleting current session");
            return;
        }

        client.removeDevice(id).then((response) => {
            setOtherDevices(
                otherDevices.filter((device) => {
                    return device.idSession !== id
                })
            );
        })
    }

    if (!otherDevices || !currentDevice) {
        return (
            <div className='flexrow flexcenter' style={{ flex: 1 }}>
                <SpinnerLoader />
            </div>
        )
    }

    return (
        <>
            <h3><TranslatableText k={"settingsMenuUserDevices"} /></h3>
            <span className={styles.tip}><TranslatableText k={"settingsDevicesTip"} /></span>
            <span className={styles.tip}><TranslatableText k={"settingsDevicesWarning"} /></span>

            <div>
                <span className={styles.header}><TranslatableText k={"currentDevice"} /></span>
                <div className={styles.devices}>
                    {
                        currentDevice ? <Device current={true} id={currentDevice.idSession} os={currentDevice.os} clientName={currentDevice.client} location={currentDevice.location} timestamp={currentDevice.updatedAt} /> : null
                    }
                </div>
            </div>

            <div>
                <span className={styles.header}><TranslatableText k={"otherDevices"} /></span>
                <div className={styles.devices}>
                    {
                        otherDevices.map((currentDevice, i) => (
                            <Device handleDelete={handleDelete} id={currentDevice.idSession} key={currentDevice.idSession} os={currentDevice.os || "Unknown"} clientName={currentDevice.client || "Unknown"} location={currentDevice.location || "Unknown"} timestamp={currentDevice.updatedAt} />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

function Device({ id, os, clientName, location, timestamp, handleDelete, current }) {
    return (
        <div className={`flex flexrow ` + styles.device}>
            <div></div>
            <div className={`flex flexcol ` + styles.deviceInfo}>
                <span className={styles.os}>{os} • {clientName}</span>
                <span>{location} • {moment(timestamp).fromNow()}</span>
            </div>
            {
                !current ? (
                    <div onClick={() => handleDelete(id)} className={styles.exit}>
                        <img src={IconExit} />
                    </div>
                ) : null
            }
        </div>
    )
}