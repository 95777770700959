import Notification_POST_COMMENT from "./POST_COMMENT";
import Notification_POST_LIKE from "./POST_LIKE";
import Notification_USER_FOLLOW from "./USER_FOLLOW";

const database = new Map();

database.set("USER_FOLLOW", Notification_USER_FOLLOW);
database.set("COMMENT_CREATE", Notification_POST_COMMENT);
database.set("POST_LIKE", Notification_POST_LIKE);

export default function GetNotificationModel(type) {
    return database.get(type);
}

export function GetNotificationEntityByAlias(notification, alias) {
    for(let i = 0; i < notification.notificationEntities.length; i++) {
        const entity = notification.notificationEntities[i];
        if(entity.alias == alias) return entity;
    }

    return null;
}