import style from './rightMenu.module.css'
import { IconExit, IconSearch } from '../img/icons'
import {useState} from 'react'

export default function Searchbar ({ style: localStyle, value, onChange, placeholder }) {
    return (
        <div className={style.searchbarContainer} style={localStyle} > 
            <img className={style.iconSearch} src={IconSearch} />       
            <input value={value} onChange={(e) => {
                if (onChange) onChange(e.target.value);
            }} className={style.searchBarInput} type='text' placeholder={placeholder} />
        </div>
    )
}