import { Node } from "slate";

const serializeNodes = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("");
};

export function isImmutable(node) {
  return node.type === 'emote';
}

export const serialize = (elements, selection) => {
  if(!selection)
    return elements.map((e) => serializeNodes(e.children)).join("\n");

  let selectedText = "";

  let start = selection.anchor;
  let end = selection.focus;

  if(start.path[0] > end.path[0]) {
    start = selection.focus;
    end = selection.anchor;
  }else if(start.path[0] === end.path[1]) {
    if(start.path[1] > end.path[1]) {
      start = selection.focus;
      end = selection.anchor;
    }
  }

  console.log(elements)
  console.log(selection)

  for(let x = start.path[0]; ; x++) {
    if(x >= elements.length || x > end.path[0]) break;

    const e = elements[x];
    for(let y = start.path[1]; ; y++) {
      if(y >= e.children.length || (y > end.path[1] && x == end.path[0])) break;

      const n = e.children[y];
      if(!n) continue;
      if(isImmutable(n)) {
        selectedText += Node.string(n);
      }else {
        let text = Node.string(n);

        if(x === start.path[0] && y === start.path[1]) text = text.substring(start.offset, text.length);

        if(x === end.path[0] && y === end.path[1]) text = text.substring(0, end.offset);

        selectedText += text;
      }
    }
    selectedText += "\n";
  }

  return String(selectedText).trim();
};
