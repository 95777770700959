import { useEffect, useState } from "react";

export function ContextMenu({ absolute, children, reference, x: propX, y: propY }) {
    const [x, setX] = useState(propX);
    const [y, setY] = useState(propY);


    useEffect(() => {
      setX(propX);
      setY(propY);
  
      const { current } = reference;
  
      if (!current) return;
  
      const clientW = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const clientH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  
      if (propX + current.clientWidth > clientW && !absolute) {
        setX(clientW - current.clientWidth - 20);
      } else {
        setX(propX);
      }
  
      if (propY + current.clientHeight > clientH && !absolute) {
        setY(clientH - current.clientHeight - 5);
      } else {
        setY(propY);
      }
    }, [propX, propY]);
  
    return (
      <div
        ref={reference}
        className="contextMenuContainer"
        style={{ top: `${y}px`, left: `${x}px`, ...(absolute ? {position: "absolute"} : {})}}
      >
        {
            children
        }
      </div>
    );
  }
  