import { useEffect, useState } from "react";

export function useForeignUser(id, client) {
    const [user, setUser] = useState(null);
    const [notFound, setNotFound] = useState(false);

    function handleUserUpdate(newUser) {
        if (newUser.id == user.id) {
            setUser({
                ...user,
                ...newUser
            })
        }
    }

    //atualização assíncrona
    useEffect(() => {
        if (user != null) {
            client.emit('SUBSCRIBE', user.id, 'LOCAL', (success) => {
                //if(success) console.log(user.id + " registered to update");
                //else console.log(user.id + " failed to register");
            });

            client.on("USER_" + user.id, handleUserUpdate)
        }
        return () => {
            if (user != null) {
                client.emit('UNSUBSCRIBE', user.id, 'LOCAL', (success) => {
                    //if(success) console.log(user.id + " unregistered to update");
                    //else console.log(user.id + " failed to unregister");
                })

                client.off("USER_" + user.id, handleUserUpdate)
            }
        }

    }, [user]);

    //requisição
    useEffect(() => {
        if (!user) {
            client.getUserProfile(id).then((response) => {
                setUser(response.data);
            }).catch((err) => {
                switch (err.response.status) {
                    case 404:
                        setNotFound(true);
                        break;
                }
            })
        }
    });

    useEffect(() => {
        setNotFound(false);
    }, [user])

    useEffect(() => {
        setUser(null)
    }, [id])

    return [user, setUser, notFound];
}