import style from "./rightMenu.module.css";
import Separator from "./separator";
import "./components.css";
import Header, { HeaderCounter } from "./RightMenuHeader";
import Searchbar from "./Searchbar";
import useFriendList from "../../hooks/useFriendList";
import CatImages, { CatMetadata } from "../cat";
import Button from "./button";
import { motion } from "framer-motion";
import Avatar from "./Avatar";
import moment from "moment";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconChat, IconProfile, IconTrash } from "../img/icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { ContextMenu } from "./contextMenu/contextMenu";
import LoadingLogo from "./loading";
import { SpinnerLoader } from "./SpinnerLoader";
import { AlertTip } from "./alert/alert";
import { EmoteComponent } from "./emote/emote";
import TranslatableText, { useTranslatableText } from "../../localization/components/translatableText";
import UIEvents from "../js/events/ui";

export default function FriendsMenu({ client }) {
  const [friendSearch, setFriendSearch] = useState("");
  const [friendList, onlineCount] = useFriendList(client);
  const [contextMenu, setContextMenu] = useState(null);
  const contextMenuRef = useRef();

  console.log("friendMenu update");

  function onContextMenu(e, friend) {
    e.preventDefault();

    const { clientX, clientY } = e;

    if (contextMenu) {
      if (contextMenu.friend == friend) return setContextMenu(null);
    }

    setContextMenu({
      x: clientX,
      y: clientY,
      friend,
    });
  }

  useOnClickOutside(contextMenuRef, () => {
    setContextMenu(null);
  });

  const now = moment();

  function friendListSort(a, b) {
    a.userOnline =
      now.diff(moment(a.lastTimeSeen), "minutes") < 1 ? true : false;
    b.userOnline =
      now.diff(moment(b.lastTimeSeen), "minutes") < 1 ? true : false;

    // First, sort by userOnline (true comes before false)
    if (a.userOnline && !b.userOnline) {
      return -1; // `a` comes before `b`
    }
    if (!a.userOnline && b.userOnline) {
      return 1; // `a` comes after `b`
    }

    // If userOnline is the same or both users are offline, sort by displayName
    var nameA = a.displayname.toUpperCase();
    var nameB = b.displayname.toUpperCase();

    if (nameA < nameB) {
      return -1; // `a` comes before `b`
    }
    if (nameA > nameB) {
      return 1; // `a` comes after `b`
    }
    return 0; // Names are equal
  }

  function friendListFilter(a) {
    const search = String(friendSearch).trim();

    if (!search) return true;

    if (a.username.toLowerCase().startsWith(search.toLowerCase())) return true;

    if (a.displayname.toLowerCase().startsWith(search.toLowerCase()))
      return true;

    return false;
  }

  const processedFriendList = friendList
    ? friendList.filter(friendListFilter).sort(friendListSort)
    : null;

  return (
    <>
      {contextMenu ? (
        <FriendContextMenu
          reference={contextMenuRef}
          x={contextMenu.x}
          y={contextMenu.y}
          friend={contextMenu.friend}
        />
      ) : null}
      <div className={style.container}>
        <Header title={<TranslatableText k={"menu_friends"} />}>
          <HeaderCounter>
            <span>{onlineCount}</span>
          </HeaderCounter>
        </Header>
        <Separator />

        <AlertTip style={{ bottom: 20 }}>
          <img
            style={{ verticalAlign: "middle" }}
            src={CatImages.Star}
            height={32}
          />
          <span style={{ textAlign: "justify " }}>
            <b>Dica do {CatMetadata.name}</b>: Quando você e uma outra pessoa se
            seguem mutuamente, ela aparece aqui{" "}
            <EmoteComponent emote={":smile:"} />
          </span>
        </AlertTip>

        <Searchbar
          value={friendSearch}
          onChange={(text) => {
            setFriendSearch(text);
            setContextMenu(null);
          }}
          placeholder={useTranslatableText("friendsSearch")}
        />



        {processedFriendList == null ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            className="flexcenter flexcol full"
          >
            <SpinnerLoader />
          </motion.div>
        ) : processedFriendList.length == 0 ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.8, transformOrigin: "top" }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="flexcenter flexcol full"
          >
            <img
              className={"blackandwhite " + style.rightMenuCat}
              height={350}
              src={CatImages.CatSmile}
            />

            <span style={{ textAlign: "center" }}>
              Você ainda <span style={{ opacity: 0.5 }}>não tem amigos</span>, mas
              não se preocupe, {CatMetadata.name} está aqui com você.
              <br></br>
              <br></br>
              <span style={{ fontSize: "calc(var(--span) - 3px)", opacity: 0.2 }}>
                (✿◡‿◡)
              </span>
            </span>
            <br></br>
            <Button>Procurar amigos</Button>
          </motion.div>
        ) : (
          <div className={style.viewport}>
            <motion.div
            initial={{ opacity: 0, scale: 0.8, transformOrigin: "top" }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="flexcol full"
            style={{ gap: "5px" }}
          >
            {processedFriendList.map((friend, i) => {
              return (
                <Friend
                  onContextMenu={onContextMenu}
                  key={friend.idUser}
                  friend={friend}
                  selected={contextMenu ? contextMenu.friend == friend : false}
                  client={client}
                />
              );
            })}
          </motion.div>
          </div>
        )}
      </div>
    </>
  );
}

function Friend({ friend, onContextMenu, selected, client }) {
  const { username, lastTimeSeen, idProfilePicture, displayname } = friend;

  const navigate = useNavigate();
  const now = moment();

  function handleClick() {
    navigate("/app/user/" + username);
  }

  function handleChat(e) {
    e.stopPropagation();
    client.createConversation(friend.idUser).then((response) => {
      const {idChat} = response.data; //dados da resposta
      UIEvents.emit("rightmenu.chat", idChat);
    })
  }

  return (
    <div
      onContextMenu={(e) => {
        onContextMenu(e, friend);
      }}
      className={
        selected
          ? "rightMenuItemContainer rightMenuItemContainerSelected"
          : "rightMenuItemContainer"
      }
      onClick={handleClick}
    >
      <Avatar
        status={now.diff(lastTimeSeen, "minutes") < 1 ? "online" : "offline"}
        type={"small"}
        height={48}
        id={idProfilePicture}
      />

      <div className="rightMenuItemInfo">
        <div className="flexcol">
          <span className="rightMenuItemUsername">{displayname}</span>
          <span style={{ color: "var(--light3)", fontSize: "12px" }}>
            @{username}
          </span>
        </div>
        <div className="flex flexrow">
          <div className={style.friendButton} onClick={handleChat}>
            <img src={IconChat} height={16} />
          </div>
        </div>
      </div>
    </div>
  );
}

function FriendContextMenu({ reference, x: propX, y: propY, friend }) {
  const navigate = useNavigate();

  function handleProfile() {
    navigate("/app/user/" + friend.username);
  }

  return (
    <ContextMenu x={propX} y={propY} reference={reference}>
      <div className="contextMenuItem" onClick={handleProfile}>
        <span><TranslatableText k={"viewProfile"} /></span>
        <img src={IconProfile} />
      </div>
      <div className="contextMenuItem" onClick={handleProfile}>
        <span className="contextMenuItemDelete"><TranslatableText k={"unfollow"} /></span>
        <img src={IconTrash} />
      </div>
    </ContextMenu>
  );
}
