import { useEffect, useState } from 'react';
import CreatePost from '../../../shared/components/createPost/createPost';
import '../../../shared/css/global.css';
import './home.css';
import Button from '../../../shared/components/button'
import { IconPosts } from '../../../shared/img/icons';
import { RemoteImage } from '../../../shared/components/storage/img';
import TopHighlights from '../../../shared/components/TopHighlights';
import { useNavigate } from 'react-router-dom';
import Post, { PostsEnd, PostsLoading } from '../../../shared/components/post/post';
import TranslatableText, { useTranslatableText } from '../../../localization/components/translatableText';
import { FilterHeaderMenu } from '../../../shared/components/postFilter/postFilter';
import { setTitle } from '../../../hooks/setTitle';
import UsersRecommendation from '../../../shared/components/usersRecommendation/userRecommendation';
import { TrendingTopics } from '../../../shared/components/trendingTopics/tredingTopics';
import Searchbar from '../../../shared/components/Searchbar';
import { RightPanels } from '../../../shared/components/rightPanels/rightPanels';
import CatImages from '../../../shared/cat';
import { useLocalUser } from '../../../hooks/useLocalUser';

export default function Home({ client }) {
    setTitle("Início");

    return (
        <>
            <div className="topBtn"></div>
            <div className='content'>
                <div className='content-main'>
                    <h1 className='highlights-title'><TranslatableText k={"home_featuring"} /></h1>
                    <TopHighlights client={client}></TopHighlights>
                    <main>
                        <MainLeft clientUser={client} />
                        <div className="mainRight">
                            <RightPanels client={client} />
                        </div>
                    </main>
                </div>
            </div>

        </>
    )
}

function MainLeft({ clientUser }) {
    const [page, setPage] = useState(0);

    function handleCreatePost(data) {
        console.log(data)
    }

    return (
        <div className="mainLeft">
            <HeaderFilter page={page} setPage={setPage} clientUser={clientUser} />
            <CreatePost clientUser={clientUser} onCreatePost={handleCreatePost} />
            {
                page === 0 ? (
                    <FollowersPosts clientUser={clientUser} />
                ) : null
            }

            {
                page === 1 ? (
                    <ExplorePosts clientUser={clientUser} />
                ) : null
            }
        </div>
    )
}

function FollowersPosts({ clientUser }) {
    const [posts, setPosts] = useState([]);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const user = useLocalUser(clientUser);
    const MAX = 10;

    function doLoad() {
        if (!hasMore) return;
        clientUser.getFollowingPosts(skip).then((response) => {
            setLoading(false);
            setHasMore(response.data.length >= MAX);
            setPosts((current) => [...current, ...response.data]);
        });
    }

    useEffect(() => {
        const onpostCreate = (post) => {
            setPosts((current) => [post, ...current]);
            setSkip((current) => current + 1);
        }

        clientUser.on("POST_" + user.id + "_CREATE", onpostCreate)
        
        return () => {
            clientUser.off("POST_" + user.id + "_CREATE", onpostCreate)
        }
    }, [clientUser, user])

    useEffect(() => {
        setLoading(true);
        doLoad();
    }, [skip]);

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        function onScroll(e) {
            if (loading || !hasMore) return;
            if ((e.target.scrollingElement.scrollTop / (e.target.scrollingElement.scrollHeight - e.target.scrollingElement.clientHeight)) > 0.75) {
                setLoading(true);
                setSkip((current) => current + MAX);
            }
        }
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    })

    return (
        <>
            {
                posts.length === 0 && !loading ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ alignSelf: "center", flexDirection: "column", display: "flex", alignItems: "center" }}>
                            <img src={CatImages.SadCatBox} width={150} />
                            <span style={{ opacity: 0.5 }}>Ninguém que você segue realizou uma publicação.</span>
                        </div>
                    </div>
                ) : null
            }

            <div className='posts'>
                {
                    posts ? posts.map((post) => {
                        return (<Post clientUser={clientUser} post={post} key={post.idPost} />)
                    }) : null
                }

                {
                    loading ? (
                        <PostsLoading />
                    ) : null
                }

                {
                    !hasMore ? (
                        <>
                            <PostsEnd />
                        </>
                    ) : null
                }
            </div>
        </>
    )
}

function ExplorePosts({ clientUser }) {
    const [posts, setPosts] = useState([]);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const MAX = 10;

    function doLoad() {
        if (!hasMore) return;
        clientUser.getExplorePosts(skip).then((response) => {
            setLoading(false);
            setHasMore(response.data.length >= MAX);
            setPosts((current) => [...current, ...response.data]);
        })
    }

    useEffect(() => {
        setLoading(true);
        doLoad();
    }, [skip]);

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        function onScroll(e) {
            if (loading || !hasMore) return;
            if ((e.target.scrollingElement.scrollTop / (e.target.scrollingElement.scrollHeight - e.target.scrollingElement.clientHeight)) > 0.75) {
                setLoading(true);
                setSkip((current) => current + MAX);
            }
        }
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    })


    return (
        <div className='posts'>
            {
                posts ? posts.map((post) => {
                    return (<Post clientUser={clientUser} post={post} key={post.idPost} />)
                }) : null
            }

            {
                loading ? (
                    <PostsLoading />
                ) : null
            }

            {
                !hasMore ? (
                    <PostsEnd />
                ) : null
            }
        </div>
    )
}

function HeaderFilter({ clientUser, page, setPage }) {
    const [menuFiltersVisible, setMenuFiltersVisible] = useState(false);
    const [filters, setFilters] = useState([-1, -1, -1]);

    function handleFilters() {
        setMenuFiltersVisible(!menuFiltersVisible);
    }

    function setFilterValue(filterId = 0, value = 0) {
        const newFilters = [...filters];
        newFilters[filterId] = value;
        setFilters(newFilters);
    }

    return (
        <div className={'homeFilters'}>
            <Button onClick={handleFilters} padding={"5px 20px"}>
                <img src={IconPosts} height={16} />
                &nbsp;
                <TranslatableText k={"filter"} />
            </Button>

            <FeedPagesHeader page={page} setPage={setPage} clientUser={clientUser} />

            <FilterHeaderMenu onClose={() => setMenuFiltersVisible(false)} setFilterValue={setFilterValue} filters={filters} open={menuFiltersVisible} />
        </div>
    )
}

function FeedPagesHeader({ clientUser, page, setPage }) {
    const pages = [
        {
            id: 0,
            name: useTranslatableText("following")
        },
        {
            id: 1,
            name: useTranslatableText("explore")
        }
    ]

    return (
        pages.map((value) => (
            <span key={value.id} onClick={() => setPage(value.id)} className='feedPageHeaderItem' data-selected={String(page === value.id)}>
                {value.name}
            </span>
        ))
    )
}
