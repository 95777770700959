import messages from "emojibase-data/en/messages.json";
import data from "emojibase-data/en/data.json";
import styles from "./picker.module.css";
import Searchbar from "../Searchbar";
import { EmoteRaw } from "./emote";
import { useEffect, useState, useRef } from "react";
import { IconRightArrow } from "../../img/icons";
import {motion} from 'framer-motion';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

export function EmotePicker({ open, onClick, onClose, style }) {
  const [emotes, setEmotes] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!emotes) {
      const emoteList = [];
      for (let i = 0; i < data.length; i++) {
        const emote = data[i];
        if (!isNaN(emote.group)) {
          if (!emoteList[emote.group]) emoteList[emote.group] = [];
          emoteList[emote.group].push(emote);
        }
      }
      setEmotes(emoteList);
    }
  }, [emotes]);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (!containerElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.style.backgroundImage = `url(${img.dataset.src})`;
            observer.unobserve(img);
          }
        });
      },
      { root: containerElement }
    );

    const images = containerElement.querySelectorAll("div[data-src]");
    images.forEach((img) => observer.observe(img));

    return () => {
      observer.disconnect();
    };
  }, [containerRef.current, open]);

  useOnClickOutside(containerRef, (e) => {
    onClose();
  }) 

  if (!open) return (null);

  return (
    <div className={styles.container} ref={containerRef} onClick={(e) => e.stopPropagation()} style={style}>
      <div className={styles.viewport}>
        {emotes
          ? messages.groups.map((value, index) => (
            <EmoteGroup
              key={index}
              title={value.message}
              emotes={emotes[index]}
              onClick={onClick}
            />
          ))
          : null}
      </div>
    </div>
  );
}

function EmoteGroup({ title, emotes, onClick }) {
  const [minimized, setMinimized] = useState(false);

  function handleMinimize() {
    setMinimized(!minimized);
  }

  return (
    <div className={styles.group}>
      <span className={styles.title} onClick={handleMinimize}>
        {title}
        <motion.img style={{marginLeft: 8}} animate={minimized ? {rotateZ: "0deg"} : {rotateZ: "90deg"}} src={IconRightArrow} height={8} />
      </span>

        <div style={minimized ? { display: "none" } : {}} className={styles.emotes}>
          {emotes.map((value) => {
            return <div onClick={() => onClick(value)} key={value.hexcode} className={styles.emoteContainer}>
              <EmoteRaw hexCode={value.hexcode} />
            </div>;
          })}
        </div>

    </div>
  );
}
