import './userRecommendation.css'
import { RemoteImage } from "../storage/img";
import { useNavigate } from 'react-router-dom';
import Button from "../button";
import { useEffect, useState } from 'react';
import LoadingLogo from '../loading';
import ProfileAvatar from '../../../shared/img/profile.jpeg';
import CatImages from '../../cat';

export default function UsersRecommendation({ title, client }) {
    const [recommendations, setRecomendations] = useState(null);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const LIMIT = 10;

    useEffect(() => {
        if (client && hasMore) {
            setLoading(true);
            client.getUserRecommendations(skip, LIMIT).then((res) => {
                setLoading(false);
                const { recommendations: newRecommendations } = res.data;
                setHasMore(newRecommendations.length >= LIMIT);

                setRecomendations([...(recommendations || []), ...(newRecommendations || [])]);
            });
        }
    }, [skip, client, hasMore]);

    function handleSeeMore() {
        setSkip((current) => current + LIMIT);
    }

    return (
        <div className='usersRecommendation'>
            <div className='topTitle'>
                <p className='recommendationTitle'>{title}</p>
            </div>
            <div className='recomended'>
                {
                    recommendations ? (
                        recommendations.map((pessoa) => (
                            <Recommendation client={client} key={pessoa.idUser} data={pessoa}></Recommendation>
                        ))
                    ) : null
                }
            </div>

            {
                loading ? (
                    <LoadingLogo height={48} />
                ) : null
            }

            {
                !hasMore ? (
                    <div className='noMore'>
                        <img src={CatImages.CatSmile} alt='CatSmile' height={64} />
                        <span>Não encontramos mais nenhum usuário para te recomendar.</span>
                        <span style={{opacity: 0.3}}>Você já é amigo de todos os caras legais 😎</span>
                    </div>
                ) : null
            }

            {
                hasMore && !loading ? (
                    <div className='seeMore' onClick={handleSeeMore}>
                        <span>Ver mais</span>
                    </div>
                ) : null
            }
        </div>
    )
}

function Recommendation({ data, client }) {
    const [following, setFollowing] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function handleClick(e) {
        e.stopPropagation();
        e.preventDefault();
        if (loading) return;
        setLoading(true);
        if (!following) {
            client.followUser(data.username).then((res) => {
                setFollowing(Boolean(res.data.value));
                setLoading(false);
            });
        } else {
            client.followUser(data.username).then((res) => {
                setFollowing(Boolean(res.data.value));
                setLoading(false);
            });
        }
    }

    return (
        <div className='recommendedUser' onClick={() => navigate(`/app/user/${data.username}`)}>
            <div className='recommendedUserContainer'>
                <div className='recommendedUserLeft'>
                    <div className='recommendedUserAvatar'>
                        <RemoteImage src={ProfileAvatar} id={data.idProfilePicture} />
                    </div>
                </div>
                <div className='recommendedUserCenter'>
                    <p className='displaynameText'>{data.displayname}</p>
                    <p className='usernameText'>@{data.username}</p>
                </div>
                <div className='recommendedUserRight'>
                    <Button style={!following && !loading ? { backgroundColor: "var(--purple2)" } : { opacity: 0.5 }} onClick={handleClick} fontSize={"clamp(15px, 0.5vw, 20px)"} padding={"clamp(5px, 1vw, 5px) clamp(5px, 1.5vw, 30px)"}>
                        {
                            loading ? (
                                "..."
                            ) : (following ? ("Seguindo") : ("Seguir"))
                        }
                    </Button>
                </div>
            </div>
            <div className='commonContainer'>
                {
                    data.commonFollower ? (
                        <span className='commonText'>Seguido(a) por {data.commonFollowerDisplayName}</span>
                    ) : (null)
                }
                {
                    data.commonTag ? (
                        <span className='commonText'>Acompanha também a Tag {data.commonTag}</span>
                    ) : (null)
                }
            </div>
            <div className='separator'></div>
        </div>

    )
}