import { Dialog } from '../../../../shared/components/dialog'
import DrawIcon from '../icons/games/draw.png';
import styles from './watch.module.css';

export function RoomGamesDialog({ roomClient, roomData, open, onClose }) {
    const list = [
        {
            id: "draw",
            name: "Desenhe e Acerte",
            icon: DrawIcon,
            description: "Desenhe e acerte a palavra com base em um desenho",
        },
    ]

    if(!roomData) return null;

    return (
        <Dialog open={Boolean(open)} onClose={onClose}>
            <div style={{ display: "flex", flexDirection: "column", gap: 2, width: 300, maxHeight: `90vh`, overflow: `auto`, padding: `10px 0px` }}>
                <span style={{ fontSize: 20 }}>O que você deseja jogar  ?</span>
                <span style={{ fontSize: 12, opacity: 0.5 }}>em {roomData.room.roomName}</span>

                <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 8 }}>
                    {
                        list.map((item) => {
                            function handleStartActivity() {
                                roomClient.emit("ACTIVITY_START", item.id, (success) => {
                                    console.log(success)
                                    onClose();

                                });
                            }

                            return (
                                <div onClick={handleStartActivity} className={item.testing ? `${styles.dialogItem} ${styles.dialogItemTesting}` : styles.dialogItem}>
                                    <img className={styles.dialogIcon} width={200} src={item.icon} />
                                    <span className={styles.dialogTitle}>{item.name} -                                 <span className={styles.dialogDescription}>{item.description}</span></span>
                                    {
                                        item.testing ? <span className={styles.dialogTesting}>* Não disponível</span> : null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Dialog>
    )
}