import { useEffect, useState } from "react";

export default function useNewNotifications(client, timeToHide = 10 * 1000) {
    const [notifications, setNotifications] = useState([
       
    ]);

    useEffect(() => {
        function onNotification(data) {
            // Adicionar a nova notificação ao estado
            setNotifications((prevNotifications) => [data, ...prevNotifications]);

            // Definir um temporizador para remover essa notificação após timeToHide ms
            setTimeout(() => {
                setNotifications((prevNotifications) => prevNotifications.filter(notification => notification !== data));
            }, timeToHide);
        }

        client.on("NOTIFICATION", onNotification);

        return () => {
            client.off("NOTIFICATION", onNotification);
        }
    }, [client, timeToHide]); // Adiciona client e timeToHide como dependências

    return notifications;
}
