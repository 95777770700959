import { useEffect } from "react";

export default function useOnClickOutside(ref, callback) {
    useEffect(() => {
        function onClick(e) {
            if(e.target.dataset['outsideOverriable'] === 'true') return;

            if (ref) {
                if (ref.current) {
                    if (callback && !ref.current.contains(e.target)) {
                        callback(e);
                    }
                }


            }
        }

        window.addEventListener("click", onClick);

        return () => {
            window.removeEventListener("click", onClick);
        }
    }, [ref])
}