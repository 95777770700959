import { useEffect, useState, useRef } from 'react';
import '../css/global.css';
import '../../pages/app/home/home.css';
import GlassButton from './glassButton';
import Tagg from '@taggapp/taggjs-main';
import CatImages from '../cat';
import { IconRightArrow, IconLeftArrow } from '../img/icons';
import { RemoteImage } from './storage/img';
import { useNavigate } from 'react-router-dom';

function SkeletonTopHighlights() {
    const value = [];

    for (let i = 0; i < 10; i++) {
        value.push(<SkeletonBox key={i} />)
    }

    return (
        <div className='highlightsBox'>
            <div className='highlights'>
                <div className='highlightsBoxesVisual'>
                    <div className='highlightsBoxes'>
                        {
                            value
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default function TopHighlights({client}) {
    const [highlights, setHighlights] = useState(null)

    useEffect(() => {
        if (!highlights) {
            client.getTrendingTags().then((response) => {
                setHighlights(response.data);
            })
        }
    })

    return (
        <TagListContainer tagList={highlights} />
    )
}

export function TagListContainer({ tagList }) {
    const [highlights, setHighlights] = useState(tagList)
    const [scrollLeft, setScrollLeft] = useState(0);
    const boxRefItem = useRef();
    const boxRefVisual = useRef();

    useEffect(() => {
        if (tagList) {
            setHighlights(tagList);
        }
    }, [tagList]);

    useEffect(() => {
        function resizeWindow() {
            setHighlights(
                [
                    ...tagList
                ]
            )
        }

        window.addEventListener("resize", resizeWindow);

        return () => {
            window.removeEventListener("resize", resizeWindow);
        }
    })

    function Add() {
        const size = boxRefItem.current.clientWidth;
        const currentScroll = boxRefVisual.current.scrollLeft;
        const add = ((size + 30) + currentScroll);
        boxRefVisual.current.scroll({ left: add, behavior: 'smooth' });
        setScrollLeft(Math.min(add, boxRefVisual.current.scrollWidth - boxRefVisual.current.clientWidth));
    }

    function Sub() {
        const size = boxRefItem.current.clientWidth;
        const currentScroll = boxRefVisual.current.scrollLeft;
        const add = ((size + 30) * -1 + currentScroll);
        boxRefVisual.current.scroll({ left: add, behavior: 'smooth' });
        setScrollLeft(Math.max(add, 0));
    }

    let gradients = { l: true, r: true }

    if (boxRefVisual.current) {
        if (boxRefVisual.current.scrollWidth === boxRefVisual.current.clientWidth) {
            gradients['l'] = false;
            gradients['r'] = false;
        } else {
            const percentage = scrollLeft / (boxRefVisual.current.scrollWidth - boxRefVisual.current.clientWidth);

            if (percentage == 0) gradients['l'] = false;
            if (percentage == 1) gradients['r'] = false;
        }
    } else {
        gradients['l'] = false;
        gradients['r'] = true;
    }

    if (!highlights) return <SkeletonTopHighlights />;

    return (
        <div className='highlightsBox'>
            <div className='highlights'>
                {gradients['l'] ? (<div className={`gradient gradientL`} />) : (null)}
                {gradients['r'] ? (<div className={`gradient gradientR`} />) : (null)}
                <div className='glassBtns'>
                    {
                        gradients['l'] ? <GlassButton style={{ marginRight: "auto" }} children={<img className='iconGlassBtn' src={IconLeftArrow}></img>} onClick={Sub}></GlassButton> : (null)
                    }
                    {
                        gradients['r'] ? <GlassButton style={{ marginLeft: "auto" }} children={<img className='iconGlassBtn' src={IconRightArrow}></img>} onClick={Add}></GlassButton> : (null)
                    }
                </div>
                <div className='highlightsBoxesVisual' ref={boxRefVisual}>
                    <div className='highlightsBoxes'>
                        {
                            highlights.map((value) => (
                                <Box key={value.idTag} reference={boxRefItem} data={value} />
                            ))
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

function SkeletonBox() {
    return (
        <div className="highlightsBoxesItem highlightsBoxesItemSkeleton">
            <span></span>

            <div className={"highlightsBoxesItemImage"}>
                <div className={"highlightsBoxesItemImagebg"} />
            </div>
        </div>
    )
}

function Box({ data, reference }) {
    const navigate = useNavigate();

    function handleClick() {
        navigate("/app/tag/" + data.idTag);
    }

    return (
        <div onClick={handleClick} ref={reference} className="highlightsBoxesItem">
            <span>{data.tagName}</span>

            <div className={"highlightsBoxesItemImage"}>
                <div className={"highlightsBoxesItemImagebg"} />
                <RemoteImage id={data.filePath} height={500} />
            </div>
        </div>
    )
}