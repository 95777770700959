import { useState } from 'react';
import SpinnerLoader from '../../img/spinnerloading.svg';
import { useEffect } from 'react';

const STORAGE_URL = process.env.STORAGE_URL || 'https://cdn.tagg.chat';

export function RemoteImage({ id, src, imageStyle, width, height, options = {}, style={width: "100%", height: "100%"} }) {
    const [image, setImage] = useState(null);
    
    useEffect(() => {
        if(!image) {
            const img = new Image();

            const params = new URLSearchParams({
                width: options.width || width,
                height: options.height || height
            });
        
            img.src = id ? `${STORAGE_URL}${id}?${params.toString()}` : src;

            img.onload = () => {
                setImage(img);
            }
        }
    }, [image]);

    useEffect(() => {
        setImage(null);
    }, [src, id])

    return (
        <div style={{width, height, display: "flex", alignItems: "center", justifyContent: "center", ...style}}>
            {
                image ? (<img style={{flex: 1, maxHeight: height, maxWidth: width, width: "100%", height: "100%", objectFit: "cover", ...imageStyle}} src={image.src} />) : (<img style={{flex: 1, maxHeight: 64, maxWidth: 64}} src={SpinnerLoader} />)
            }
        </div>
    )
}