import { useNavigate } from "react-router-dom";
import { IconExternalLink } from "../img/icons";
import styles from './components.css';

export function ReferenceLink({href, children}) {
    const navigate = useNavigate();

    return (
        <span onClick={() => navigate(href)} className={"referenceLink"}>
            <img height={24} src={IconExternalLink} />
            {children}
        </span>
    )
}