import { useEffect, useState } from "react";
import { GetNotificationEntityByAlias } from ".";
import './notifications.css';
import Avatar from "../Avatar";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function Notification_POST_COMMENT({ data }) {
    const [comment, setComment] = useState(null);
    const [now] = useState(moment());
    const [commentText, setCommentText] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!comment) {
            const entity = GetNotificationEntityByAlias(data, "COMMENT");
            setComment(entity.postComment);
        }
    }, [data]);

    useEffect(() => {
        if (comment) {
            setCommentText(comment.commentContent);
        }
    }, [comment])

    if (comment == null || !commentText) return (null);

    function handleClick() {
        navigate("/app/user/" + comment.user.username);
    }

    const date = moment(data.createdAt);
    const lastTimeSeen = moment(comment.user.lastTimeSeen);

    return (
        <div className="rightMenuItemContainer" onClick={handleClick}>
            <Avatar status={now.diff(lastTimeSeen, "minutes") < 1 ? "online" : "offline"} type={"small"} height={48} id={comment.user.idProfilePicture} />

            <div className="rightMenuItemInfo">
                <span className="rightMenuItemUsername">
                    {comment.user.username}
                    &nbsp;
                    <span className="notificationContent">comentou em sua postagem:
                    {commentText.length > 20 ? " \"" + commentText.substring(0, 20) + '...' : " \"" + commentText + "\""}
                    </span>
                    &nbsp;
                    <span className="notificationTime">• {date.fromNow()}</span>
                </span>
            </div>
        </div>
    )
}