import { useEffect, useState } from "react";

export default function useNotifications(client) {
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        function onNotification(data) {
            if(notifications) {
                setNotifications(
                    [
                        data,
                        ...notifications,
                    ]
                )
            }
        }

        client.on("NOTIFICATION", onNotification);

        if(!notifications) {
            client.getNotifications().then((response) => {
                const { notifications } = response.data;
                setNotifications(notifications);
            })
        }

        return () => {
            client.off("NOTIFICATION", onNotification);
        }
    })

    function handleClearNotifications() {
        client.clearNotifications().then((response) => {
            setNotifications([]);
        })
      }

    return [notifications, handleClearNotifications];
}