import style from "./rightMenu.module.css";
import { IconExit } from "../img/icons";
import UIEvents from "../js/events/ui";

export default function Header({ style: estilo, title, children }) {
  const handleClose = () => {
    UIEvents.emit("right_menu.location", null);
  };

  return (
    <div className={style.header} style={estilo}>
      <span className={style.topHeader}>
        <h1 className={style.title}>{title}</h1>
        {children}
      </span>
      <img onClick={handleClose} className={style.exitIcon} src={IconExit} />
    </div>
  );
}

export function HeaderCounter({children}) {
    return (
        <div className={style.headerCounter}>
            {children}
        </div>
    )
}