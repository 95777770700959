import { useEffect, useState } from "react";
import { useLocalUser } from "./useLocalUser";

export default function useChats(client) {
    const [currentChats, setCurrentChats] = useState([]);
    const [pendingChats, setPendingChats] = useState([]);
    const user = useLocalUser(client);

    useEffect(() => {
        function onConversationMessage(data) {
            setCurrentChats((current) => {
                const novo = current.map((value) => {
                    if(value.idChat === data.idChat) {
                        return {
                            ...value,
                            messages: [
                                data
                            ],
                            updatedAt: new Date(),
                            unreadMessages: user.id === data.user.idUser ? 0 : value.unreadMessages + 1
                        }
                    }

                    return value;
                });
                
                return novo;
            });

            setPendingChats((current) => {
                const novo = current.map((value) => {
                    if(value.idChat === data.idChat) {
                        return {
                            ...value,
                            messages: [
                                data
                            ],
                            updatedAt: new Date(),
                            unreadMessages: user.id === data.user.idUser ? 0 : value.unreadMessages + 1
                        }
                    }

                    return value;
                });
                
                return novo;
            });
        }

        function onConversation(data) {
            if(data.accepted) {
                setCurrentChats((current) => {
                    return [data, ...current]
                })

                setPendingChats((current) => {
                    return current.filter((a) => a.idChat !== data.idChat)
                });
            }else {
                setPendingChats((current) => {
                    return [data, ...current]
                })

                setCurrentChats((current) => {
                    return current.filter((a) => a.idChat !== data.idChat)
                });
            }
        }

        if(client) {
            client.on("CONVERSATION_MESSAGE", onConversationMessage);
            client.on("CONVERSATION", onConversation);
        }

        return () => {
            if(client) {
                client.off("CONVERSATION_MESSAGE", onConversationMessage);
            }
        }
    }, [client, user])

    useEffect(() => {
        client.getConversations(false).then((response) => {
            const {conversations} = response.data;
            setCurrentChats(conversations);
        })

    }, [])

    useEffect(() => {
        client.getConversations(true).then((response) => {
            const {conversations} = response.data;
            setPendingChats(conversations);
        })

    }, [])

    return [currentChats, setCurrentChats, pendingChats, setPendingChats];
}