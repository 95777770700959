import { UNSAFE_NavigationContext, useLocation, useNavigate, useNavigation } from "react-router-dom";
import useEditProfile from "../../../../../hooks/useEditProfile";
import { useLocalUser } from "../../../../../hooks/useLocalUser";
import { ContainedTextInput } from "../../../../../shared/components/textinput";
import { useEffect } from "react";
import React from "react";
import Button from "../../../../../shared/components/button";
import { BottomContainer } from "../../../../../shared/components/bottomContainer/bottomContainer";
import { AnimatePresence } from "framer-motion";
import styles from './account.module.css';
import { ReferenceLink } from "../../../../../shared/components/referenceLink";
import TranslatableText from "../../../../../localization/components/translatableText";
import { setTitle } from "../../../../../hooks/setTitle";

export function SettingsAccount({ client }) {
    setTitle("Configurações de Conta");

    const localUser = useLocalUser(client);
    const location = useLocation();
    const navigate = useNavigate();

    const [settingsChanges, allValues, addChange, resetChanges] = useEditProfile(
        localUser ?
            {
                'displayname': localUser.displayname || ''
            }
            : {},
        localUser
    );

    console.log(localUser)

    function handleDisplayNameChange(value) {
        addChange("displayname", value);
    }

    function handleResetChanges() {
        resetChanges();
    }

    function handleSave() {
        client.editAccount(settingsChanges).then(() => {
            
        }).catch((err) => {
            console.error(err);
        })
    }

    const changesCount = Object.keys(settingsChanges).length;
    const hasChanges = changesCount > 0;

    const { displayname } = allValues;

    const verifiedDisplayName = (!displayname.includes("@") && displayname.length <= 20 && displayname.length > 0 && displayname != null)

    return (
        <>
            <h2><TranslatableText k={"settingsMenuUserAccount"} /></h2>
            <div>
                <ContainedTextInput max={20} verified={verifiedDisplayName} errorText={"Insira um nome de exibição válido."} onChange={handleDisplayNameChange} value={allValues.displayname} placeholder={<TranslatableText k={"displayName"} />} />
            </div>

           {
            /*
                 <div>
                <h5 className={styles.header}><TranslatableText k={"accountRemoval"} /></h5>
                <span className={styles.tip}><TranslatableText k={"accountDisableTip"} /></span>
                <div className="flex flexrow" style={{ marginTop: 10, gap: 10 }}>
                    <DisableAccountButton />
                    <DeleteAccountButton />
                </div>
            </div>
            */
           }

            <div className="flex flexcol">
                <ReferenceLink href={"/app/user/" + localUser.username}><TranslatableText k={"changeProfilePicture"} /></ReferenceLink>
                <ReferenceLink href={"/app/user/" + localUser.username}><TranslatableText k={"changeProfileBanner"} /></ReferenceLink>
            </div>

            <AnimatePresence mode={"wait"}>
                {
                    hasChanges ? <SaveChanges handleSave={handleSave} handleResetChanges={handleResetChanges} TotalChanges={changesCount} /> : null
                }
            </AnimatePresence>
        </>
    )
}

function SaveChanges({ TotalChanges, handleResetChanges, handleSave }) {
    return (
        <BottomContainer>
            <span>Cuidado — você tem {TotalChanges > 1 ? (TotalChanges + " alterações que não foram salvas.") : "1 alteração que não foi salva."}</span>
            <Button onClick={handleResetChanges}>Redefinir</Button>
            <Button onClick={handleSave}>Salvar</Button>
        </BottomContainer>
    )
}

function DisableAccountButton({ onClick }) {
    return (
        <div onClick={onClick} className={styles.disableAccountButton}>
            <TranslatableText k={"disableAccount"} />
        </div>
    )
}

function DeleteAccountButton({ onClick }) {
    return (
        <div onClick={onClick} className={styles.deleteAccountButton}>
            <TranslatableText k={"deleteAccount"} />
        </div>
    )
}