import './components.css'
import Logo from './logo'
import profile from '../../shared/img/profile.jpeg'
import { IconBell, IconChat, IconConfig, IconHome, IconProfile, IconProfile2User, IconProfile3User, IconTag, IconHamburger } from '../img/icons'
import { useEffect, useRef, useState } from 'react'
import UIEvents from '../js/events/ui';
import { useLocation, useMatch, useNavigate } from 'react-router-dom'

import NotificationsMenu from './NotificationsMenu'
import FriendsMenu from './FriendsMenu'
import ChatsMenu from './ChatsMenu'
import RoomsMenu from './RoomsMenu'
import TagsMenu from './TagsMenu'
import IndividualChat from './individualChat/individualChat'

import Avatar from './Avatar'
import { AnimatePresence, motion } from 'framer-motion';
import FileIDToStorageURL from '../js/storage'
import { useLocalUser } from '../../hooks/useLocalUser'
import UserStats from './UserStats'
import { UsersRecent } from './recent/recent'
import { useTranslatableText } from '../../localization/components/translatableText'
import useFriendList from '../../hooks/useFriendList'

export default function SideMenu({ client }) {
    const user = useLocalUser(client);
    const [closed, setClosed] = useState(false);
    const [hovering, setHovering] = useState(false);
    const location = useLocation();
    const itemsMenuContainer = useRef();
    const rightMenuContainer = useRef();
    const [friendList, friendListCount] = useFriendList(client);

    const [rightMenuLocation, setRightMenuLocation] = useState(null);

    useEffect(() => {
        function onRightMenu(newLocation) {
            setRightMenuLocation(newLocation);
            setClosed(false);
        }

        UIEvents.on("right_menu.location", onRightMenu);

        return () => {
            UIEvents.off("right_menu.location", onRightMenu)
        }
    }, [client])

    useEffect(() => {
        setRightMenuLocation(null);
        setClosed(true);
        setHovering(false)
    }, [location])

    const menuItems = [
        {
            icon: IconProfile,
            item: useTranslatableText("menu_profile"),
            path: 'user/' + user.username,
            isURLOriented: true,
            pattern: 'user/' + user.username,
        },
        {
            icon: IconBell,
            item: useTranslatableText("menu_notifications"),
            path: 'notifications/'
        },
        {
            icon: IconHome,
            item: useTranslatableText("menu_home"),
            path: '',
            isURLOriented: true,
            pattern: '',
        },
        {
            icon: IconProfile2User,
            item: useTranslatableText("menu_friends"),
            path: 'friends/',
            count: friendListCount
        },
        {
            icon: IconChat,
            item: useTranslatableText("menu_conversations"),
            path: 'chats/'
        },
        {
            icon: IconProfile3User,
            item: useTranslatableText("menu_interactrooms"),
            path: 'rooms/',
        },
        {
            icon: IconTag,
            item: useTranslatableText("menu_tags"),
            path: 'tags/',
        },
        {
            icon: IconConfig,
            item: useTranslatableText("menu_settings"),
            path: 'settings/',
            pattern: 'settings/*',
            isURLOriented: true
        },
    ]

    if (!user) return (null)

    function onHover(e) {
        if (e.pointerType == "touch") return;
        if (hovering) return;
        setHovering(true);
        setClosed(false)
    }

    function onHoverEnd(e) {
        if (rightMenuLocation) return;
        if (e.pointerType == "touch") return;
        if (!hovering) return;
        if (e.target.id == 'usersRecent') return;
        setHovering(false);
        setClosed(true);
    }

    function onClickBackground(e) {
        e.stopPropagation();
        e.preventDefault();
        setClosed(true);
        setRightMenuLocation(null);
        setHovering(false);
    }

    function onClickLeftMenu(e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.pointerType === "mouse") return;
        if (rightMenuLocation) return;
        if (e.target.dataset.ignoreClick === 'true') return;
        setClosed(!closed);
        if (!closed) {
            setHovering(true)
        }
    }

    let view = (null);

    if (closed) {
        view = (
            <div className='side-menu-background side-menu-background-closed' id="side-menu-background">
                <motion.div onClick={onClickLeftMenu} key={"sideMenu"} onHoverStart={onHover} initial={{ width: "0px" }} animate={{ width: "clamp(24px, 15vw, 64px)" }} className={`side-menu-container side-menu-closed`}>
                    <div className='header-container'>
                        <div className="logo-container">
                            <Logo key={"logo"} rotated={true} height='clamp(0px, 8vh, 64px)' />
                        </div>

                        <MenuSeparator />

                        <div className="side-menu-header">
                            <Avatar id={user.profile.avatar} src={profile} status='online' height={100} />

                            <div id='sidebar-user'>
                                <h1 className='sidebar-exhibiion-name'>{user.displayname}</h1>
                                <h2 className='sidebar-username'>{user.username}</h2>
                            </div>

                            <UserStats followers={user.profile.followers} following={user.profile.following} posts={user.profile.posts} />

                        </div>
                        <MenuSeparator />
                    </div>

                    <div className='menu-items-container' style={{ flex: "60%" }}>
                        {
                            menuItems.map((item, i) => {
                                return (
                                    <MenuItem count={item.count} pattern={item.pattern} key={item.path || i} separator={item.separator} rightMenuLocation={rightMenuLocation} icon={item.icon} label={item.item} isURLOriented={item.isURLOriented} path={item.path} />
                                )
                            })
                        }
                    </div>
                </motion.div>

                <motion.div animate={{ translate: "0% 0px", opacity: 1 }}>
                    <UsersRecent client={client} />
                </motion.div>

                <RightMenu client={client} location={rightMenuLocation} />
            </div>
        )
    } else {
        view = (
            <div rightmenu={String(rightMenuLocation != null)} className={`side-menu-background`} onClick={onClickBackground} id="side-menu-background">
                <motion.div onClick={onClickLeftMenu} rightmenu={String(rightMenuLocation != null)} ref={itemsMenuContainer} id="side-menu" initial={{ width: "clamp(24px, 15vw, 64px)" }} animate={{ width: "300px" }} key={"sideMenu"} onHoverEnd={onHoverEnd} className={`side-menu-container side-menu-opened`}>
                    <div style={{ flex: "20%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <div className="logo-container">
                            <Logo key={"logo"} height='36px' />
                        </div>

                        <MenuSeparator />

                        <div className="side-menu-header">



                            <Avatar profileId={user.username} id={user.profile.avatar} src={profile} status='online' height={100} />

                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} id='sidebar-user'>
                                <h1 className='sidebar-exhibiion-name'>{user.displayname}</h1>
                                <h2 className='sidebar-username'>{user.username}</h2>
                            </motion.div>

                            <UserStats followers={user.profile.followers} following={user.profile.following} posts={user.profile.posts} />

                        </div>
                        <MenuSeparator />
                    </div>

                    <div ref={rightMenuContainer} className='menu-items-container' style={{ flex: "60%" }}>
                        {
                            menuItems.map((item, i) => {
                                return (
                                    <MenuItem count={item.count} pattern={item.pattern} key={item.path || i} separator={item.separator} rightMenuLocation={rightMenuLocation} icon={item.icon} label={item.item} isURLOriented={item.isURLOriented} path={item.path} />
                                )
                            })
                        }
                    </div>
                </motion.div>

                <motion.div animate={{ translate: "-64px 0px", opacity: 0, width: "0px" }}>
                    <UsersRecent client={client} />
                </motion.div>

                <RightMenu setLocation={setRightMenuLocation} client={client} location={rightMenuLocation} />
            </div>
        )
    }

    return (
        view
    )
}



function MenuItem({ count, icon, label, isURLOriented, pattern, path, separator, rightMenuLocation }) {
    const navigate = useNavigate();
    const match = useMatch(`/app/${pattern}`)

    if (separator) return (<MenuSeparator />)

    const selected = isURLOriented ? Boolean(match) : rightMenuLocation == path

    return (
        <div onClick={e => {
            e.stopPropagation();
            if (isURLOriented)
                navigate(`/app/${path}`)
            else
                UIEvents.emit('right_menu.location', path)


        }} id='menu-item' className={selected ? `menu-item selected-menu-item` : 'menu-item'}>
            <div className='menu-item-icon-container'>
                {!isNaN(count) ? <div className='menu-item-count'>{count}</div> : null}
                <img className='menu-item-icon' src={icon} />
            </div>
            <span className='menu-item-label'>{label}</span>

        </div>
    )
}

function MenuSeparator({ }) {
    return (<div className='menu-separator'></div>)
}

function RightMenu({ location, client, setLocation }) {
    const [subMenu, setSubMenu] = useState(null);

    useEffect(() => {
        setSubMenu(null);

        if (location) {
            console.log(location)
            const path = location.split("/");

            switch (path[0]) {
                case "chats":
                    if(!path[1]) return;
                    setSubMenu(<IndividualChat key={path[1]} idChat={path[1]} setSubMenu={setSubMenu} client={client} />)
                    break;
            }
        }
    }, [location])

    useEffect(() => {
        const onChatHandle = (idChat) => {
            setLocation("chats/" + (idChat ? idChat : ""))
        }

        UIEvents.on("rightmenu.chat", onChatHandle)

        return () => {
            UIEvents.off("rightmenu.chat", onChatHandle)
        }
    })

    if (!location) return (null);

    const paths = location.split("/");
    const base = paths[0];
    const params = paths.slice(1);

    let view = (null);

    switch (base) {
        case "notifications":
            view = (
                <>
                    <NotificationsMenu client={client} />
                </>
            )
            break;

        case "friends":
            view = (
                <>
                    <FriendsMenu client={client} />
                </>
            )
            break;


        case "chats":
            view = (
                <>
                    <ChatsMenu client={client} subMenu={subMenu} setSubMenu={setSubMenu} />
                </>
            )
            break;

        case "rooms":
            view = (
                <>
                    <RoomsMenu client={client} />
                </>
            )
            break;

        case "tags":
            view = (
                <>
                    <TagsMenu />
                </>
            )
            break;

        default:
            view = (null);
            break;
    }

    return (
        <>
            <div onClick={(e) => e.stopPropagation()} className="side-menu-container">
                {view}
            </div>
            {
                subMenu ? (
                    <div className='side-menu-container' onClick={(e) => e.stopPropagation()}>
                        {subMenu}
                    </div>
                ) : null
            }
        </>
    )
}
