import { useEffect, useState } from "react";
import SoundMeter from "../../../../shared/js/soundMeter";

export default function useSoundMeter(previewStream) {
    const [volume, setVolume] = useState(0);
    const [context, setContext] = useState(new AudioContext());

    useEffect(() => {
        let interval = null;

        async function main() {
            if (!previewStream) return;

        }

        main();

        return () => {
            clearInterval(interval);
        }
    }, [previewStream])

    return volume;
}