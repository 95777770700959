import { useEffect, useState } from "react";
import checkedIcon from '../../shared/img/checked-icon.svg'

export default function Checkbox({text, onChange, value = false}) {
    const [checked, setChecked] = useState(value);
    const handleCheck = e => { setChecked(!checked); if(onChange) onChange(!checked) }

    useEffect(() => {
        setChecked(value);
    }, [value])

    return (
        <div className={"checkboxContainer"} onClick={handleCheck}>

            <div onClick={handleCheck} className={"checkBox"}>
                {
                    checked ? (
                        <img width={26.385 * 0.75} height={20.115 * 0.75} className={"checkedIcon"} src={checkedIcon} type='image/svg+xml' />
                    ) : (null)
                }
            </div>


            <span>{text}</span>
        </div>
    )
}