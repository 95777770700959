import { EventEmitter } from "events";
import { useEffect, useState } from "react";
import useSoundMeter from "./useSoundMeter";
import CallJoin from '../sounds/call_join.mp3';
import CallLeave from '../sounds/call_leave.mp3';

export default function useCallHandler(roomClient, user) {
    const [callUsers, setCallUsers] = useState({});
    const [previewStream, setPreviewStream] = useState(null);
    const [audioContext, setAudioContext] = useState(new AudioContext());
    const [connections, setConnections] = useState({});
    const [inCall, setInCall] = useState(false);
    const [muted, setMuted] = useState({
        audio: false,
        video: true,
    });
    const soundMeter = useSoundMeter(previewStream);

    const [talking, setTalking] = useState(false);

    useEffect(() => {
        async function main() {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: !muted.audio, video: !muted.video });
            console.log(stream);
            setPreviewStream(stream)
        }

        main();
    }, [muted]);
    useEffect(() => {
        for (const connection of Object.values(connections)) {
            connection.emit("TALKING", {
                talking
            });
        }
    }, [talking, connections])

    useEffect(() => {
        setTalking(soundMeter > 20);
        connections[user.id]?.events.emit("TALKING", { talking: soundMeter > 20 });
    }, [soundMeter, connections, user])

    useEffect(() => {
        setInCall(() => {
            for (const idUser of Object.keys(callUsers)) {
                if (idUser === user.id) {
                    return true;
                }
            }
            return false;
        })
    }, [callUsers])

 

    useEffect(() => {
        if (!audioContext) {
            setAudioContext(new AudioContext());
        } else {
            audioContext.resume();
        }
    }, [audioContext])

    useEffect(() => {
        function onCallJoin(data) {
            if (inCall || data.idUser === user.id) {
                const audio = new Audio();
                audio.onloadedmetadata = function () {
                    audio.play();
                }
                audio.src = CallJoin;
            }

            setCallUsers((callUsers) => ({
                ...callUsers,
                [data.idUser]: data
            }))
        }

        function onCallLeave(idConnection) {
            if (inCall || idConnection === user.id) {
                const audio = new Audio();
                audio.onloadedmetadata = function () {
                    audio.play();
                }
                audio.src = CallLeave;
            }

            setCallUsers((callUsers) => {
                const newCallUsers = { ...callUsers };
                delete newCallUsers[idConnection];
                return newCallUsers;
            })
        }

        if (roomClient) {
            roomClient.on("CALL_JOIN", onCallJoin)
            roomClient.on("CALL_LEAVE", onCallLeave)
        }

        return () => {
            if (roomClient) {
                roomClient.off("CALL_JOIN", onCallJoin)
                roomClient.off("CALL_LEAVE", onCallLeave)
            }
        }
    }, [roomClient, inCall]);

    useEffect(() => {
        function onRoomOffer(idConnection, offer) {
            const connection = connections[idConnection];
            if (!connection) {
                const connection = new RoomCallConnection(roomClient, idConnection, previewStream);

                setConnections((connections) => ({
                    ...connections,
                    [idConnection]: connection
                }));

                connection.muted = muted;

                connection.onOffer(offer)
            }
            else {
                connection.onOffer(offer);
            }
        }

        function onRoomRenegotiationOffer(idConnection, offer) {
            const connection = connections[idConnection];
            if (connection) {
                connection.onRenegotiateOffer(offer);
            }
        }

        function onRoomRenegotiationAnswer(idConnection, answer) {
            const connection = connections[idConnection];
            if (connection) {
                connection.onRenegotiateAnswer(answer);
            }
        }

        function onRoomAnswer(idConnection, answer) {
            const connection = connections[idConnection];
            if (connection) {
                connection.onAnswer(answer);
            }
        }

        function onRoomCandidate(idConnection, candidate) {
            const connection = connections[idConnection];
            if (connection) {
                connection.addIceCandidate(candidate);
            }
        }

        function onStreams(connection, streams) {
            var ctx = audioContext;
            const stream = streams[0];

            var audio = new Audio();
            audio.srcObject = stream;
            var gainNode = ctx.createGain();
            gainNode.gain.value = .5;

            connection.volumeNode = gainNode;

            audio.onloadedmetadata = function () {
                var source = ctx.createMediaStreamSource(audio.srcObject);
                audio.play();
                audio.muted = true;
                source.connect(gainNode);
                gainNode.connect(ctx.destination);
            }

            if (audioContext.state === "suspended") {
                audioContext.resume();
            }
        }

        function onCallLeave(idConnection) {
            const connection = connections[idConnection];
            if (connection) {
                connection.close();
                setConnections((connections) => {
                    const newConnections = { ...connections };
                    delete newConnections[idConnection];
                    return newConnections;
                })
            }
        }

        function onConnected(connection) {
            connection.emit("MUTED", { muted });
        }

        for (const connection of Object.values(connections)) {
            connection.events.on("streams", onStreams);
            connection.events.on("connected", onConnected);
        }

        if (roomClient) {
            roomClient.on("CALL_OFFER", onRoomOffer);
            roomClient.on("CALL_ANSWER", onRoomAnswer);
            roomClient.on("CALL_CANDIDATE", onRoomCandidate);
            roomClient.on("CALL_LEAVE", onCallLeave);

            roomClient.on("CALL_OFFER_RENEGOTIATE", onRoomRenegotiationOffer);
            roomClient.on("CALL_ANSWER_RENEGOTIATE", onRoomRenegotiationAnswer);
        }

        return () => {
            if (roomClient) {
                roomClient.off("CALL_OFFER", onRoomOffer);
                roomClient.off("CALL_ANSWER", onRoomAnswer);
                roomClient.off("CALL_CANDIDATE", onRoomCandidate);
                roomClient.off("CALL_LEAVE", onCallLeave);

                roomClient.off("CALL_OFFER_RENEGOTIATE", onRoomRenegotiationOffer);
                roomClient.off("CALL_ANSWER_RENEGOTIATE", onRoomRenegotiationAnswer);
            }

            for (const connection of Object.values(connections)) {
                connection.events.off("streams", onStreams);
                connection.events.off("connected", onConnected);
            }
        }
    }, [roomClient, connections, previewStream, audioContext, muted]);

    useEffect(() => {
        if (previewStream) {
            for (const track of previewStream.getTracks()) {
                switch (track.kind) {
                    case "audio":
                        track.enabled = !muted.audio;
                        break;
                    case "video":
                        track.enabled = !muted.video;
                        break;
                }
            }
        }

        for (const connection of Object.values(connections)) {
            connection.emit("MUTED", { muted });
        }
    }, [previewStream, muted, connections]);

    useEffect(() => {
        for (const connection of Object.values(connections)) {
            connection.setPreviewStream(previewStream);
        }
    }, [connections, previewStream]);

    function setupUsers(members) {
        const nM = {};

        for (const member of members) {
            if (member.idUser !== user.id) {
                const connection = new RoomCallConnection(roomClient, member.idUser, previewStream);
                setConnections((connections) => ({
                    ...connections,
                    [member.idUser]: connection
                }));
                connection.start();
            }
            nM[member.idUser] = member;
        }
        setCallUsers(nM);
    }

    return {
        callUsers,
        setCallUsers,
        previewStream,
        setPreviewStream,
        audioContext,
        setAudioContext,
        connections,
        setConnections,
        muted,
        setMuted,
        setupUsers,
        talking
    }
}

class RoomCallConnection {
    constructor(roomClient, idMember, previewStream) {
        this.roomClient = roomClient;
        this.idMember = idMember;
        this.connection = new RTCPeerConnection({
            iceServers: [
                {
                    urls: "turn:191.234.202.24:3478",
                    username: "tagg",
                    credential: "tagg"
                },
                {
                    url: "stun:191.234.202.24:3478",
                },
                {
                    'url': 'stun:stun.l.google.com:19302'
                },
            ],

        });

        this.pendingData = [];

        this.tracks = {};
        this.started = false;

        this.connection.addTransceiver("audio");
        this.connection.addTransceiver("video");

        this.connection.onicecandidate = this.onIceCandidate;
        this.connection.ontrack = this.onTrack;
        this.connection.onnegotiationneeded = this.onNegotiationNeeded;

        this.connection.onconnectionstatechange = () => {
            //console.log("signaling", this.connection.signalingState)
            if (this.connection.connectionState === "stable") {
                this.roomClient.emit("CALL_STATUS", this.idMember, "CONNECTED");
            }
        }

        this.data = this.connection.createDataChannel("data", {
            negotiated: true,
            id: 0
        });

        this.connection.onconnectionstatechange = () => {
            console.log("connection", this.connection.connectionState)
            if (this.connection.connectionState === "connected") {
                this.emit("MUTED", { muted: this.muted });
                this.events.emit("connected", this);
            }
        }

        this.data.onmessage = this.onDataMessage;

        this.events = new EventEmitter();
        this.setPreviewStream(previewStream);

        this.muted = {
            video: true,
            audio: false,
        }

        this.events.addListener("MUTED", ({ muted }) => {
            this.muted = muted;
        });
    }

    onDataOpen = () => {
        for (const data of this.pendingData) {
            this.data.send(data);
        }
        this.pendingData = [];
    }

    onDataMessage = ({ data }) => {
        const message = JSON.parse(data);

        this.events.emit(message.event, message.data);
    }

    emit(event, data) {
        if (this.data.readyState !== "open") {
            return this.pendingData.push(JSON.stringify(
                {
                    event,
                    data
                }
            ))
        }

        this.data.send(JSON.stringify(
            {
                event,
                data
            }
        ))
    }

    close() {
        this.connection.onicecandidate = null;
        this.connection.ontrack = null;
        this.connection.onnegotiationneeded = null;

        this.data.close();

        this.data.removeEventListener("open", this.onDataOpen)

        this.connection.close();
        this.events.removeAllListeners();
        this.tracks = {};
    }

    setPreviewStream(stream) {
        this.previewStream = stream;

        console.log("setPreviewStream", stream);

        if (!stream) return;

        for (const track of stream.getTracks()) {
            console.log("track ", track);
            this.addTrack(track, stream);
        }
    }

    onNegotiationNeeded = async () => {
        const offer = await this.connection.createOffer();
        await this.connection.setLocalDescription(offer)
        this.roomClient.emit("CALL_OFFER_RENEGOTIATE", this.idMember, offer);
    }

    addTrack(track, stream) {
        if (this.tracks[track.id]) {
            this.connection.removeTrack(this.tracks[track.id]);
            delete this.tracks[track.id];
        }
        const sender = this.connection.addTrack(track, stream);
        this.tracks[track.id] = sender;
    }


    async start() {
        console.log("start()");
        this.connection.createOffer()
            .then((offer) => this.connection.setLocalDescription(offer))
            .then(() => this.sendOffer(this.connection.localDescription))
            .catch((err) => console.log("error on start"))
    }

    async sendOffer(offer) {
        this.roomClient.emit("CALL_OFFER", this.idMember, offer);
    }

    async sendAnswer(answer) {
        this.roomClient.emit("CALL_ANSWER", this.idMember, answer);
    }

    async onOffer(offer) {
        this.connection.setRemoteDescription(offer)
            .then(() => this.connection.createAnswer()
                .then((answer) => this.connection.setLocalDescription(answer))
                .then(() => this.sendAnswer(this.connection.localDescription))
            )
    }

    async onAnswer(answer) {
        if (this.connection.signalingState === "stable") return;
        this.connection.setRemoteDescription(answer);
    }

    async onRenegotiateOffer(offer) {
        this.connection.setRemoteDescription(offer)
            .then(() => this.connection.createAnswer()
                .then((answer) => this.connection.setLocalDescription(answer))
                .then(() => this.sendAnswer(this.connection.localDescription))
            )
    }

    async onRenegotiateAnswer(answer) {
        this.connection.setRemoteDescription(answer);
    }

    addIceCandidate(candidate) {
        this.connection.addIceCandidate(new RTCIceCandidate(candidate));
    }

    onIceCandidate = (e) => {
        if (e.candidate) {
            this.roomClient.emit("CALL_CANDIDATE", this.idMember, e.candidate);
        }
    }

    onTrack = (e) => {
        this.streams = e.streams;
        this.events.emit("streams", this, e.streams);
    }
}
