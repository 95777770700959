import { useNavigate } from 'react-router-dom';
import CatImages from '../../../shared/cat';
import Button from '../../../shared/components/button';
import LoadingLogo from '../../../shared/components/loading';
import Logo from '../../../shared/components/logo';
import '../../../shared/css/global.css';
import {motion} from 'framer-motion';

export default function NotFound() {
    return (
        <div className={"start-background"}>
            <NotFoundContainer />
        </div>
    )
}

export function NotFoundContainer() {
    const navigate = useNavigate();

    function handleHome() {
        navigate('/app');
    }

    return (
        <motion.div initial={{opacity: 0, scale: 0.8}} animate={{opacity: 1, scale: 1}} transition={{duration: 2}} className='flexcol flexcenter'>
                <LoadingLogo height={"75px"} />
                <br></br>
                <img src={CatImages.CatLazy} height={"350px"} className='cat' />
                <br></br>
                <div className='flexcol flexcenter'>
                    <span className='tipHeader'>404 - PÁGINA NÃO ENCONTRADA</span>
                    <span className='tip'>Verifique se a URL digitada está correta.</span>
                </div>
                <br></br>
                <div className='flexrow'>
                    <Button onClick={handleHome}>Voltar</Button>
                </div>
            </motion.div>
    )
}