import { useEffect, useRef } from "react";
import './components.css'
import { motion } from 'framer-motion';
import styles from './dialog.module.css';

export function Dialog({ open, children, onClose, style }) {
    const dialogRef = useRef();

    useEffect(() => {
        if (dialogRef.current && open) {
            dialogRef.current.showModal();
        } else if (dialogRef.current) {
            dialogRef.current.close();
        }
    }, [open, dialogRef.current]);

    if (!open) return <motion.dialog style={{opacity: 0, display: "none"}} onClick={handleClick} ref={dialogRef} className={styles.dialog}>
    </motion.dialog>

    function handleClick(event) {
        const dialog = dialogRef.current;
        var rect = dialog.getBoundingClientRect();
        var isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
            rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
        if (!isInDialog) {
            event.stopPropagation();
            onClose();
        }
    }

    return (
        <motion.dialog style={style} initial={{ scale: 0.75, opacity: 0 }} animate={children ? { scale: 1, opacity: 1 } : { scale: 0.75 }} onClick={handleClick} ref={dialogRef} className={styles.dialog}>
            {
                children
            }
        </motion.dialog>
    )
}