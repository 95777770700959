import style from './rooms.module.css'
import Button from '../../../shared/components/button'
import crown from '../../../shared/img/icons/crowntagg.svg'
import { useState, useRef, useEffect, useCallback } from 'react'
import { IconGame, IconListen, IconPlay, IconRead, IconMic, IconCam, IconPhone, IconLeftArrow, IconMute } from '../../../shared/img/icons/index'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { RemoteImage } from '../../../shared/components/storage/img'
import config from '../../../shared/img/icons/config.svg'
import exit from '../../../shared/img/icons/exit.svg'
import chat from '../../../shared/img/icons/chat.svg'
import { useLocalUser } from '../../../hooks/useLocalUser'
import TaggRooms from '@taggapp/room'
import { useRoomData, useRoomMembers } from './hooks/useRoomData'
import moment from 'moment'
import { RoomCall } from './call/call'
import useCallHandler from './hooks/useCallHandler'
import MicMute from './sounds/mic_mute.mp3';
import MicUnmute from './sounds/mic_unmute.mp3';
import { RoomWatchDialog } from './activities/watch'
import useActivityHandler from './hooks/useActivityHandler'
import { Activities } from './activities/list'
import { EmotePicker } from '../../../shared/components/emote/picker'
import { set } from 'lodash'
import { RoomReaction } from './roomReaction'
import { v4 } from 'uuid'
import { RoomGamesDialog } from './activities/games'


function RoomHome({ roomClient, roomId, roomData, activityHandler, user }) {
    const [currentOpen, setCurrentOpen] = useState(null);

    const tabs = [
        {
            id: "play",
            id: "play",
            name: "Jogar",
            icon: IconGame,
            redirect: "app/rooms/" + roomId + "/play",
            className: `${style.tab} ${style.play}`,
            describe: "Jogue com seus amigos"

        },
        {
            id: "watch",
            id: "watch",
            name: "Assistir",
            icon: IconPlay,
            redirect: "app/rooms/" + roomId + "/watch",
            className: `${style.tab} ${style.watch}`,
            describe: "Assista vídeos com seus amigos"
        },
        {
            id: "listen",
            id: "listen",
            name: "Ouvir",
            icon: IconListen,
            redirect: "app/rooms/" + roomId + "/listen",
            className: `${style.tab} ${style.listen}`,
            describe: "Ouça músicas com seus amigos"
        },
        {
            id: "read",
            id: "read",
            name: "Ler",
            icon: IconRead,
            redirect: "app/rooms/" + roomId + "/read",
            className: `${style.tab} ${style.read}`,
            describe: "Leia e escreva com seus amigos"
        }
    ];

    let view = (
        <div className={style.tabs}>
            {tabs.map((item) => {
                return (
                    <Tabs tabDescription={item.describe} onClick={() => setCurrentOpen(item.id)} tabId={item.id} tabName={item.name} tabIcon={item.icon} tabRedirect={item.redirect} tabClassName={item.className} />
                )
            })}
            <RoomWatchDialog roomClient={roomClient} onClose={() => setCurrentOpen(null)} open={currentOpen === "watch"} roomData={roomData} />
            <RoomGamesDialog roomClient={roomClient} onClose={() => setCurrentOpen(null)} open={currentOpen === "play"} roomData={roomData} />
        </div>
    );

    if (activityHandler.activity) {
        view = (null);

        const Activity = Activities[activityHandler.activity];

        view = (
            <Activity user={user} activityHandler={activityHandler} />
        )
    }

    return (

        view


    )
}


function RoomReactions({ roomClient }) {
    const parent = useRef();
    const [reactions, setReactions] = useState({
    })

    useEffect(() => {
        function handleReaction(emote) {
            setReactions((reactions) => {
                const newReactions = { ...reactions };
                newReactions[v4()] = emote;
                return newReactions;
            })
        }

        if (roomClient) {
            roomClient.on("REACTION", handleReaction)
        }

        return () => {
            if (roomClient) {
                roomClient.off("REACTION", handleReaction)
            }
        }
    }, [roomClient])

    return (
        <div className={style.roomReactions} ref={parent}>
            {
                Object.keys(reactions).map((key) => {
                    const emote = reactions[key];

                    function handleClose() {
                        setReactions((reactions) => {
                            const newReactions = { ...reactions };
                            delete newReactions[key];
                            return newReactions;
                        })
                    }

                    return (
                        <RoomReaction onClose={handleClose} key={key} emote={emote} parent={parent} />
                    )
                })
            }

        </div>
    )
}


export default function Room({ client }) {
    const { roomId } = useParams()
    const user = useLocalUser(client)
    const [roomClient, setRoomClient] = useState(null);
    const [connected, setConnected] = useState(false);
    const roomData = useRoomData(roomClient);
    const roomMembers = useRoomMembers(roomClient);
    const location = useLocation();
    const callHandler = useCallHandler(roomClient, user);
    const activityHandler = useActivityHandler(roomClient, user);
    const [emojiPicker, setEmojiPicker] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (roomData) {
            if (roomData.call)
                callHandler.setCallUsers(roomData.call.members || {});

            if (roomData.activity)
                activityHandler.setActivity(roomData.activity.id);
        }
    }, [roomData]);

    useEffect(() => {
        if (roomId && !roomClient) {
            setRoomClient(new TaggRooms.Room(roomId, client.token));
        }

        return () => {
            if (roomClient) {
                roomClient.disconnect();
            }
        }
    }, [roomId, roomClient]);

    useEffect(() => {
        function onConnect() {
            setConnected(true);
        }

        function onDisconnect() {
            setConnected(false);
        }

        if (roomClient) {
            roomClient.on("connect", onConnect);
            roomClient.on("disconnect", onDisconnect);
            roomClient.socket.auth.token = client.token;
            roomClient.socket.auth.idRoom = roomId;
            roomClient.connect();
        }

        return () => {
            if (roomClient) {
                roomClient.off("connect", onConnect);
                roomClient.off("disconnect", onDisconnect);
            }
        }
    }, [roomClient, roomId, client]);

    useEffect(() => {
        if (roomClient) {
            const interval = setInterval(() => {
                if (roomClient) roomClient.emit("HEARTBEAT");
            }, 30 * 1000);

            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            }
        }
    }, [roomClient])

    const imLeader = activityHandler.leader && activityHandler.leader === user.id;

    if (!roomClient) return null;
    if (!connected || !roomData) return null;
    if (!user) return null

    function handleClick() {
        const relativeLocation = location.pathname.replaceAll(`/app/rooms/${roomId}`, "");
        if (relativeLocation.length > 0) {
            navigate("/app/rooms/" + roomId);
        } else {
            navigate("/app/");
        }
    }

    function handleEmote(emote) {
        roomClient.emit("REACTION", emote.emoji);
    }

    function handleEndActivity() {
        roomClient.emit("ACTIVITY_END", (success) => {
            if (success) {
                activityHandler.setActivity(null);
            }
        })
    }

    return (
        <div className={style.roomPage}>
            <div className={style.main}>
                <div className={style.header}>
                    <div onClick={handleClick} className={`${style.headerItem} ${style.goBack}`}>
                        <img src={IconLeftArrow} height={16} />
                        <span className={style.roomTitle}>Voltar</span>
                    </div>
                    <span style={{ opacity: 0.5 }}>|</span>
                    <div className={style.headerItem}>
                        <img className={style.roomIcon} />
                        <span className={style.roomTitle}>{roomData.room.roomName}</span>
                    </div>
                </div>

                <div className={style.mainTopContent}>
                    <Routes location={location}>
                        <Route path={"/"} element={<RoomHome user={user} roomData={roomData} activityHandler={activityHandler} roomId={roomId} roomClient={roomClient} />} />
                        <Route path={"/call"} element={<RoomCall user={user} callHandler={callHandler} roomId={roomId} roomClient={roomClient} />} />
                    </Routes>

                    <RoomReactions roomClient={roomClient} />
                </div>
                <div className={style.mainBottomMenu}>
                    <RoomCallBottom activityHandler={activityHandler} callHandler={callHandler} roomId={roomId} user={user} roomClient={roomClient} />

                    <div className={style.bottomcenter}>
                        <span className={style.bcStatus}>
                            <ActivityName id={activityHandler.activity} />
                        </span>
                        <span className={style.leaderStatus}>
                            <LeaderName id={activityHandler.leader} members={roomMembers} />
                        </span>
                        {
                            imLeader ? <Button style={{pointerEvents: "all"}} onClick={handleEndActivity}>Encerrar atividade</Button> : null
                        }
                    </div>


                    <div className={style.bottomright}>
                        <div style={{ position: "absolute", bottom: "100%", left: 0, right: 0 }}>
                            <EmotePicker onClick={handleEmote} style={{ bottom: 0, top: "inherit" }} open={emojiPicker} onClose={() => setEmojiPicker(false)} />
                        </div>
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            setEmojiPicker(!emojiPicker);
                        }} padding='10px' style={{ height: 'min-content' }}>
                            <img src={"/img/emotes/svg/1F633.svg"} height={24} style={{ filter: "grayscale(1)" }} />

                        </Button>
                        <Button padding='10px' style={{ height: 'min-content' }}>
                            <img src={chat} className={style.iconroom} />
                        </Button>
                        <Button padding='10px' style={{ height: 'min-content' }}>
                            <img src={config} className={style.iconroom} />
                        </Button>
                        <Button style={{ "margin": "10px" }} padding="1px 12.5px">
                            <img src={exit} className={style.iconroom} style={{ "marginRight": "10px" }} /> Sair
                        </Button>
                    </div>
                </div>
            </div>
            <div className={style.rightMenu}>
                <span className={style.membersspan}>
                    Membros
                </span>
                <Button capsOff={true} fontSize="13px" padding="2.5px 15px" width={"85%"}>
                    Convidar amigos
                </Button>
                <div className={style.membersfather}>
                    {roomMembers.map((member) => {
                        return (
                            <Members data={member} key={member.idUser} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function Members({ data }) {
    const now = moment();
    const inRoom = data.lastTimeSeen ? now.diff(moment(data.lastTimeSeen), "minutes") < 1 ? true : false : false;

    return (
        <div className={style.membersdiv}
            style={inRoom == true ? { "opacity": "1" } : { "opacity": "0.25" }}>
            <div className={style.remote}>
                <RemoteImage id={data.idProfilePicture}></RemoteImage>
            </div>
            <div style={{ flex: 1 }}>
                <span className={style.username}>
                    {data.username}
                </span>
            </div>
            {
                data.isAdmin == true ? (

                    <img src={crown} className={style.crown} />
                )
                    : null
            }
        </div>
    )
}

function Tabs({ onClick, tabName, tabIcon, tabDescription, tabRedirect, tabClassName }) {

    return (
        <div className={style.tabContainer} onClick={onClick}>
            <div className={style.flip} >

                <div className={style.tab}>
                    <img src={tabIcon} />
                    <p>{tabName}</p>
                </div>
                <div className={`${style.tab} ${style.tabBack}`}>
                    <span style={{ textAlign: "center" }}>{tabDescription}</span>
                </div>
            </div>
        </div>

    )
}

function RoomCallBottom({ activityHandler, callHandler, user, roomClient, roomId }) {
    const [step, setStep] = useState(1);
    const [callImage, setCallImage] = useState(1)
    const {
        callUsers,
        setCallUsers,
        previewStream,
        setPreviewStream,
        audioContext,
        talking,
        setAudioContext,
        connections,
        setConnections,
        muted,
        setMuted,
        setupUsers
    } = callHandler;

    function detectWebcam() {
        return new Promise((resolve) => {
            let md = navigator.mediaDevices;
            if (!md || !md.enumerateDevices) return resolve(false);
            md.enumerateDevices().then(devices => {
                resolve(devices.some(device => 'videoinput' === device.kind));
            })
        })
    }


    const navigate = useNavigate();

    const refUpdated = useCallback((videoRef) => {
        if (previewStream && videoRef) {
            videoRef.onloadedmetadata = () => {
                videoRef.muted = true;
                videoRef.play();
            }
            if (!videoRef.srcObject || videoRef.srcObject.id !== previewStream.id) {

                videoRef.srcObject = previewStream;
            }
        }
    })

    function handleStep() {
        roomClient.emit('CALL_JOIN', ({ members }) => {
            setStep(2)

            setupUsers(members);
        })
    }

    function handleHangUp() {
        setCallImage(1)
        setStep(1)

        if (audioContext) {
            audioContext.close();
        }

        for (const connection of Object.values(connections)) {
            connection.close();
        }

        setMuted({
            audio: false,
            video: true,
        });
        setAudioContext(null);
        setConnections({});
        roomClient.emit("CALL_LEAVE", (success) => {

        })
    }

    function handleOpenCall() {
        navigate("/app/rooms/" + roomId + "/call");
    }

    async function handleCallView() {
        if(!(await detectWebcam())) return;
        
        if (callImage == 1) {
            setCallImage(2)
            setMuted((muted) => {
                return {
                    audio: muted.audio,
                    video: false
                }
            })
        }
        else {
            setCallImage(1)
            setMuted((muted) => {
                return {
                    audio: muted.audio,
                    video: true
                }
            })
        }
    }

    function handleMute() {
        const audio = new Audio(muted.audio ? MicUnmute : MicMute);

        audio.onloadedmetadata = () => {
            audio.play();
        }

        setMuted({
            ...muted,
            audio: !muted.audio
        });
    }

    let callView = (null);
    let view = (null);

    switch (step) {
        case 1:
            view = (
                <div className={style.bottomLeft}>
                    <span style={{ "opacity": "0.25", textAlign: 'center', width: '100%' }}>
                        Participe da chamada da sala
                    </span>
                    <Button width={'100%'} style={{ "margin-top": "10px" }} onClick={handleStep}>
                        Juntar-se
                    </Button>
                </div>
            )
            break;
        case 2:
            switch (callImage) {
                case 1:
                    callView = (
                        <div className={!talking ? style.avatar : `${style.avatar} ${style.talking}`}>
                            <RemoteImage id={user.profile.avatar}></RemoteImage>
                        </div>
                    )
                    break;
                case 2:
                    callView = (
                        <video ref={refUpdated} className={style.videoImage}></video>
                    )
                    break
            }
            view = (
                <div className={style.bottomLeftOnCall}>
                    <div className={style.callImage} onClick={handleOpenCall}>
                        {callView}
                    </div>
                    <div className={style.callComands}>
                        <Button onClick={handleMute} padding='5px' style={{ height: '30px' }}>
                            <img src={muted.audio ? IconMute : IconMic} className={style.iconControl} />
                        </Button>
                        <Button padding='5px' style={{ height: '30px', marginTop: '3px', marginBottom: '3px' }} onClick={handleCallView}>
                            <img src={IconCam} className={style.iconControl} />
                        </Button>
                        <Button padding='5px' style={{ height: '30px' }} onClick={handleHangUp}>
                            <img src={IconPhone} className={style.iconControl} />
                        </Button>
                    </div>

                </div>
            )
            break;
    }

    return (
        view
    )
}

function ActivityName({ id }) {
    switch (id) {
        case "youtube": return <span>Atividade: <span>YouTube</span></span>
        case "draw": return <span>Atividade: <span>Desenhe e Acerte</span></span>
        default: return "Aguardando atividade";
    }
}

function LeaderName({ id, members }) {
    const [leaderName, setLeaderName] = useState(null);

    useEffect(() => {
        if (id) {
            const member = members.find((member) => member.idUser === id);

            if (member) {
                setLeaderName(member.username);
            }
        }else {
            setLeaderName(null);
        }
    }, [id])

    if (leaderName) {
        return ("@" + leaderName)
    }
    else {
        return null;
    }
}