import { useEffect, useState } from "react";

export default function useActivityHandler(roomClient, user) {
    const [activity, setActivity] = useState(null);
    const [leader, setLeader] = useState(null);

    useEffect(() => {
        function onActivity(activityId) {
            setActivity(activityId);
            roomClient.emit("ACTIVITY_JOIN");
        }

        function onActivityLeader(leaderId) {
            setLeader(leaderId);
        }

        if (roomClient) {
            roomClient.on("ACTIVITY", onActivity);
            roomClient.on("ACTIVITY_LEADER", onActivityLeader);

            return () => {
                roomClient.off("ACTIVITY", onActivity);
                roomClient.off("ACTIVITY_LEADER", onActivityLeader);
            }
        }
    }, [roomClient])

    return {
        activity,
        setActivity,
        roomClient,
        leader
    }
}