import style from './imagepicker.module.css';
import { useGesture } from '@use-gesture/react';
import { useState, useEffect, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor'
import SeparatorLine from '../separator';
import Button from '../button';
import UIEvents from '../../js/events/ui';
import { useBlockGlobalScroll } from '../../../hooks/useBlockGlobalScroll';

function ImagePicker({image, setImage, onChange, title, width = 250, height = 250, borderRadius = 0}) {
    const [zoom, setZoom] = useState(1);
    const editor = useRef(null);
    useBlockGlobalScroll(Boolean(image));

    const bind = useGesture(
        {
            onPinch: ({ delta: [dX, dY], memo }) => {
                let newZoom = zoom
                newZoom += dX / 200 // Ajuste o denominador para alterar a sensibilidade do zoom
                newZoom = Math.max(newZoom, 1) // Impõe um limite mínimo para o zoom
                setZoom(newZoom)
            },
            onWheel: ({ event, delta: [dx, dy] }) => {
                setZoom(oldZoom => Math.max(1, oldZoom + dy * -0.01)); // Increase or decrease zoom level based on wheel delta
            }
        },
        {
            eventOptions: { passive: false },
            drag: {
                filterTaps: true
            }
        }
    )

    if (!image) return (null);

    function saveAvatar() {
        if (editor.current) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            const canvas = editor.current.getImage();

            if(onChange) onChange(canvas);
        }

        setImage(null);
    }

    function onClose(e) {
        if (e.target.id == 'imagepicker-background') {
            setImage(null);
        }
    }

    return (
        <div onClick={onClose} id='imagepicker-background' className={style.background}>
            <div className={style.container + " glassContainer"}>
                <div>
                    <h3>{title}</h3>
                    <SeparatorLine />
                </div>
                <span {...bind()}>
                    <AvatarEditor
                        ref={editor}
                        image={image}
                        width={width}
                        height={height}
                        border={50}
                        borderRadius={borderRadius}
                        scale={zoom}
                    />
                </span>
                <div className={style.buttons}>
                    <Button onClick={() => setImage(null)}>Cancelar</Button>
                    <Button onClick={saveAvatar}>Salvar</Button>
                </div>
            </div>
        </div>
    )
}

export function AvatarCropper({onChange}) {
    const [image, setImage] = useState(null);

    useEffect(() => {
        function onImageAvatar(image) {
            setImage(image);
        }

        UIEvents.on("avatar.crop", onImageAvatar);

        return () => {
            UIEvents.off("avatar.crop", onImageAvatar);
        }
    })

    return (
        <ImagePicker image={image} setImage={setImage} onChange={onChange} title={"Escolhendo uma foto de perfil"} borderRadius={250 * 0.5}/>
    )
}

export function BannerCropper({onChange}) {
    const [image, setImage] = useState(null);

    useEffect(() => {
        function onImageBanner(image) {
            setImage(image);
        }

        UIEvents.on("banner.crop", onImageBanner);

        return () => {
            UIEvents.off("banner.crop", onImageBanner);
        }
    })

    return (
        <ImagePicker image={image} setImage={setImage} onChange={onChange} width={768} height={250} title={"Escolhendo uma foto de capa"} borderRadius={0}/>
    )
}