import style from "./rightMenu.module.css";
import { motion } from "framer-motion";
import Separator from "./separator";
import "./components.css";
import Header from "./RightMenuHeader";
import GetNotificationModel from "./notifications";
import useNotifications from "../../hooks/useNotifications";
import { SpinnerLoader } from "./SpinnerLoader";

export default function NotificationsMenu({ client }) {
  const [notifications, clearAllNotifications] = useNotifications(client);

  return (
    <div className={style.container}>
      <Header title="Notificações">
        {
          notifications ? notifications.length > 0 ? <span onClick={clearAllNotifications} className={style.clearNotifications}>Limpar notificações</span> : null : null
        }
      </Header>
      <Separator />

      {notifications ? (
        <div className={style.notificationsListContainer}>
          {notifications.map((value, i) => {
            return <Notification key={value.idNotification} data={value} />;
          })}
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          className="flexcenter flexcol full"
        >
          <SpinnerLoader />
        </motion.div>
      )}
    </div>
  );
}

function Notification({ data }) {
  const Model = GetNotificationModel(data.type);

  if (!Model) {
    console.warn("Notification model doesnt exist " + data.type);
    return null;
  }

  return <Model data={data} />;
}
