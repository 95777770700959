const Translations = {
    login_header: "Entrar na conta",
    login_signin: "Entrar",
    login_signup: "Criar conta",
    login_ad: "Nossa rede social é o ponto de encontro de histórias incríveis. Venha e faça parte delas!",
    keep_connected: "Mantenha-me conectado",
    email: "E-mail",
    password: "Senha",
    followers: "Seguidores",
    following: "Seguindo",
    posts: "Postagens",
    viewProfile: "Ver perfil",
    filter: "Filtro",
    explore: "Explorar",
    publish: "Publicar",
    addTag: "+ Adicionar Tag",
    description: "Descrição",
    favoriteTags: "Tags favoritas",
    follow: "Seguir",
    unfollow: "Parar de seguir",
    stopEditing: "Parar de editar",
    editProfile: "Editar perfil",
    content: "Conteúdo",
    sortBy: "Ordenar pelas",
    relevance: "Relevância",
    text: "Texto",
    videos: "Vídeos",
    images: "Imagens",
    moreRecents: "Mais recentes",
    moreOlder: "Mais antigas",
    morePopular: "Mais populares",
    recent: "Recente",
    filterPosts: "Filtrar postagens",
    menu_profile: "Perfil",
    menu_notifications: "Notificações",
    menu_home: "Início",
    menu_friends: "Amigos",
    menu_conversations: "Conversas",
    menu_interactrooms: "Salas de interação",
    menu_tags: "Tags",
    menu_settings: "Configurações",
    friendsSearch: "Buscar amigos...",
    home_featuring: "Destaques semanais",
    createPost_tipPlaceholder: "No que você está pensando?",
    tutorialTip: "Dica do {0}" /* {0} = Chatty */,
    profileLocalUserTip: "Você pode editar seu perfil clicando no botão Editar Perfil!",
    profileExternalUserTip: "Você pode seguir {0} clicando no botão SEGUIR!" /* {0} = external username*/,

    settingsMenuUser: "USUÁRIO",
    settingsMenuUserAccount: "Minha conta",
    settingsMenuUserSecurity: "Segurança",
    settingsMenuUserDevices: "Dispositivos",

    settingsMenuApp: "APLICATIVO",
    settingsMenuAppAppearence: "Aparência",
    settingsMenuAppLanguage: "Idioma",
    settingsMenuAppVoiceAndVideo: "Voz e Vídeo",

    displayName: "Nome de exibição",
    accountRemoval: "Remoção de Conta",
    accountDisableTip: "Desativar sua conta significa que você pode recuperá-la quando quiser.",
    changeProfilePicture: "Alterar foto de perfil",
    changeProfileBanner: "Alterar banner",
    disableAccount: "Desativar conta",
    deleteAccount: "Excluir conta",
    
    settingsDevicesTip: "Aqui estão todos os dispositivos conectados à sua conta do Tagg App. Você pode sair de cada um individualmente, ou todos os outros ao mesmo tempo.",
    settingsDevicesWarning: "Se você não reconhecer alguma postagem na sua conta, cheque por dispositivo e mude a sua senha do Tagg imediatamente.",

    currentDevice: "Dispositivo atual",
    otherDevices: "Outros dispositivos"
};

export default Translations;