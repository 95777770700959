import { useEffect, useState } from "react";
import { GetNotificationEntityByAlias } from ".";
import './notifications.css';
import Avatar from "../Avatar";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function Notification_POST_LIKE({ data }) {
    const [post, setPost] = useState(null);
    const [user, setUser] = useState(null);
    const [now] = useState(moment());
    const navigate = useNavigate();

    useEffect(() => {
        if (!post) {
            const entity = GetNotificationEntityByAlias(data, "POST");
            setPost(entity.post);
        }
    }, [data]);

    useEffect(() => {
        if (!user) {
            const entity = GetNotificationEntityByAlias(data, "USER");
            setUser(entity.user);
        }
    }, [data]);


    if (!post || !user) return (null);

    function handleClick() {
        navigate("/app/user/" + user.username);
    }

    const date = moment(data.createdAt);
    const lastTimeSeen = moment(user.lastTimeSeen);

    return (
        <div className="rightMenuItemContainer" onClick={handleClick}>
            <Avatar status={now.diff(lastTimeSeen, "minutes") < 1 ? "online" : "offline"} type={"small"} height={48} id={user.idProfilePicture} />

            <div className="rightMenuItemInfo">
                <span className="rightMenuItemUsername">
                    {user.username}
                    &nbsp;
                    <span className="notificationContent">curtiu sua postagem</span>
                    &nbsp;
                    <span className="notificationTime">• {date.fromNow()}</span>
                </span>
            </div>
        </div>
    )
}