import style from './chatBubble.module.css'
import { RemoteImage } from '../storage/img'
import { deserializeText } from '../textarea/deserializer'
import moment from 'moment'

export function ChatBubble({ avatar, message, isLocal, showLastSeen, readedTime }) {
    let classNameP = style.main + " " + (isLocal ? style.local : '')
    let messageP = style.message + " " + (isLocal ? style.messageP : '')

    return (
        <div className={style.containerMessage}>

            <div className={classNameP}>
                <div className={style.avatar}>
                    <div className={style.avatarImg}>
                        <RemoteImage id={avatar} />
                    </div>
                </div>
                <div className={messageP}>
                    <p>{message}</p>
                </div>

            </div>
            {
                showLastSeen ? <span className={style.seenText}>Visto {moment(readedTime).calendar().toLowerCase()}</span> : null
            }
        </div>
    )
}

export function ChatTyping({ avatar, gif }) {
    return (
        <div className={style.main}>
            <div className={style.avatar}>
                <div className={style.avatarImg}>
                    <RemoteImage id={avatar} />
                </div>
            </div>
            <div className={style.message}>
                <img src={gif} className={style.img} />
            </div>
        </div>
    )
}