import style from './rightMenu.module.css'
import { IconExit, IconSearch } from '../img/icons'
import fixed from '../img/icons/Push pin.svg';
import Separator from './separator'
import UIEvents from '../js/events/ui';
import './components.css'
import moment from 'moment';
import Avatar from './Avatar'
import profile from '../../shared/img/profile.jpeg'
import { useEffect, useState } from 'react'
import Header from './RightMenuHeader'
import Searchbar from './Searchbar'
import useChats from '../../hooks/useChats';
import CatImages from '../cat';
import Button from './button';
import { motion } from 'framer-motion';
import IndividualChat from './individualChat/individualChat';
import { useLocalUser } from '../../hooks/useLocalUser';
import GlassButton from './glassButton';

function CurrentChats({ chats, subMenu, setSubMenu, client }) {
    const user = useLocalUser(client);

    function chatListFilter(a) {
        return true;
    }

    const processedContacts = chats
        ? chats.filter(chatListFilter)
        : [];

    return (

        processedContacts.length == 0 ?
            (
                <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }} className='flexcenter flexcol full' style={{ gap: 10 }}>
                    <img className={'blackandwhite ' + style.rightMenuCat} height={350} src={CatImages.CatComputer} />

                    <span style={{ textAlign: "center" }}>Você não tem conversas abertas. <br></br> <br />Que tal <span style={{ opacity: 0.5 }}> começar </span>uma nova conversa agora?</span>
                    <br></br>
                    <Button>Conversar com</Button>
                </motion.div>
            )
            :
            (
                <div className={style.conversation}>
                    {
                        processedContacts.map((contact) => {
                            return (
                                <Conversation client={client} user={user} contact={contact} subMenu={subMenu} setSubMenu={setSubMenu} />
                            )
                        })
                    }
                </div>
            )

    )
}

function RequestsChats({ setSubMenu, subMenu, client, chats, setChats }) {
    const user = useLocalUser(client);

    function chatListFilter(a) {
        return true;
    }

    const processedContacts = chats
        ? chats.filter(chatListFilter)
        : [];

    return (

        processedContacts.length == 0 ?
            (
                <motion.div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }} className='flexcenter flexcol full'>
                    <img className={'blackandwhite ' + style.rightMenuCat} height={100} src={CatImages.SadCatBox} />
                    <br></br>
                    <span style={{ textAlign: "center" }}>Você não tem solicitações de mensagem. <br></br> <br /></span>
                    <br></br>
                </motion.div>
            )
            :
            (
                <div className={style.conversation}>
                    {
                        processedContacts.map((contact) => {
                            function handleDelete() {
                                setChats((current) => {
                                    const novo = current.filter((value) => {
                                        return value.idChat !== contact.idChat;
                                    });

                                    return novo;
                                })
                            }

                            return (
                                <ConversationRequest client={client} handleDelete={handleDelete} user={user} contact={contact} subMenu={subMenu} setSubMenu={setSubMenu} />
                            )
                        })
                    }
                </div>
            )

    )
}

export default function ChatsMenu({ client, setSubMenu, subMenu }) {
    const [chatSearch, setchatSearch] = useState('');
    const [currentTab, setCurrentTab] = useState(0);
    const [currentChats, setCurrentChats, pendingChats, setPendingChats] = useChats(client);

    return (
        <>
            <div className={style.container}>
                <Header style={{ marginBottom: "0.5rem" }} title='Conversas' />
                <Separator />
                <br></br>

                <div className='flex flexrow' style={{ gap: 10 }}>
                    <Button onClick={() => setCurrentTab(0)} capsOff={true} style={{ flex: 1 }}>
                        Todas
                    </Button>
                    <Button onClick={() => setCurrentTab(1)} capsOff={true} style={{ flex: 1, position: "relative" }}>
                        {
                            pendingChats.length > 0 ? (
                                <div style={{ position: "absolute", borderRadius: 10, top: -10, right: -10, background: "var(--red1)", width: 36, height: 24, textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {pendingChats.length}
                                </div>
                            ) : null
                        }
                        Solicitações
                    </Button>
                </div>

                <Searchbar
                    value={chatSearch}
                    onChange={(text) => {
                        setchatSearch(text);
                    }}
                    placeholder='Buscar conversas...'
                />

                {
                    currentTab === 0 ? <CurrentChats chats={currentChats} client={client} subMenu={subMenu} setSubMenu={setSubMenu} /> : <RequestsChats setChats={setPendingChats} chats={pendingChats} client={client} subMenu={subMenu} setSubMenu={setSubMenu} />
                }


            </div>
        </>
    )
}


function Conversation({ user, contact, setSubMenu, subMenu, client }) {
    const [unreadMessages, setUnreadMessages] = useState(contact.unreadMessages);

    useEffect(() => {
        setUnreadMessages(contact.unreadMessages);
    }, [contact]);

    if (!contact) return null;

    const foreignUser = contact.sender.idUser === user.id ? contact.receiver : contact.sender;

    function handleSetSubMenu() {
        if (subMenu) {
            if (subMenu.props.idChat === contact.idChat) {
                return UIEvents.emit("rightmenu.chat", null);
            }
        }
        setUnreadMessages(0);
        UIEvents.emit("rightmenu.chat", contact.idChat);
    }

    const lastMessage = contact.messages ? contact.messages[0] : null;

    let unreaded = unreadMessages > 0;

    return (
        <div className={style.main}>

            <div className={style.chatPers} onClick={handleSetSubMenu}>
                <Avatar status={""} type={"small"} id={foreignUser.idProfilePicture} />
                <div className={style.chatDetails}>
                    <div className={style.userAndMessage}>
                        <span className={style.userName}>{foreignUser.displayname}</span>
                        <div className={style.messageDiv}>
                            {lastMessage ? (<span className={unreaded ? style.messageUnreaded : style.message}>{lastMessage.messageContent} · {moment(lastMessage.createdAt).fromNow()}</span>) : null}
                        </div>
                    </div>
                    {
                        unreadMessages > 0 ? (
                            <div className={style.chatMessageUnreadContainer}>
                                {unreadMessages}
                            </div>
                        ) : null
                    }

                </div>
            </div>

        </div>

    )
}


function ConversationRequest({ user, contact, setSubMenu, subMenu, client, handleDelete }) { 
    if (!contact) return null;

    const foreignUser = contact.sender.idUser === user.id ? contact.receiver : contact.sender;
    const imSender = contact.sender.idUser === user.id;

    function handleSetSubMenu() {
        if (subMenu) {
            if (subMenu.props.idChat === contact.idChat) {
                return UIEvents.emit("rightmenu.chat", null);
            }
        }
        UIEvents.emit("rightmenu.chat", contact.idChat);
    }

    const lastMessage = contact.messages ? contact.messages[0] : null;

    let unreaded = false;

    if (lastMessage) {
        unreaded = lastMessage.idUser !== user.id && !lastMessage.readedTime;
    }

    function handleCancel(e) {
        e.preventDefault();
        e.stopPropagation();
        client.cancelConversationRequest(contact.idChat).then((response) => {
            handleDelete();
        })
    }

    return (
        <div className={style.main}>
            <div className={style.chatPers} onClick={handleSetSubMenu}>
                <Avatar status={""} type={"small"} id={foreignUser.idProfilePicture} />
                <div className={style.chatDetails}>
                    <div className={style.userAndMessage}>
                        <span className={style.userName}>{foreignUser.displayname}</span>
                        {
                            lastMessage ? (
                                <div className={style.messageDiv}>
                                    <span className={unreaded ? style.messageUnreaded : style.message}>{lastMessage.messageContent} · {moment(contact.updatedAt).fromNow()}</span>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className={"flex flexrow"} style={{ alignItems: "center", gap: 10 }}>
                        {imSender ? <span className={style.chatMenuPending}>Aguardando resposta</span> : null}
                        <GlassButton onClick={handleCancel} style={{ width: 32, height: 32, fontSize: 16 }}>
                            <img src={IconExit} height={16} />
                        </GlassButton>
                    </div>
                </div>
            </div>

        </div>

    )
}