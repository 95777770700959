import { TrendingTopics } from "../trendingTopics/tredingTopics";
import style from './rightPanels.module.css'
import { IconExit, IconSearch } from '../../img/icons'
import { useEffect, useState } from 'react'
import UsersRecommendation from "../usersRecommendation/userRecommendation";
import { RemoteImage } from "../storage/img";
import { useNavigate } from "react-router-dom";

export function RightPanels({ client }) {
    return (
        <>
            <Searchbar client={client} style={{ flex: 1, margin: 0 }} placeholder={`Buscar`} />
            <TrendingTopics client={client} />
            <UsersRecommendation client={client} title="Perfis Recomendados"></UsersRecommendation>
        </>
    )
}

function Searchbar({ style: localStyle, placeholder, client }) {
    const [currentSearch, setCurrentSearch] = useState('');

    const [results, setResults] = useState([
        
    ]);

    useEffect(() => {
        client.search(currentSearch).then((response) => {
            setResults(response.data);
        })
    }, [currentSearch])

    const searchVisible = String(currentSearch).trim().length > 0;

    return (
        <div className={style.searchbarContainer} style={localStyle} >
            <img className={style.iconSearch} src={IconSearch} />
            <input value={currentSearch} onChange={(e) => {
                setCurrentSearch(e.target.value);
            }} className={style.searchBarInput} type='text' placeholder={placeholder} />

            {
                searchVisible && (
                    <div className={style.searchBarResults}>
                        <div className={style.searchBarResultHeader}>
                            <span className={style.searchBarResultsTitle}>
                                {
                                    results.length > 0 ? `${results.length} resultados para "${currentSearch}"` : `Nenhum resultado para "${currentSearch}"`
                                }
                            </span>
                        </div>
                        {
                            results.map((result) => {
                                switch(result.Type) {
                                    case "User":

                                    return <SearchBarUserResult key={result.idUser} data={result} />
                                }
                            })
                        }

                    </div>
                )
            }
        </div>
    )
}

function SearchBarUserResult({ data }) {
    const navigate = useNavigate();

    function handleClick(e) {
        e.stopPropagation();
        e.preventDefault();

        navigate(`/app/user/${data.username}`);
    }

    return (
        <div className={style.searchBarResult} onClick={handleClick}>
            <div className={style.searchBarResultAvatar}>
                <RemoteImage id={data.idProfilePicture} width={96} height={96} />
            </div>
            <div className={style.searchBarResultInfo}>
                <span className={style.searchBarResultInfoDisplayName}>{data.displayname}</span>
                <span className={style.searchBarResultInfoUsername}>@{data.username}</span>
            </div>
        </div>
    )
}