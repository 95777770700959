import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tagg from '@taggapp/taggjs-main';
import useToken from "./useToken";

export default function useClient() {
    const token = useToken();
    const [client, setClient] = useState(new Tagg.Client(null));
    const HEARTBEAT_INTERVAL = 30 * 1000;

    const navigate = useNavigate();

    useEffect(() => {
        function onHello() {
            console.log("[HELLO] hello from gateway");
        }

        function onEvents() {
            if (client) {
                client.on("hello", onHello);
            }
        }

        function offEvents() {
            if (client) {
                client.off("hello", onHello);
            }
        }

        let heartbeatInterval = setInterval(() => {
            if(client)
                client.emit("HEARTBEAT");
        }, HEARTBEAT_INTERVAL)

        onEvents();

        if (token) {
            function getBrowser() {
                const agent = window.navigator.userAgent.toLowerCase();
                const browser =
                    agent.indexOf('edg') > -1 ? 'Edge'
                        : agent.indexOf('opr') > -1 || window.opr ? 'Opera'
                            : agent.indexOf('chrome') > -1 || window.chrome ? 'Chrome'
                                : agent.indexOf('trident') > -1 ? 'Internet Explorer'
                                    : agent.indexOf('firefox') > -1 ? 'Firefox'
                                        : agent.indexOf('safari') > -1 ? 'Safari'
                                            : 'Unknown';

                return browser;
            }

            function getOS() {
                var OSName = "Unknown";
                if (window.navigator.userAgent.indexOf("Windows") != -1) OSName = "Windows";
                if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
                if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
                if (window.navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
                if (window.navigator.userAgent.indexOf("iPhone") != -1) OSName = "iPhone";
                if (window.navigator.userAgent.indexOf("iPad") != -1) OSName = "iPad";
                if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
                return OSName;
            }

            const location = {
                client: getBrowser(),
                os: getOS()
            }

            client.location = location;
            client.token = token;
            client.login().then((response) => {
            }).catch((err) => {
                console.error(err)
            })
        } else if (token === null) {
            navigate("/");
        }

        return () => {
            clearInterval(heartbeatInterval);
            offEvents();
        }
    }, [token]);

    return client;
}