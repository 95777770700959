import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

export function AlertTip({ hidder, children, style }) {
  const [enabled, setEnabled] = useState(true);
  const [timeout, setCurrentTimeout] = useState(null);

  useEffect(() => {
    if(hidder && enabled) {
        setEnabled(false);
    }
  }, [hidder])

  let view = (
    <motion.div>

    </motion.div>
  );

  function handleClick() {
    if(timeout) clearTimeout(timeout);
    setEnabled(false);
  }

  useEffect(() => {
    if(timeout) clearTimeout(timeout);

    if(!enabled || hidder) return;

    setCurrentTimeout(setTimeout(() => {
        setEnabled(false);
    }, 20 * 1000))
  }, [children])

  if (enabled) {
    view = (
      <motion.div
        onClick={handleClick}
        initial={{ opacity: 0, transform: "scale(0.5)" }}
        animate={{ opacity: 1, transform: "scale(1)" }}
        exit={{ opacity: 0, transform: "scale(0.5)" }}
        transition={{ duration: 0.25 }}
        style={style}
        className="alertContainer"
        key={"alert"}
      >
        <span className="alertText">{children}</span>
      </motion.div>
    );
  }

  return <AnimatePresence mode="wait">{view}</AnimatePresence>;
}
