import './components.css'

export default function GlassButton({children, disabled, onClick, style}){
    
    const handleClick = (e) => {
        if(disabled) return;
        if(onClick) onClick(e)
    }

    let className = "glassButton";

    if(disabled) className += " disabledGlass";
    
    return(
        <div onClick={handleClick} className={className} style={style}>
            {children}
        </div>
    )
}