import { useEffect, useState } from "react";

export function useBlockGlobalScroll(active) {
    const [isActive, setIsActive] = useState(active || false);

    useEffect(() => {
        setIsActive(Boolean(active));
    }, [active])

    useEffect(() => {
        document.body.style['overflow'] = isActive ? 'hidden' : 'auto';

        return () => {
            document.body.style['overflow'] = 'auto'
        }
    }, [isActive])
}