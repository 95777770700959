import { useLocation, matchPath, useMatch, useNavigate, Routes, Route } from 'react-router-dom';
import '../../../shared/css/global.css';
import style from './settings.module.css';
import { SettingsAccount } from './pages/account/account';
import { SettingsSecurity } from './pages/security/security';
import { SettingsDevices } from './pages/devices/devices';
import { SettingsAppearence } from './pages/appearence/appearence';
import { SettingsVoiceAndVideo } from './pages/voiceandvideo/voiceandvideo';
import { SettingsLanguage } from './pages/language/language';
import TranslatableText from '../../../localization/components/translatableText';
import { useEffect, useState } from 'react';
import { Dialog } from '../../../shared/components/dialog';
import CatImages from '../../../shared/cat';
import { SpinnerLoader } from '../../../shared/components/SpinnerLoader';

function SettingsLogout({ client }) {
    const navigate = useNavigate();
    const [loggingOut, setLoggingOut] = useState(false);

    useEffect(() => {
        if(client) {
            window.localStorage.removeItem("token");
            setLoggingOut(true);
            client.logout().then((response) => {
                setLoggingOut(false);
                switch(response.status) {
                    case 200:
                    return navigate("/");
                }
            })
        }
    }, [client])


    return (
        <Dialog open={loggingOut} style={{alignItems: "center"}}>
            <img src={CatImages.CatComputer} width={200} />
            <span>Não feche essa página até você ser redirecionado ao início...</span>
            <SpinnerLoader />
        </Dialog>
    )
}

export default function Settings({ client }) {
    const menus = [
        {
            name: <TranslatableText k={"settingsMenuUser"} />,
            items: [
                {
                    name: <TranslatableText k={"settingsMenuUserAccount"} />,
                    path: "/",
                    component: SettingsAccount
                },
                {
                    name: <TranslatableText k={"settingsMenuUserSecurity"} />,
                    path: "/security",
                    component: SettingsSecurity
                },
                {
                    name: <TranslatableText k={"settingsMenuUserDevices"} />,
                    path: "/devices",
                    component: SettingsDevices
                },
            ]
        },
        {
            name: <TranslatableText k={"settingsMenuApp"} />,
            items: [
                {
                    name: <TranslatableText k={"settingsMenuAppLanguage"} />,
                    path: "/language",
                    component: SettingsLanguage
                },
                {
                    name: <TranslatableText k={"settingsMenuAppVoiceAndVideo"} />,
                    path: "/audionvideo",
                    component: SettingsVoiceAndVideo
                },
                {
                    name: "Sair",
                    path: "/logout",
                    component: SettingsLogout
                },
            ]
        }
    ]

    return (
        <div className={style.content}>
            <div className={style.mainLeft}>
                <ConfigurationMenu menus={menus} />
            </div>
            <div className={style.mainRight}>
                <ConfigurationContent menus={menus} client={client} />
            </div>
        </div>
    );
}

function ConfigurationMenu({ menus }) {


    return (
        <>
            {
                menus.map((menu) => (
                    <ConfigurationMenuBox key={menu.name} label={menu.name}>
                        {
                            menu.items.map((submenu) => (
                                <ConfigurationMenuItem key={submenu.name} path={submenu.path}>{submenu.name}</ConfigurationMenuItem>
                            ))
                        }
                    </ConfigurationMenuBox>
                ))
            }
        </>
    )
}

function ConfigurationMenuBox({ label, children }) {
    return (
        <div className={style.configurationMenuBox}>
            <span className={style.label}>{label}</span>
            {children}
        </div>
    )
}

function ConfigurationMenuItem({ children, path }) {
    const match = useMatch("/app/settings" + path);
    const navigate = useNavigate();

    function handleClick() {
        if (!match) navigate("/app/settings" + path);
    }

    return (
        <div onClick={handleClick} className={!match ? style.configurationMenuItem : style.configurationMenuItemSelected}>
            {children}
        </div>
    )
}

function ConfigurationContent({ menus, client }) {
    return (
        <div className={style.configurationContent}>
            <Routes>
                {
                    menus.map((menu) => (
                        menu.items.map((submenu) => (
                            <Route key={submenu.path} path={submenu.path} element={<submenu.component client={client} />} />
                        ))
                    ))
                }
            </Routes>
        </div>
    )
}
