import { useEffect, useRef, useState } from 'react'
import './components.css'

export default function Button(props) {
    const [ripples, setRipples] = useState([]);

    const handleClick = (e) => {
        if (props.disabled) return;

        props.onClick?.(e); // Chama onClick se estiver definido

        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const newRipple = { id: Date.now(), x, y };  // Usando timestamp como ID

        setRipples(current => [...current, newRipple]);

        // Remover ripple após 1 segundo
        setTimeout(() => {
            setRipples(current => current.filter(ripple => ripple.id !== newRipple.id));
        }, 1000);
    }

    return (
        <div
            onClick={handleClick}
            className={`button ${props.capsOff ? 'capsOff' : ''} ${props.disabled ? 'disabled' : ''}`}
            style={{ ...props.style, borderRadius: props.borderRadius, width: props.width }}>

            <div style={{ position: "absolute", zIndex: 10, top: 0, left: 0, right: 0, bottom: 0, overflow: "hidden" }}>
                {ripples.map(ripple => (
                    <span
                        key={ripple.id}
                        className="ripple"
                        style={{
                            left: ripple.x,
                            top: ripple.y
                        }} />
                ))}
            </div>

            <span className='buttonText' style={{ fontSize: props.fontSize || 'var(--paragraph)', fontWeight: props.fontWeight, padding: props.padding }}>
                {props.children}
            </span>
        </div>
    )
}
