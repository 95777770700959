import { useEffect, useState } from "react"
import { setTitle } from "../../../../../hooks/setTitle"
import styles from './security.module.css'
import Button from "../../../../../shared/components/button"
import { Dialog } from "../../../../../shared/components/dialog"
import TextInput from "../../../../../shared/components/textinput"

export function SettingsSecurity({client}) {
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState(null);
    const [changePassword, setChangePassword] = useState(false);

    setTitle("Segurança")

    function handlePassword() {
        setError(null)
        client.changePassword({
            oldPassword: currentPassword,
            newPassword: newPassword
        }).then(() => {
            setCurrentPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
            setChangePassword(false);
        }).catch((err) => {
            setError("A senha atual inserida está incorreta.")
        })
    }

    return (
        <>
            <h2>Segurança</h2>

            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <h3>Senha e autenticação</h3>
                <Button fontWeight={400} onClick={() => setChangePassword(true)} fontSize={14} capsOff={true} padding={"5px 10px"}>Mudar senha</Button>
            </div>

            <Dialog style={{ padding: 0 }} open={changePassword} onClose={() => setChangePassword(false)}>
                <div className={styles.header}>
                    <h3 style={{ textAlign: "center", fontSize: 20 }}>Atualize sua senha</h3>
                    <span style={{ textAlign: "center", opacity: 0.5, fontWeight: "400" }}>Insira sua senha atual e uma nova senha.</span>
                </div>
                <div className={styles.container}>
                    <TextInput type={"password"} placeholder={"Senha atual"} value={currentPassword} onChange={setCurrentPassword}></TextInput>
                    <TextInput type={"password"} placeholder={"Nova senha"} value={newPassword} onChange={setNewPassword}></TextInput>
                    <TextInput type={"password"} placeholder={"Confirmar nova senha"} value={confirmNewPassword} onChange={setConfirmNewPassword}></TextInput>
                    {
                        error ? (<span style={{color: "var(--red1)"}}>{error}</span>) : null
                    }
                </div>
                <div className={styles.bottom}>
                    <Button onClick={() => setChangePassword(false)}>Cancelar</Button>
                    <Button onClick={handlePassword} disabled={!(currentPassword && (newPassword == confirmNewPassword))} style={{ backgroundColor: "var(--purple1)" }}>Pronto</Button>
                </div>
            </Dialog>
        </>
    )
}