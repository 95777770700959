import { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

export function useReactionAnimation(ref, elem) {
    const [initial, setInitial] = useState({});
    const [animate, setAnimate] = useState({});

    useEffect(() => {
        if (ref.current && elem.current) {
            const width = ref.current.clientWidth;
            const height = ref.current.clientHeight;

            const maxLeft = width - elem.current.clientWidth;
            const maxTop = (height / 2) - elem.current.clientHeight;

            const leftPos = Math.floor(Math.random() * (maxLeft + 1));
            const topPos = Math.floor(Math.random() * (maxTop + 1));

            console.log(leftPos, topPos)

            setInitial({ bottom: `${topPos}px`, left: `${leftPos}px`, opacity: 1 });
            setAnimate({
                left: `${leftPos - 50 + Math.floor(Math.random() * 100)}px`,
                bottom: `${topPos + Math.floor(Math.random() * maxTop * 1.2)}px`,
                opacity: 0
            });
        }

    }, [ref, elem]);

    return { initial, animate };
}

export function RoomReaction({ emote, parent, onClose }) {
    const elem = useRef();
    const controls = useAnimation();

    useEffect(() => {
        if (!parent.current || !elem.current) return;
        // Insira aqui o código para obter a altura e largura do content-video-reactionsBox, se necessário
        const width = parent.current.offsetWidth;
        const height = parent.current.offsetHeight;

        const maxLeft = width - elem.current.offsetWidth;
        const maxTop = (height / 2) - elem.current.offsetHeight;

        const leftPos = Math.floor(Math.random() * (maxLeft + 1));
        const topPos = Math.floor(Math.random() * (maxTop + 1));

        elem.current.style.left = `${leftPos}px`;
        elem.current.style.bottom = `${topPos}px`;

        const newLeft = leftPos + (-50 + Math.floor(Math.random() * 50));
        const newBottom = topPos + Math.floor(Math.random() * maxTop * 1.2);

        controls.start({
            left: newLeft,
            bottom: newBottom,
            opacity: 0,
            scale: 0,
            transition: { duration: 1.5 }
        }).then(() => {
            if(onClose) onClose();
        });
    }, [controls, parent, elem]);

    return (
        <motion.span
            transition={{ duration: 1.5 }}
            style={{ position: 'absolute', fontSize: 32 }}
            initial={{ opacity: 1 }}
            animate={controls}
            ref={elem}
        >
            {emote}
        </motion.span>
    );
}
