import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './Router';
import moment from 'moment';
import 'moment/min/locales.min'
import 'draft-js/dist/Draft.css';
import './analytics';
import Env from '@taggapp/taggjs-main/env';
import TaggRooms from '@taggapp/room';

//Env.ChangeGateway("http://localhost:3030")
//

moment.locale('pt-br');

/*



*/

//

//

//Env.ChangeAPI("http://localhost:8080/v1")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppRouter />
);
