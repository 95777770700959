import styles from "./loading.module.css";
import { AnimatePresence, motion } from "framer-motion";
import loading from "../../img/loading.svg";

export default function LoadingScreen({ open }) {
  return (
    <AnimatePresence mode={"wait"}>
      {open ? (
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.loadingContainer}
        >
          <motion.img
            height={96}
            initial={{ transform: "scale(0.5)" }}
            animate={{ transform: "scale(1)" }}
            transition={{ duration: 5, ease: false }}
            src={loading}
          />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
