import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import LoginPage from './pages/login/login.jsx'
import './shared/css/global.css';
import RegisterPage from './pages/register/register.jsx';
import AppRouter from './pages/app/Router.jsx';
import NotFound from './pages/app/404/notfound.jsx';
import { PrivacyPage } from './pages/privacy/privacy.jsx';

export default function MainRouter() {
    return (
        <div className="App" id='App'>
            <BrowserRouter>
                <Routes> 
                    <Route path="/" element={<LoginPage/>} />
                    <Route path="/privacy" element={<PrivacyPage/>} />
                    <Route path="/register" element={<RegisterPage/>} />


                    <Route path="/app/*" element={<AppRouter />} />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}