import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from './video.module.css';
import Play from './icons/play.svg';
import Pause from './icons/pause.svg';
import VolumeOn from './icons/volumeon.svg';
import VolumeOff from './icons/volumeoff.svg';
import moment from "moment";
import { AnimatePresence, motion } from 'framer-motion';
import Fullscreen from './icons/fullscren.svg';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const STORAGE_URL = process.env.STORAGE_URL || 'https://cdn.tagg.chat';

export const RemoteVideo = React.forwardRef(({ id }, ref) => {
    const handle = useFullScreenHandle();
    const [source, setSource] = useState(null);
    const [videoTime, setVideoTime] = useState(null);
    const [videoDuration, setVideoDuration] = useState(null);
    const [playerPlaying, setPlayerPlaying] = useState(false);
    const [showPlayer, setShowPlayer] = useState(true);
    const [volume, setVolume] = useState(window.localStorage.getItem("volume") || 0);
    const unshowPlayerTimeout = useRef();
    const [volumeSlider, setVolumeSlider] = useState(false);
    const [beforeMutedValue, setBeforeMutedValue] = useState(0);
    const [userPaused, setUserPaused] = useState(false);
    const fullscreen = handle.active;
    const videoRef = useRef();

    function pauseVideo() {
        if(videoRef.current)
            videoRef.current.pause();
    }

    function playVideo() {
        if(videoRef.current && !userPaused)
            videoRef.current.play();
    }

    useEffect(() => {
        window.localStorage.setItem("volume", volume);
    }, [volume])

    React.useImperativeHandle(ref, () => ({
        pauseVideo,
        playVideo
      }));

    useEffect(() => {
        if (id) setSource(`${STORAGE_URL}${id}`);
    }, [id]);
    
    useEffect(() => {
        if(videoRef.current) {
            videoRef.current.volume = volume;
        }
    }, [volume]);

    useEffect(() => {
        if(!showPlayer && fullscreen) {
            document.body.requestPointerLock();
        }else {
            document.exitPointerLock();
        }
    }, [fullscreen, showPlayer])

    useEffect(() => {
        window.addEventListener("mousemove", onMouseMove);

        function onMouseMove(e) {
            if(!fullscreen) return;
            if(unshowPlayerTimeout.current) clearTimeout(unshowPlayerTimeout.current);
            setShowPlayer(true);
            unshowPlayerTimeout.current = setTimeout(() => {
                setShowPlayer(false);
            }, 3000);
        }

        return () => {
            window.removeEventListener("mousemove", onMouseMove);
        }
    }, [fullscreen, unshowPlayerTimeout, showPlayer])

    function handleTimeUpdate(e) {
        if (e.target)
            setVideoTime(e.target.currentTime);
    }

    function handleMetadata(e) {
        if (e.target)
            setVideoDuration(e.target.duration);
    }

    if (!source) return null;

    function handlePausePlay() {
        if (videoRef.current) {
            if (videoRef.current.paused || videoRef.current.ended){
                setUserPaused(false);
                videoRef.current.play();
            }
            else {
                setUserPaused(true);
                videoRef.current.pause();
            }
        }
    }

    function handlePlayerStatus(e) {
        videoRef.current.volume = volume;
        setPlayerPlaying(!(e.target.paused || e.target.ended))
    }

    function toTimestamp(seconds) {
        const duration = moment.duration(seconds, 'seconds');
        const timestamp = duration.hours() > 0 ? moment.utc(duration.as('milliseconds')).format("HH:mm:ss") : moment.utc(duration.as('milliseconds')).format("mm:ss");
        return timestamp;
    }

    function handleHoverStart() {
        if(fullscreen) return;
        console.log("Hover start")
        if (unshowPlayerTimeout.current) {
            clearTimeout(unshowPlayerTimeout.current);
            unshowPlayerTimeout.current = null;
        }
        setShowPlayer(true);
    }

    function handleHoverStop() {
        console.log("Hover end")
        if(fullscreen) return;
        //setShowPlayer(false);
        unshowPlayerTimeout.current = setTimeout(() => {
            setShowPlayer(false);
        }, 0);
    }

    function handleFullScreen(e) {
        e.stopPropagation();
        if (!handle.active)
            handle.enter();
        else
            handle.exit();
    }

    function handleMute(e) {
        e.stopPropagation();
        if(volume > 0) {
            setBeforeMutedValue(volume);
            setVolume(0);
        }else {
            setVolume(beforeMutedValue > 0 ? beforeMutedValue : 1);
        }
    }

    return (
        <FullScreen handle={handle}>
            <div className={styles.container} onMouseEnter={handleHoverStart} onMouseLeave={handleHoverStop}>
                <AnimatePresence mode={"wait"}>
                    {
                        showPlayer ? (
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={styles.player} onClick={handlePausePlay}>
                                <div className={styles.bottomControls}>
                                    <PlayerSlider videoRef={videoRef} videoProgress={videoTime / videoDuration * 100} />
                                    <div className={styles.controls}>
                                        <div className={styles.playControl}>
                                            <div className={styles.playButtonContainer}>
                                                <img src={playerPlaying ? Pause : Play} height={20} />
                                            </div>
                                        </div>

                                        <div className={styles.playerControl}>
                                            <span>{toTimestamp(videoTime)} / {toTimestamp(videoDuration)}</span>

                                            <div onClick={handleMute} onMouseEnter={() => setVolumeSlider(true)} onMouseLeave={() =>setVolumeSlider(false)} className={styles.playButtonContainer}>
                                                <img src={volume > 0 ? VolumeOn : VolumeOff} height={20} />
                                                {volumeSlider ? <VolumeSlider volume={volume} setVolume={setVolume} /> : null}
                                            </div>

                                            <div className={styles.playButtonContainer} onClick={handleFullScreen}>
                                                <img src={Fullscreen} height={20} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ) : null
                    }
                </AnimatePresence>
                <video preload="metadata" onPause={handlePlayerStatus} onPlay={handlePlayerStatus} onLoadedMetadata={handleMetadata} onTimeUpdate={handleTimeUpdate} ref={videoRef} src={source} controls={false} autoPlay={false} />
            </div>
        </FullScreen>
    )
})

function VolumeSlider({volume, setVolume}) {
    const [movingCaret, setMovingCaret] = useState(false);
    const [startPosition, setStartPosition] = useState(null);
    const playerSliderRef = useRef();

    useEffect(() => {
        function handleMouseMove(e) {
            e.stopPropagation();
            if (movingCaret && playerSliderRef.current) {
                const delta = (e.pageY - startPosition);
                const offset = (delta * 1 / playerSliderRef.current.clientHeight);
                setVolume((current) => Math.max(Math.min(current - offset, 1), 0));
                setStartPosition((current) => current + delta);     
            }
        }

        function handleMouseUp(e) {
            e.stopPropagation();
            if (!movingCaret) return;
    
            setMovingCaret(null);
            setStartPosition(null);
        }

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        }
    })

    function getRelativeCoordinates(event, referenceElement) {

        const position = {
            x: event.pageX,
            y: event.pageY
        };

        const offset = {
            left: referenceElement.offsetLeft,
            top: referenceElement.offsetTop
        };

        let reference = referenceElement.offsetParent;

        while (reference) {
            offset.left += reference.offsetLeft;
            offset.top += reference.offsetTop;
            reference = reference.offsetParent;
        }

        return {
            x: position.x - offset.left,
            y: position.y - offset.top,
        };

    }

    function handleMouseDown(e) {
        e.stopPropagation();
        setMovingCaret(true);
        setStartPosition(e.pageY);
    }

    function handleClick(e) {
        e.stopPropagation();

        const relativePos = getRelativeCoordinates(e, e.target);

        console.log(relativePos.y, e.target)

        setVolume(() => Math.max(0, Math.min(1, (1 - relativePos.y * 1 / playerSliderRef.current.clientHeight))));
    }

    return (
        <div onClick={handleClick} id="volumeSliderContainer" className={styles.volumeSliderContainer}>
            <div onClick={(e) => {}} ref={playerSliderRef} className={styles.volumeSlider}>
                <div onClick={(e) => e.stopPropagation()} className={styles.volumeSliderProgress} style={{height: `${volume * 100}%`}}>
                    <div onClick={(e) => e.stopPropagation()} onMouseDown={handleMouseDown} className={styles.volumeSliderCaret} />
                </div>
            </div>
        </div>
    )
}

function PlayerSlider({ videoProgress, videoRef }) {
    const [caretShown, setCaretShown] = useState(false);
    const [movingCaret, setMovingCaret] = useState(false);
    const [startPosition, setStartPosition] = useState(null);
    const [currentProgress, setCurrentProgress] = useState(0);
    const playerSliderRef = useRef();

    useEffect(() => {
        setCurrentProgress(videoProgress);
    }, [videoProgress])

    function getRelativeCoordinates(event, referenceElement) {

        const position = {
            x: event.pageX,
            y: event.pageY
        };

        const offset = {
            left: referenceElement.offsetLeft,
            top: referenceElement.offsetTop
        };

        let reference = referenceElement.offsetParent;

        while (reference) {
            offset.left += reference.offsetLeft;
            offset.top += reference.offsetTop;
            reference = reference.offsetParent;
        }

        return {
            x: position.x - offset.left,
            y: position.y - offset.top,
        };

    }

    function handleClick(e) {
        e.stopPropagation();

        const relativePos = getRelativeCoordinates(e, e.target);

        videoRef.current.currentTime = videoRef.current.duration * (relativePos.x / e.target.clientWidth);

        setCurrentProgress(videoRef.current.currentTime / videoRef.current.duration * 100);
    }

    function handleMouseEnter(e) {
        setCaretShown(true);
    }

    function handleMouseExit(e) {
        setCaretShown(false);
    }

    useEffect(() => {
        function handleMouseMove(e) {
            if (movingCaret && playerSliderRef.current) {
                const delta = (e.pageX - startPosition.px);
                const offset = (delta * videoRef.current.duration / playerSliderRef.current.clientWidth);
                videoRef.current.currentTime = videoRef.current.currentTime + offset;
                setCurrentProgress(videoRef.current.currentTime / videoRef.current.duration * 100);
                setStartPosition({
                    s: videoRef.current.currentTime,
                    px: startPosition.px + delta
                })
            }
        }

        window.addEventListener("mouseup", handleMouseUp);
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        }
    })


    function handleMouseUp(e) {
        if (!movingCaret) return;

        window.removeEventListener("mouseup", handleMouseUp);

        videoRef.current.play().then(() => {
            videoRef.current.currentTime = startPosition.s;
            videoRef.current.play();
        });

        setMovingCaret(null);
        setStartPosition(null);
    }

    function handleMouseDown(e) {
        e.stopPropagation();
        console.log("mouse down");
        setMovingCaret(true);
        setStartPosition({ px: e.pageX, s: videoRef.current.currentTime });
    }

    return (
        <div className={styles.sliderViewport} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
            <div ref={playerSliderRef} className={styles.slider}>
                <div style={{ width: `${currentProgress}%` }} className={styles.sliderProgress}>
                    {
                        caretShown || movingCaret ? <div onMouseDown={handleMouseDown} className={styles.sliderCaret} /> : null
                    }
                </div>
            </div>
        </div>
    )
}