import '../../shared/css/global.css';
import SeparatorLine from '../../shared/components/separator';
import registerStyle from './register.module.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../shared/components/textinput';
import PasswordInput from '../../shared/components/passwordInput';
import Button from '../../shared/components/button'
import Logo from '../../shared/components/logo'
import FileUploader from '../../shared/components/fileUploader';
import VerifiedIcon from '../../shared/img/VerifiedIcon.svg';
import Tagg from '@taggapp/taggjs-main';
import {SpinnerLoader} from '../../shared/components/SpinnerLoader';
import { Spheres } from '../login/login';
import {AvatarEditable, BannerEditable} from '../../shared/components/avatar/avatareditable';

export default function RegisterPage() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1); //register steps
    const [registering, setRegistering] = useState(false)

    // step 1 
    const [user, setUser] = useState(null);
    const [userValidation, setUserValidation] = useState(false);

    const [email, setEmail] = useState(null);
    const [emailValidation, setEmailValidation] = useState(false);

    const [password, setPassword] = useState(null);
    const [passwordValidation, setPasswordValidation] = useState(false)
    const [confirmedPassword, setConfirmedPassword] = useState(null);
    const [passwordIcon, setPasswordIcon] = useState(null);


    // step 2
    const [exhibitionName, setExhibitionName] = useState(null)
    const [exhibitionNameValidation, setExhibitionNameValidation] = useState(false)
    const [aboutYou, setAboutYou] = useState(null)
    const [profileImage, setProfileImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);

    //step 3
    const [selectedTopics, setSelectedTopics] = useState(new Map())
    const [tagTopics, setTagTopics] = useState(null)
    const minSelectedTopics = 3

    function getSelectedTopics() {
        const newSelectedTopics = new Map();

        const iterator = selectedTopics.keys();

        let entry = iterator.next();
        while (!entry.done) {
            const tId = entry.value;

            newSelectedTopics.set(tId, true)

            entry = iterator.next();
        }

        return newSelectedTopics;
    }

    function selectedTopicsToArray() {
        const newSelectedTopics = [];

        const iterator = selectedTopics.keys();

        let entry = iterator.next();
        while (!entry.done) {
            const tId = entry.value;

            newSelectedTopics.push(tId)

            entry = iterator.next();
        }

        return newSelectedTopics;
    }

    const handleTopicSelect = e => {
        const topicId = e.target.id;

        const newSelectedTopics = getSelectedTopics();

        if (newSelectedTopics.has(topicId)) newSelectedTopics.delete(topicId);
        else newSelectedTopics.set(topicId, true);

        setSelectedTopics(newSelectedTopics);
    }

    useEffect(() => { // request topics
        if (step != 3) return


        Tagg.API.GetTags().then(res => {
            setTagTopics(res.tags)
        })
    }, [step])


    const handleCreateAccount = () => {
        if (registering) return;
        setRegistering(true)

        function doError(err) {
            setRegistering(false);
        }

        Tagg.API.Register.DoRegister(
            {
                username: user,
                email,
                password
            },
            {
                displayName: exhibitionName,
                userInfo: aboutYou,
                tags: selectedTopicsToArray(),
            },
            profileImage?.currentImage,
            bannerImage?.currentImage
        ).then((response) => {
            setRegistering(false);

            //save token
            window.localStorage.setItem("token", response.token);

            navigate("/app");
        }).catch(doError)
    }


    let view = (null);
    let viewText = null;
    let bottomButtons = [];

    switch (step) {
        case 1:

            const step1Validation = emailValidation && passwordValidation && userValidation;
            //true if all of them are true

            function handleStep() {
                setStep(step + 1)
            }

            // bottom buttons
            bottomButtons = [
                {
                    onClick: e => { navigate('/') },
                    child: 'Voltar',
                    disabled: false
                },
                {
                    onClick: handleStep,
                    child: 'Próximo',
                    //disabled: false
                    // disabled: false===step1Validation
                    disabled: step1Validation === false,
                    //true==false is false, so the button isn't disabled
                }
            ]

            function handleUser(user = "") {
                setUser(user.toLowerCase());
                if(user.length < 2) setUserValidation(false);
                else {
                    Tagg.API.Register.UsernameAvailable(user).then((userTaken) => {
                        setUserValidation(!userTaken.value && /^[a-z0-9_.]+$/.test(user)); //LLLL
                    }).catch(() => {
                        setUserValidation(false);
                    })
                }
            }

            function handleEmail(email) {
                setEmail(email);
                Tagg.API.Register.EmailAvailable(email).then((emailTaken) => {
                    setEmailValidation(!emailTaken.value && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email));
                }).catch(() => {
                    setEmailValidation(false);
                })
            }

            function handleConfirmedPassword(confirmedPassword) {
                setConfirmedPassword(confirmedPassword);
                setPasswordValidation(password === confirmedPassword);
            }

            view = (
                <>
                    <TextInput value={user} verified={userValidation} type="text" placeholder="Nome de usuário" onChange={handleUser} errorText={"Nome de usário inválido ou já está em uso"} />
                    <TextInput value={email} verified={emailValidation} type="email" placeholder="E-mail" onChange={handleEmail} errorText={"E-mail inválido ou já está em uso!"} />
                    <PasswordInput value={password} onChange={nPass => setPassword(nPass)} />
                    <TextInput value={confirmedPassword} verified={passwordValidation} type="password" placeholder="Confirmação de senha" onChange={handleConfirmedPassword} errorText={"As senhas não batem"} />
                </>
            );
            viewText = "Informações pessoais"
            break;

        case 2:
            const step2Validation = profileImage && exhibitionNameValidation;
            //choose the ver.


            bottomButtons = [
                {
                    onClick: e => { setStep(step - 1) },
                    child: 'Voltar'
                },
                {
                    onClick: e => { setStep(step + 1) },
                    child: 'Próximo',
                    disabled: !step2Validation //neg of null is true
                }
            ]

            const handleProfileImage = (files) => {
                const currentImage = files[0];
                const imageURL = URL.createObjectURL(currentImage);

                setProfileImage({imageURL, currentImage});
            }

            const handleBannerImage = (files) => {
                const currentImage = files[0];
                const imageURL = URL.createObjectURL(currentImage)

                setBannerImage({imageURL, currentImage})
            }

            function handleExhibitionName(exhibitionName) {
                setExhibitionName(exhibitionName);
                setExhibitionNameValidation(!exhibitionName.includes("@") && exhibitionName.length <= 20 && exhibitionName.length > 0 && exhibitionName != null);
            }


            view = (
                <>
                    <div className={registerStyle.infoBox}>

                        <div>
                        <AvatarEditable editable={true} local={true} handleProfileImage={handleProfileImage} profileImage={profileImage ? profileImage.imageURL : null} />
                        </div>




                        <div className={registerStyle.infoEditContainer}>
                            <TextInput value={exhibitionName} type='text' placeholder='Nome de exibição' onChange={handleExhibitionName} verified={exhibitionNameValidation} errorText={"Nome de exibição contém @ ou possui mais que 20 caracteres"} />
                            <textarea placeholder='Sobre você (opicional)' className={registerStyle.aboutYouInput} />
                        </div>
                    </div>

                    <div className={registerStyle.bannerEditContainer}>
                        <h2 className={"header"}>Altere seu banner <span>(opicional)</span></h2>

                        <div style={{width: "100%", height: 150}}>
                            <BannerEditable editable={true} local={true} handleBannerImage={handleBannerImage} bannerImage={bannerImage ? bannerImage.imageURL : null} />
                        </div>
                            

                    </div>

                </>
            );

            viewText = "Sobre você"
            break;
        case 3:

            const step3Validation = selectedTopics.size >= minSelectedTopics

            bottomButtons = [
                {
                    onClick: e => { setStep(step - 1) },
                    child: 'Voltar'
                },
                {
                    onClick: handleCreateAccount,
                    child: 'Criar conta',
                    disabled: !step3Validation
                }
            ]

            view = (
                <>
                    <h2 className={registerStyle.topicsEditHeader}>
                        Selecione pelo menos {minSelectedTopics} tópicos de seu interesse
                    </h2>

                    <div className={`${registerStyle.topicsContainer} ${tagTopics instanceof Array || registerStyle.topicsContainerLoading}`}>
                        {
                            tagTopics instanceof Array ?
                                (
                                    tagTopics.map((t, i) => {
                                        return (
                                            <Topic selected={selectedTopics.has(t.idTag)}
                                                onClick={handleTopicSelect}
                                                id={t.idTag} key={t.idTag} children={t.tagName} />
                                        )
                                    })
                                ) : (<SpinnerLoader />)
                        }
                    </div>
                </>
            );
            viewText = "Gostos pessoais"

            break;
    }

    return (
        <div className='start-background'>
            <Spheres />     

            <Logo height={"clamp(60px, 5vw, 75px)"} />
            <div className={"loginBox glassContainer " + registerStyle.registerBox}>
                <div className={registerStyle.container}>
                    <div className={"headerBox"}>
                        <h1 className={"header"}>Criar conta - <span className={registerStyle.viewText}>{viewText}</span></h1>
                        <SeparatorLine />
                    </div>
                    {view}
                    <BottomButtonsContainer bottomButtons={bottomButtons} />
                </div>
            </div>


            {
                registering ?
                    (<div className={registerStyle.screenBlocker}>
                        <div className={registerStyle.spinnerContainer}>
                            <SpinnerLoader />
                            <p>Estamos preparando sua conta!</p>
                        </div>
                    </div>) : (null)
            }
        </div>
    )
}

function BottomButtonsContainer({ bottomButtons }) {

    const [btn1, btn2] = bottomButtons

    return (
        <div className={registerStyle.buttonContainer}>
            <Button disabled={btn1.disabled} onClick={btn1.onClick}>{btn1.child}</Button>
            <Button disabled={btn2.disabled} onClick={btn2.onClick}>{btn2.child}</Button>
        </div>
    )
}

function Topic({ children, selected, id, onClick }) {
    return (
        <>
            <div id={id} onClick={onClick} className={`${registerStyle.topic} ${selected && registerStyle.topicSelected}`}>
                {children}
            </div>
        </>
    )
}


