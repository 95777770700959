import useNewNotifications from "../../../hooks/useNewNotifications";
import GetNotificationModel from "../../../shared/components/notifications";
import styles from "./notificationsPopup.module.css";
import { AnimatePresence, motion } from "framer-motion";

export default function NotificationsPopup({ client }) {
  const notifications = useNewNotifications(client);

  return (
    <div className={styles["notificationsPopup"]}>
      <AnimatePresence>
        {notifications
          ? notifications.map((value, i) => {
              return <Notification key={value.idNotification} data={value} />;
            })
          : null}
      </AnimatePresence>
    </div>
  );
}

function Notification({ data }) {
  const Model = GetNotificationModel(data.type);

  if (!Model) {
    console.warn("Notification model doesnt exist " + data.type);
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={styles["notificationsPopupContainer"]}
    >
      <Model data={data} />
    </motion.div>
  );
}
