import style from './individualChat.module.css'
import Button from '../button';
import { AnimatePresence, motion } from 'framer-motion';
import UIEvents from '../../js/events/ui';
import '../components.css'
import Avatar from '../Avatar'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { IconExit, IconAt, IconTyping } from '../../img/icons'
import { ChatBubble, ChatTyping } from '../chatBubble/chatBubble';
import profile from '../../../shared/img/profile.jpeg'
import luiz from '../../../shared/img/luizRicardo/luiz.png'
import TextArea from '../textarea/textarea'
import { deserializeText } from '../textarea/deserializer'
import AddCircle from '../../img/icons/Add circle.svg'
import Emoji from '../../img/icons/Emoji emotions.svg'
import Gif from '../../img/icons/Gif.svg'
import { useLocalUser } from '../../../hooks/useLocalUser';
import moment from 'moment';
import { serialize } from '../textarea/serializer';
import Post, { PostsEnd, PostsLoading } from '../post/post';

export default function IndividualChat({ setSubMenu, idChat, client }) {
    const user = useLocalUser(client);

    const handleClose = () => {
        setSubMenu(null)
    };

    const [messageData, setMessageData] = useState([])
    const [conversation, setConversation] = useState(null)
    const [friend, setFriend] = useState(null)
    const [typing, setTyping] = useState(false)
    const [myTimeOut, setMyTimeOut] = useState(null)

    useEffect(() => {
        if (conversation == null)
            client.getConversation(idChat).then(
                (res) => {
                    setConversation(res.data)
                }
            )
    }, [idChat, conversation])

    useEffect(() => {
        if (conversation) {
            client.emit('CONVERSATION_SUBSCRIBE', idChat)
        }
        return () => {
            if (conversation) {
                client.emit('CONVERSATION_UNSUBSCRIBE', idChat)
            }
        }
    }, [conversation])

    function handleTypingExpire() {
        if (myTimeOut) {
            clearTimeout(myTimeOut);
        }
        setTyping(false)
        setMyTimeOut(null)
    }

    useEffect(() => {
        function handleTyping(friendId) {
            if (friendId == friend.idUser) {
                setTyping(true)
                if (myTimeOut) {
                    clearTimeout(myTimeOut);
                }
                setMyTimeOut(setTimeout(handleTypingExpire, 7000))
            }
        }

        if (conversation) {
            client.on('CONVERSATION_' + idChat + '_TYPING', handleTyping)
        }
        return () => {
            if (conversation) {
                client.off('CONVERSATION_' + idChat + '_TYPING', handleTyping)
            }
        }
    }, [conversation, friend, myTimeOut])


    useEffect(() => {
        if (conversation) {
            const senderId = conversation.sender.idUser
            let isSender = null

            isSender = (user.id == senderId)

            if (isSender) setFriend(conversation.receiver)
            else setFriend(conversation.sender)
        }
    }, [conversation])

    const isOnline = useFriendStatus(friend);

    if (!conversation || !friend) return null;

    return (
        <div className={style.container}>
            <div className={style.chatTop}>
                <div className={style.chatTopTransition}></div>
                <div className={style.topHeader}>
                    <div className={style.friend}>
                        <img src={IconAt} height={23} />
                        <p>{friend.displayname}</p>
                        {isOnline ? <div className={style.sphere}></div> : null}
                    </div>
                    <img onClick={handleClose} className={style.exitIcon} src={IconExit} />
                </div>
            </div>
            <div className={style.chatMain}>
                <ChatMessages client={client} idChat={idChat} friend={friend} handleTyping={handleTypingExpire} />

                {typing ? <RenderTyping friend={friend} /> : null}

            </div>
            <ChatBottom handleClose={handleClose} setConversation={setConversation} user={user} client={client} idChat={idChat} conversation={conversation} />
        </div>
    )
}

function ChatBottom({ handleClose, setConversation, client, idChat, conversation, user }) {
    const authorizedWrite = (conversation.sender.idUser === user.id) || conversation.accepted;

    function AcceptConversation() {
        client.acceptConversation(idChat).then((response) => {
            setConversation(null);
        })
    }

    function DeclineConversation() {
        client.declineConversation(idChat).then((response) => {
            handleClose();
        })
    }

    if (authorizedWrite) {
        return (
            <div className={style.chatBottom}>
                <div className={style.textDiv}>
                    <InputChat client={client} idChat={idChat} />
                </div>
                <div className={style.media}>
                    <div className={style.iconsChat}>
                        <img src={Emoji} className={style.iconChat} style={{ "height": "25px" }} />
                        <img src={Gif} className={style.iconChat} style={{ "height": "25px" }} />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={style.inviteBottom}>
                <span className={style.inviteText}><span style={{color: "var(--purple1)"}}>@{conversation.sender.username}</span> te enviou uma solicitação de mensagem</span>

                <div className={style.buttonsAccept}>
                    <Button onClick={AcceptConversation} style={{backgroundColor: "var(--purple2)"}}>
                        Aceitar
                    </Button>

                    <Button onClick={DeclineConversation}>
                        Recusar
                    </Button>
                </div>
            </div>
        )
    }
}

function InputChat({ client, idChat }) {
    const [text, setText] = useState(deserializeText(''))
    const [dateLastSend, setDateLastSend] = useState(null)


    function handleOnKeyDown(e) {
        if (e.key == "Enter") {
            let formatText = serialize(text).trim();
            if(String(formatText).length === 0) return;
            setText(deserializeText(''))
            client.sendConversationMessage(idChat, formatText, []).then((res) => { console.log(res) })
            setDateLastSend(null)
        }
        else if (isAlphanumericOrSymbol(e.key)) {
        
            const now = moment()
        
            if (dateLastSend == null) {
                client.sendConversationTyping(idChat).then((res) => { console.log(res) })
                setDateLastSend(moment())
            }
            else {
                const differencesInSeconds = now.diff(dateLastSend, "seconds");
        
                if (differencesInSeconds > 5) {
                    client.sendConversationTyping(idChat).then((res) => { console.log(res) })
                    setDateLastSend(moment())
                }
            }
        }
        
        function isAlphanumericOrSymbol(key) {
            // Regular expression to match alphanumeric characters and common symbols.
            const regex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/;
            return regex.test(key);
        }
        
    }

    return (
        <div className={style.textInput}>
            <img className={style.iconChat} src={AddCircle} />
            <div style={{ flex: 1, overflow: "hidden", maxHeight: "110px" }}>
                <TextArea onKeyDown={handleOnKeyDown} value={text} onChange={setText} placeholder="Mensagem..." />
            </div>
        </div>

    )

}

function useFriendStatus(friend) {
    const [lastTimeSeen, setLastTimeSeen] = useState(null);

    useEffect(() => {
        if (friend) {
            setLastTimeSeen(moment(friend.lastTimeSeen));
        }
    }, [friend])

    const now = moment();

    if (lastTimeSeen == null) return false

    const differencesInMinutes = now.diff(lastTimeSeen, "minutes");

    if (differencesInMinutes > 1) {
        return (
            false
        )
    } else {
        return (
            true
        )
    }
}

function RenderTyping({ friend }) {
    return (
        <motion.div className={style.typing} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <ChatTyping avatar={friend.idProfilePicture} gif={IconTyping} />
        </motion.div>
    )
}

function ChatMessages({ client, idChat, friend, handleTyping }) {
    const [messages, setMessages] = useState([]);
    const [skip, setSkip] = useState(0);
    const [offset, setOffset] = useState(0)
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [previousScrollTop, setPreviousScrollTop] = useState(0);
    const [lockedTop, setLockedTop] = useState(false);
    const MAX = 50;
    const user = useLocalUser(client);
    const containerRef = useRef(null);

    useEffect(() => {
        function handleMessages(message) {
            if (message !== null) {
                setMessages((current) => [message, ...current])
                handleTyping()
                setOffset((current) => current + 1);
                client.emit('CONVERSATION_READ', idChat)
                UIEvents.emit("RECENT_CLEAR", idChat);
            }
        }

        function handleRead(reader) {
            if (reader === user.id) return;
            setMessages(messages.map((value) => {
                if (value.idUser !== reader) {
                    return {
                        ...value,
                        readedTime: moment().toISOString()
                    }
                }

                return {
                    ...value
                }
            }));
        }

        if (idChat) {
            client.on('CONVERSATION_' + idChat + '_MESSAGE', handleMessages)
            client.on('CONVERSATION_' + idChat + '_READ', handleRead)
        }
        return () => {
            if (idChat) {
                client.off('CONVERSATION_' + idChat + '_MESSAGE', handleMessages)
                client.off('CONVERSATION_' + idChat + '_READ', handleRead)
            }
        }
    }, [messages, idChat])

    useEffect(() => {
        if (!lockedTop) return;
        setTimeout(() => {
            setLoading(true);
            setSkip((current) => current + MAX);
            setLockedTop(false);
        }, 1000);
    }, [lockedTop])

    function doLoad() {
        if (!hasMore) return;
        client.getConversationMessages(idChat, skip + offset, MAX).then((response) => {
            setHasMore(response.data.messages.length >= MAX);
            setMessages((current) => [...current, ...response.data.messages]);
            setPreviousScrollTop(containerRef.current.scrollTop);
            setLoading(false);

            if (skip === 0) client.emit('CONVERSATION_READ', idChat)
        })
    }

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = previousScrollTop;

            setTimeout(() => {
                if (containerRef.current.scrollTop !== previousScrollTop) {
                    containerRef.current.scrollTop = previousScrollTop;
                }
            }, 50);
        }
    }, [containerRef, messages, previousScrollTop]);

    useEffect(() => {
        UIEvents.emit("RECENT_CLEAR", idChat);
    }, [])

    useEffect(() => {
        setLoading(true);
        doLoad();
    }, [skip]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", onScroll);
        }

        function onScroll(e) {
            if (!e.isTrusted) return;
            const scrollPercentage = Number((Math.abs(e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight))).toFixed(2));
            if (loading || !hasMore || lockedTop) return;
            if (scrollPercentage >= 0.9) {
                setLockedTop(true);
            }
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("scroll", onScroll);
            }
        }
    })

    let showed = false;

    return (
        <>
            <div className={style.messages} ref={containerRef}>
                {
                    messages ? messages.map((message, i) => {
                        const previousMessage = messages[i - 1];

                        let realMessage = null;

                        const isLocal = !(friend.idUser == message.idUser);

                        const showLastSeen = Boolean(message.readedTime && isLocal);

                        if (!isLocal) {
                            realMessage = (<ChatBubble readedTime={message.readedTime} showLastSeen={showLastSeen && !showed && !previousMessage} key={message.idChatMessage} avatar={friend.idProfilePicture} message={message.messageContent} isLocal={isLocal} />)
                        }
                        else {
                            realMessage = (<ChatBubble readedTime={message.readedTime} showLastSeen={showLastSeen && !showed && !previousMessage} key={message.idChatMessage} avatar={user.profile.avatar} message={message.messageContent} isLocal={isLocal} />)
                        }

                        showed = showLastSeen || showed;

                        return realMessage;

                    }) : null
                }


            </div>
        </>
    )
}