import './components.css'
import { IconWhiteTrash } from '../img/icons/index';
import Button from './button';

export default function ExpandPreview({hover, onClick, file}){

    let className = "expandPreview"

    if(hover) className+= " hover"

    const handleClick = (e) => {
        if(onClick) onClick(file)
    }
    
    return(
        <div className={className} onClick={handleClick}>
            <img src={IconWhiteTrash} className='deletePreview'/>
        </div>
    )
}