import { useEffect, useState, useRef } from 'react';
import '../../css/global.css';
import './createPost.css';
import FileUploader from '../fileUploader';
import Button from '../button'
import { IconImg, IconHashtags, IconEmote } from '../../img/icons';
import { RemoteImage } from '../storage/img';
import { useLocalUser } from '../../../hooks/useLocalUser';
import TextArea from '../textarea/textarea';
import { deserializeText } from '../textarea/deserializer';
import { EmotePicker } from '../emote/picker';
import { AnimatePresence, motion } from 'framer-motion';
import ExpandPreview from '../ExpandPreview';
import Tagg from '@taggapp/taggjs-main';
import { serialize } from '../textarea/serializer';
import TranslatableText, { useTranslatableText } from '../../../localization/components/translatableText';
import { useNavigate } from 'react-router-dom';

export default function CreatePost({ clientUser, onCreatePost }) {
    const user = useLocalUser(clientUser);
    const [textContent, setTextContent] = useState(deserializeText(''));
    const [previewFiles, setPreviewFiles] = useState([]);
    const [selectEmote, setSelectEmote] = useState(false);
    const [tagName, setTagName] = useState(null);
    const [tagId, setTagId] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadingStatus, setUploadingStatus] = useState(0);
    const textAreaRef = useRef(null);
    const navigate = useNavigate();
    const [btnPublish, setBtnPublish] = useState(
        {
            child: <TranslatableText k={"publish"} />,
            disabled: true,
            capsOff: true,
            padding: "clamp(0px, 2vh, 3px) clamp(0px, 2vw, 20px)",
            borderRadius: "10px"
        }
    )

    const [btnAddTag, setBtnAddTag] = useState(
        {
            child: <TranslatableText k={"addTag"} />,
            disabled: false,
            capsOff: true,
            padding: "clamp(0px, 2vh, 3px) clamp(0px, 2vw, 20px)",
            width: "136px",
            borderRadius: "10px"
        }
    )

    useEffect(() => {
        if (uploadProgress >= 1) setUploadingStatus(2);
    }, [uploadProgress])

    if (!user) return (null)

    function handleProfile() {
        if(uploadingStatus > 0) return;
        navigate("/app/user/" + user.username);
    }

    function handlePostTextChange(text) {
        if(uploadingStatus > 0) return;
        setTextContent(text);
    }

    function handleAddEmote(emoteData) {
        if(uploadingStatus > 0) return;
        if (textAreaRef.current) {
            textAreaRef.current.addText(emoteData.emoji);
        }
    }

    const handleMedia = (files) => {
        if(uploadingStatus > 0) return;
        setPreviewFiles([...previewFiles, ...files])
    }

    const handleDelete = (fileToDelete) => {
        if(uploadingStatus > 0) return;
        const index = previewFiles.indexOf(fileToDelete)
        const filteredItems = previewFiles.slice(0, index).concat(previewFiles.slice(index + 1, previewFiles.length))
        setPreviewFiles(filteredItems)
    }

    const handleTag = (tagInfo) => {
        if(uploadingStatus > 0) return;
        setBtnPublish({ ...btnPublish, disabled: false })
        setTagName(tagInfo.name)
        setTagId(tagInfo.id)
    }

    const handlePublish = () => {
        if(uploadingStatus > 0) return;
        setUploadProgress(0);
        setUploadingStatus(1);
        clientUser.createPost(tagId, serialize(textContent), previewFiles, (e) => {
            setUploadProgress(e.progress);
        }).then((response) => {
            setUploadingStatus(0);
            if (response.status == 200) {
                if(onCreatePost)
                    onCreatePost(response.data);
                
                setPreviewFiles([])
                setTagName(null)
                setTagId(null)
                setBtnPublish({ ...btnPublish, disabled: true })
                setTextContent(deserializeText(''))
                setBtnAddTag({ ...btnAddTag, child: <TranslatableText k={"addTag"} /> })
                setUploadProgress(0);
            }
        }).catch((err) => {
            setUploadingStatus(0);
            console.error(err)
        });
    }

    return (
        <div className='createPostBox'>
            <div className='createPostBoxTop'>
                <div className='userCreatePostBox' onClick={handleProfile}>
                    <div className='userLeft'>
                        <div className='userImg'>
                            <RemoteImage id={user.profile.avatar} height={100} width={100} />
                        </div>
                    </div>
                    <div className='userRight'>
                        <span className='userDisplayName'>{user.displayname}</span>
                        <span className='userName'>@{user.username}</span>
                    </div>
                </div>
                <div className='addCreatePostBox'>
                    <AddTag disabled={uploadingStatus > 0} onChange={handleTag} btnAddTagState={btnAddTag} btnAddTagSetState={setBtnAddTag}></AddTag>
                </div>
            </div>
            <div className='createPostBoxMain'>
                <TextArea readOnly={uploadProgress > 0 && uploadingStatus > 0} ref={textAreaRef} value={textContent} onChange={handlePostTextChange} placeholder={<TranslatableText k={"createPost_tipPlaceholder"} />} />
            </div>
            {(previewFiles.length != 0) ? (
                <div className='createPostPreview'>
                    <div className='preview'>
                        {previewFiles.map((file) => (
                            <Preview file={file} handleDelete={handleDelete}></Preview>
                        ))}
                    </div>
                </div>
            ) : null}

            <div className='createPostBoxBottom'>
                <div className='createPostInfo'>
                    <FileUploader disabled={uploadingStatus > 0} multiple={true} onChange={handleMedia} accept={"image/gif, image/jpeg, image/png, video/mp4, video/quicktime, video/x-msvideo, video/x-ms-wmv"}>
                        <img src={IconImg} className="createPostMedia" />
                    </FileUploader>
                    <img src={IconHashtags} className="createPostHashtag" />
                    <img data-outside-overriable="true" onClick={() => setSelectEmote(!selectEmote)} src={IconEmote} className="createPostHashtag" />

                    <EmotePicker onClick={handleAddEmote} onClose={() => setSelectEmote(false)} open={selectEmote && uploadingStatus === 0} />
                </div>
                <div className='createPostPublish'>
                    <Button disabled={btnPublish.disabled || uploadingStatus > 0} onClick={handlePublish} fontSize={12} capsOff={btnPublish.capsOff} padding={btnPublish.padding} borderRadius={btnPublish.borderRadius}>{btnPublish.child}</Button>
                </div>
            </div>

            {
                uploadingStatus === 1 ? (
                    <div style={{ marginTop: 10, width: "100%", position: "relative" }}>
                        <span>Publicando... <span style={{ color: "var(--purple2)" }}>{Math.floor(uploadProgress * 100)}%</span></span>
                    </div>
                ) : null
            }

            {
                uploadingStatus === 2 ? (
                    <div style={{ marginTop: 10, width: "100%", position: "relative" }}>
                        <span>Processando...</span>
                    </div>
                ) : null
            }

            {uploadingStatus === 1 ? (
                <div style={{ position: "absolute", top: "100%", left: 0, right: 0, background: "var(--purple1)", height: 2, borderRadius: 4, width: `${uploadProgress * 100}%` }}></div>
            ) : null
            }
        </div>
    )
}

function Preview({ file, handleDelete }) {
    const [blob, setBlob] = useState(null);
    const [blobURL, setBlobURL] = useState(null);
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    useEffect(() => {
        if (!blob) {
            const rawBlob = new Blob([file], { type: file.type });
            setBlob(rawBlob)
        }
    }, [file])

    useEffect(() => {
        if (blob) {
            setBlobURL(URL.createObjectURL(blob));
        }
    }, [blob])

    if (!blob || !blobURL) return null

    let localType = ""

    if (blob.type.includes('image')) {
        localType = "img"
    }
    else if (blob.type.includes('video')) {
        localType = "vdo"
    }
    else if (blob.type.includes('audio')) {
        localType = "aud"
    }

    switch (localType) {
        case 'img':
            return (
                <div className='previewItem'>
                    <div className='previewContent' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <ExpandPreview hover={hover} onClick={handleDelete} file={file}></ExpandPreview>
                        <img src={blobURL} />
                    </div>
                    <div className='previewName'>
                        <p>{file.name}</p>
                    </div>
                </div>
            )
        case 'vdo':
            return (
                <div className='previewItem'>
                    <div className='previewContent' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <ExpandPreview hover={hover} onClick={handleDelete} file={file}></ExpandPreview>
                        <video controls={false} className='previewVideo'>
                            <source src={blobURL} type={blob.type} />
                        </video>
                    </div>
                    <div className='previewName'>
                        <p>{file.name}</p>
                    </div>
                </div>
            )
    }
}

function AddTag({ onChange, btnAddTagState, btnAddTagSetState, disabled }) {
    const [tagVisibility, setTagVisibility] = useState(false)

    function handleAddTag(tagInfo) {
        btnAddTagSetState({ ...btnAddTagState, child: tagInfo.name })
        setTagVisibility(false)
        onChange(tagInfo)
    }

    function handleAddVisibility() {
        setTagVisibility(!tagVisibility)
    }


    return (
        <div className='addTag'>
            <Button disabled={btnAddTagState.disabled || disabled} onClick={handleAddVisibility} fontSize={12} capsOff={btnAddTagState.capsOff} padding={btnAddTagState.padding} width={btnAddTagState.width} borderRadius={btnAddTagState.borderRadius}>{btnAddTagState.child}</Button>
            <TagSelector visibility={tagVisibility && !disabled} tagInfo={handleAddTag}></TagSelector>
        </div>
    )
}

function TagSelector({ visibility, tagInfo }) {
    const [tagArr, setTagArr] = useState(null)

    useEffect(() => {
        if (!tagArr) {
            Tagg.API.GetTags().then((response) => {
                setTagArr(response.tags);
            })
        }
    })

    if (!tagArr) return (null);

    let view = (null);
    if (visibility) {
        view = (
            <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.5 }} className="tagSelector">
                {
                    tagArr.map((value) => (
                        <AddTagItem tag={value} index={tagArr.indexOf(value)} last={tagArr.length} onClick={tagInfo}></AddTagItem>
                    ))
                }
            </motion.div>
        )
    }

    return (
        <AnimatePresence mode={"wait"}>
            {view}
        </AnimatePresence>

    )
}

function AddTagItem({ tag, index, last, onClick }) {

    const handleClick = (e) => {
        if (onClick) onClick({
            name: tag.tagName,
            id: tag.idTag
        })
    }


    let className = "tagItem"
    if (index === last - 1) className += " nonBorder"

    return (
        <div className={className} onClick={handleClick}>
            <p>{tag.tagName}</p>
        </div>
    )
}