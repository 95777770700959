import TranslatableText from '../../../localization/components/translatableText';
import Checkbox from '../checkbox';
import styles from './postFilter.module.css';
import {AnimatePresence, motion} from 'framer-motion';

export function FilterHeaderMenu({ open, setFilterValue, onClose, filters: values }) {
    let view = (null);

    const filters = [
        {
            name: <TranslatableText k={"content"} />,
            values: [
                {
                    name: <TranslatableText k={"text"} />
                },
                {
                    name: <TranslatableText k={"videos"} />
                },
                {
                    name: <TranslatableText k={"images"} />
                }
            ]
        },
        {
            name: <TranslatableText k={"sortBy"} />,
            values: [
                {
                    name: <TranslatableText k={"moreRecents"} />
                },
                {
                    name: <TranslatableText k={"moreOlder"} />
                },
            ]
        },
        {
            name: <TranslatableText k={"relevance"} />,
            values: [
                {
                    name: <TranslatableText k={"morePopular"} />
                },
            ]
        },
    ]

    function handleCheckboxChange(key, value, checkboxValue) {
        setFilterValue(key, checkboxValue ? value : -1);
    }


    if (open) {
        view = (
            <motion.div initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0.5 }} className={styles.homeFiltersMenu}>
                <h2><TranslatableText k={"filterPosts"} /></h2>

                <div className='flexrow flex' style={{ gap: "20px" }}>
                    {
                        filters.map((value, i) => (
                            <ItemFilterHeader key={value.name} title={value.name}>
                                {value.values.map((value, ii) => (
                                    <Checkbox onChange={(value) => handleCheckboxChange(i, ii, value)} value={values[i] === ii} key={value.name} text={value.name} />
                                ))}

                            </ItemFilterHeader>
                        ))
                    }
                </div>
            </motion.div>
        )
    }

    return (
        <AnimatePresence mode={"wait"}>
            {view}
        </AnimatePresence>
    )
}

export function ItemFilterHeader({ title, children }) {
    return (
        <div className={styles.homeFiltersItem}>
            <span><b>{title}</b></span>
            {
                children
            }
        </div>
    )
}