import TextInput from "./textinput"; 
import { useEffect, useState } from "react";
import passwordStrengthStyle from "./passwordStrength.module.css"

export default function PasswordInput({value, onChange}){
    const [password, setPassword] = useState(value);


    function handleChange(text){
        setPassword(text);
        if (onChange) onChange(text);
    }

    return(
        <TextInput type="password" placeholder="Senha" value={value} errorText={<PasswordStrength password={password} />} onChange={handleChange} />
    )
}

function PasswordStrength({password}) {
    const [passwordPoints, setPasswordPoints] = useState(1);
    
    useEffect(() => {
        let sum = 1;

        if(password){
            const containsSpecialCharacter = /\W|_/;
            if(containsSpecialCharacter.test(password)){
                sum++
            }

            const containsNumber = /[0-9]/;
            if(containsNumber.test(password)){
                sum++
            }

            const containsCapital = /[A-Z]/
            if(containsCapital.test(password)){
                sum++
            }
            
            if(password.length>=8){
                sum++
            }

            const containsLowerCase = /[a-z]/
            if(containsLowerCase.test(password)){
                sum++
            }
        }
        
        setPasswordPoints(sum);
    }, [password])

    const toRender = [];

    for(let i = 1; i <= 6; i++) {
        let className = passwordStrengthStyle.lv1;

        if(i <= passwordPoints) {
            className = passwordStrengthStyle["lv" + i];
        }

        toRender.push(<div className={className}></div>)
    }
    
    return (
        <div className={passwordStrengthStyle.container}>
            <span>Força da senha:</span>
            <div className={passwordStrengthStyle.strength}>
                {
                    toRender.map((value) => (
                        <>
                            {value}
                        </>
                    ))
                } 
            </div>
        </div>
    )
}