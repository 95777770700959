import { useEffect, useState } from 'react';
import style from './trendingTopics.module.css';
import { formatCompactNumber } from '../../../utils/formatNumber';

export function TrendingTopics({ client }) {
    const [data, setData] = useState([
    ])

    useEffect(() => {
        if(client) {
            client.getTrendingTopics().then((response) => {
                setData(response.data);
            })
        }
    }, [client])

    return (
        <div className={style.container}>
            <div className={style.header}>
                <h3>
                    O que está acontecendo?
                </h3>
            </div>
            {
                data.map((value) => {
                    return <TrendingTopic data={value} key={value.name} />
                })
            }
        </div>
    )
}

function TrendingTopic({ data }) {
    const {tagData} = data;

    return (
        <div className={style.trendingContainer}>
            <span className={style.trendingHeader}>{tagData.tagName} · Assunto do momento</span>
            <span className={style.trendingName}>{data.name}</span>
            <span className={style.trendingPostsCount}>{formatCompactNumber(data.count)} posts</span>
        </div>
    )
}