import { useRef } from "react";
import Button from "./button";


export default function FileUploader({disabled, onChange, multiple = false, accept = "*", children}) {
    const ref = useRef();

    const handleClick = () => {
        if(disabled) return;
        if(ref.current) {
            ref.current.click();
        }
    }

    const handleChange = (e) => {
        const {files} = e.target;
        if(onChange) onChange(files);
        if(ref.current) {
            ref.current.value = null;
        }
    }

    return (
        <>
            <input multiple={multiple} accept={accept} onChange={handleChange} style={{display: "none"}} ref={ref} type='file' />
            <span style={{width: "100%", height: "100%", display: "flex"}} onClick={handleClick}>
                {children}
            </span>
        </>
    )
}