import '../../shared/css/global.css';
import loginStyle from './login.module.css';
import SeparatorLine from '../../shared/components/separator';
import Button from '../../shared/components/button';
import { Fragment, useLayoutEffect } from 'react';
import Logo from '../../shared/components/logo';
import { useState } from 'react';
import Checkbox from '../../shared/components/checkbox';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../shared/components/textinput';
import Tagg from '@taggapp/taggjs-main';
import { useEffect } from 'react';
import useToken from '../../hooks/useToken';
import { motion } from 'framer-motion'
import CatImages from '../../shared/cat';
import './login.css';
import TranslatableText, { useTranslatableText } from '../../localization/components/translatableText';

export function Spheres() {

    const mobileScale = 0.5;

    return (
        <>
            <div className={loginStyle.sphereContainer} id='sphere-desktop'>
                <Sphere w={282} left={"13%"} top={"10%"} background={"var(--sphere1)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={462} left={"30%"} top={"15%"} background={"var(--sphere2)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={462} left={"20%"} bottom={"1%"} background={"var(--sphere1)"} transform={"rotate(-41.75deg)"} />

                <Sphere w={282} left={"-35%"} bottom={"10%"} background={"var(--sphere1)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={462} left={"-20%"} bottom={"1%"} background={"var(--sphere2)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={400} left={"-30%"} bottom={"40%"} background={"var(--sphere2)"} transform={"rotate(-41.75deg)"} />
            </div>

            <div className={loginStyle.sphereContainer} id='sphere-mobile'>
                <Sphere w={462 * mobileScale} left={"50%"} bottom={"-10%"} background={"var(--sphere2)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={462 * mobileScale} left={"-50%"} bottom={"-10%"} background={"var(--sphere1)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={282 * mobileScale} left={"0%"} bottom={"10%"} background={"var(--sphere2)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={282 * mobileScale} left={"15%"} bottom={"0%"} background={"var(--sphere1)"} transform={"rotate(-41.75deg)"} />
                <Sphere w={282 * mobileScale} left={"-15%"} bottom={"0%"} background={"var(--sphere1)"} transform={"rotate(-41.75deg)"} />
            </div>
        </>
    )
}

export default function IndexPage() {
    const token = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate('/app');
        }
    }, [token])

    return (
        <Fragment>
            <Spheres />

            <div className={"start-background"}>
                <Logo height={'75px'} />

                <LoginBox />
            </div>
        </Fragment>
    )
}

function LoginBox() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [rememberAccount, setRememberAccount] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleCreateAccount = () => {
        navigate("/register");
    }

    const handleLogin = () => {
        setError(null);
        Tagg.API.Login(email, password).then((response) => {
            //save token
            window.localStorage.setItem("token", response.token);

            navigate("/app");
        }).catch((err) => {
            switch (err.response.status) {
                default: setError(err.message); break;
                case 403: setError("E-mail ou senha inválidos"); break;
            }
        })
    }

    return (
        <div className={"loginBox glassContainer"}>

            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className={"inputBox"}>
                <div className={"headerBox"}>
                    <h1 className={"header"}><TranslatableText k={"login_header"} /></h1>
                    <SeparatorLine />
                </div>

                <div className={loginStyle.inputs}>
                    <TextInput value={email} type="text" placeholder={useTranslatableText("email")} onChange={setEmail} />
                    <TextInput value={password} type="password" placeholder={useTranslatableText("password")} onChange={setPassword} />

                    <Checkbox text={<TranslatableText k={"keep_connected"} />} value={rememberAccount} onChange={setRememberAccount} />
                </div>


                <ErrorComponent error={error} />

                <div className={loginStyle.buttonsContainer}>
                    <Button onClick={handleLogin}><TranslatableText k={"login_signin"} /></Button>
                    <Button onClick={handleCreateAccount}><TranslatableText k={"login_signup"} /></Button>
                </div>
            </motion.div>

            <div className={loginStyle.infoBox}>

                <img src={CatImages.CatChat} height={250} />
                <span><TranslatableText k={"login_ad"} /></span>

            </div>
        </div>
    )
}

function ErrorComponent({ error }) {
    if (!error) return (null);

    return (
        <span className={loginStyle.error}>{error}</span>
    )
}

function Sphere({ left, right, bottom, top, background, w, transform }) {
    const [viewportW, setViewportWidth] = useState(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));

    useEffect(() => {
        setViewportWidth(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));

        window.onresize = () => {
            setViewportWidth(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
        }
    }, [document.documentElement.clientWidth, window.innerWidth])

    const width = `${w * 100 / viewportW}vw`;
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={loginStyle.sphere}
            style={{ maxWidth: `${w}px`, maxHeight: `${w}px`, width, height: width, left: `calc(50% - ${left})`, bottom: `${bottom}`, top: `${top}`, background }} />
    )
}