import { useEffect, useState } from 'react';
import styles from './components.css'
import VerifiedIcon from '../../shared/img/VerifiedIcon.svg';

export default function TextInput({ disabled, style, verified, type, placeholder, onChange, errorText, value, onKeyDown }) {
    const [text, setText] = useState(value ? value : '');

    useEffect(() => {
        if(value !== text) setText(value ? value : '')
    }, [value])

    return (
        <div className='inputContainer' style={disabled ? {opacity: 0.5} : {}}>
            <div className='container'>
                <input disabled={disabled} style={style} onKeyDown={onKeyDown} className={"input"} value={text} onChange={(e) => {
                    setText(e.target.value);
                    if (onChange) onChange(e.target.value);
                    // if callback is defined, passes the param
                    //the param it's what the user typed
                }} type={type} placeholder={placeholder}

                />
                {
                    verified ? (
                        <img src={VerifiedIcon} />
                    ) : (null)
                }
            </div>
            {
                !verified && text.length > 0 ? (
                    <span style={{ color: "var(--red2)" }}>{errorText}</span>
                ) : (null)
            }

        </div>
    )
}

export function ContainedTextInput({ max, verified, type, placeholder, onChange, errorText, value }) {
    const [text, setText] = useState(value ? value : '');

    useEffect(() => {
        if(value !== text) setText(value ? value : '')
    }, [value])

    return (
        <div className='textInputContainer'>
            <span className='placeholder'>{placeholder}</span>
            <div className='container'>
                <input max={max} className={"input"} value={text} onChange={(e) => {
                    setText(e.target.value);
                    if (onChange) onChange(e.target.value);
                    // if callback is defined, passes the param
                    //the param it's what the user typed
                }} type={type}

                />
                {
                    verified ? (
                        <img src={VerifiedIcon} />
                    ) : (null)
                }
            </div>
            {
                !verified && text.length > 0 ? (
                    <span style={{ color: "var(--red2)" }}>{errorText}</span>
                ) : (null)
            }

        </div>
    )
}