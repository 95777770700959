import style from './rightMenu.module.css'
import { IconAdd, IconExit, IconExplore, IconProfile, IconProfile2User, IconSearch } from '../img/icons'
import MembersIcon from './rooms/membericon.svg';
import Separator from './separator'
import UIEvents from '../js/events/ui';

import { useEffect, useRef, useState } from 'react'
import Header from './RightMenuHeader'
import Searchbar from './Searchbar'
import Button from './button';
import styles from './roomsMenu.module.css';
import { RemoteImage } from './storage/img';
import { Dialog } from './dialog';
import { useNavigate } from 'react-router-dom';

function RoomInviteDialog({ updateRooms, client, currentInvite, setCurrentInvite }) {
    let view = null;
    const navigate = useNavigate();

    function handleAccept() {
        client.acceptRoomRequest(currentInvite.room.idRoom).then((response) => {
            setCurrentInvite(null); 
            navigate("/app/rooms/" + currentInvite.room.idRoom);
        })
    }

    function handleDecline() {
        client.rejectRoomRequest(currentInvite.room.idRoom).then((response) => {
            setCurrentInvite(null); 
            updateRooms();
        })
    }

    if (currentInvite) {
        const {inviter} = currentInvite;

        view =
            (
                <div className={styles.dialogContainer}>
                    <div>
                        <h3>Convite para sala de interação</h3>
                    </div>
                    <div className={styles.dialogContainerContent}>
                        <div className={styles.inviter}>
                            <RemoteImage id={inviter.idProfilePicture} height={48} width={48} imageStyle={{ borderRadius: "50%" }} />
                            <span className={styles.inviterText}>{inviter.displayname}</span>
                        </div>
                        <div className={styles.roomDetails}>
                            <span className={styles.roomTip}>convidou você para a sala de interação</span>
                            <span>“<span className={styles.roomText}>{currentInvite.room.roomName}</span>”</span>
                        </div>
                    </div>
                    <div className={styles.inviteButtons}>
                        <Button onClick={handleDecline}>Recusar</Button>
                        <Button onClick={handleAccept} style={{backgroundColor: "var(--purple2)"}}>Aceitar</Button>
                    </div>
                </div>
            )
    }

    return (
        <Dialog open={Boolean(currentInvite)} onClose={() => setCurrentInvite(null)}>
            {view}
        </Dialog>
    )

}

export default function RoomsMenu({ client }) {
    const [data, setData] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [currentInvite, setCurrentInvite] = useState(null);

    useEffect(() => {
        if (!data) {
            client.getRooms().then((response) => {
                setData(response.data);
            })
        }
    }, [data])

    function handleCreateRoom() {
        UIEvents.emit("room.create");
    }

    function updateRooms() {
        setData(null);
    }

    function handleInvite(invite) {
        setCurrentInvite(invite);
    }

    return (
        <>
            <RoomInviteDialog updateRooms={updateRooms} client={client} currentInvite={currentInvite} setCurrentInvite={setCurrentInvite} />
            <div className={style.container}>
                <Header style={{ marginBottom: "0.5rem" }} title='Salas de interação' />
                <Separator />
                <br></br>

                <div className='flex flexrow' style={{ gap: 10 }}>
                    <Button onClick={() => setCurrentTab(0)} disabled={currentTab === 0} capsOff={true} style={{ flex: 1 }}>
                        Todas
                    </Button>
                    <Button onClick={() => setCurrentTab(1)} disabled={currentTab === 1} capsOff={true} style={{ flex: 1 }}>
                        Convites
                    </Button>
                </div>
                <Searchbar placeholder='Buscar salas de interação...' />

                <div className='flex flexcol' style={{ flex: 1, gap: 10 }}>
                    {
                        data ? (

                            currentTab === 0 ? (
                                data.rooms.map((room) => {
                                    return (
                                        <Room data={room} key={room.idRoom} />
                                    )
                                })
                            ) : (
                                data.pending.map((room) => {
                                    return (
                                        <RoomInvite onClick={handleInvite} data={room} key={room.idRoom} />
                                    )
                                })
                            )

                        ) : null
                    }
                </div>

                <div className='flex flexcol' style={{ gap: 5 }}>
                    <CustomButton onClick={handleCreateRoom} icon={IconAdd}>Criar sala</CustomButton>
                    <CustomButton icon={IconExplore}>Explorar salas</CustomButton>
                </div>
            </div>
        </>
    )
}


function RoomInvite({ data, onClick }) {
    const [defaultAvatar, setDefaultAvatar] = useState({});

    function stringToUniqueNumber(s) {
        let hash = 0, i, chr;
        if (s.length === 0) return hash;

        for (i = 0; i < s.length; i++) {
            chr = s.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        return Math.abs(hash);
    }

    useEffect(() => {
        if (data) {
            if (!data.iconPath) {
                const index = stringToUniqueNumber(data.room.idRoom);
                setDefaultAvatar(index % colors.length)
            }
        }
    }, [data]);

    const colors = [
        { r: 255, g: 105, b: 180 }, // Darker Red
        { r: 50, g: 205, b: 50 },   // Darker Green
        { r: 70, g: 130, b: 180 },  // Darker Blue
        { r: 255, g: 215, b: 0 },   // Darker Yellow
        { r: 72, g: 209, b: 204 },  // Darker Cyan
        { r: 238, g: 130, b: 238 }, // Darker Magenta
        { r: 192, g: 192, b: 192 }  // Grey (instead of White)
    ];


    const c = !isNaN(defaultAvatar) ? colors[defaultAvatar] : {};

    return (
        <div>
            <div className={styles.room} onClick={() => {
                if (onClick) onClick(data);
            }}>
                {
                    defaultAvatar != null ? (
                        <div style={{ backgroundColor: `rgb(${c.r},${c.g},${c.b})` }} className={styles.roomAvatar}>
                            <img src={MembersIcon} />
                        </div>
                    ) : (null)
                }
                <div className={styles.roomInfo}>
                    <span className={styles.roomName}>{data.room.roomName}</span>
                    <span className={styles.roomMembers}>{data.membersCount} membros</span>
                </div>
            </div>
        </div>
    )
}

function Room({ data }) {
    const navigate = useNavigate();

    const [defaultAvatar, setDefaultAvatar] = useState({});

    function stringToUniqueNumber(s) {
        let hash = 0, i, chr;
        if (s.length === 0) return hash;

        for (i = 0; i < s.length; i++) {
            chr = s.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        return Math.abs(hash);
    }

    const colors = [
        { r: 255, g: 105, b: 180 }, // Darker Red
        { r: 50, g: 205, b: 50 },   // Darker Green
        { r: 70, g: 130, b: 180 },  // Darker Blue
        { r: 255, g: 215, b: 0 },   // Darker Yellow
        { r: 72, g: 209, b: 204 },  // Darker Cyan
        { r: 238, g: 130, b: 238 }, // Darker Magenta
        { r: 192, g: 192, b: 192 }  // Grey (instead of White)
    ];

    useEffect(() => {
        if (data) {
            if (!data.iconPath) {
                const index = stringToUniqueNumber(data.idRoom);
                setDefaultAvatar(index % colors.length)
            }
        }
    }, [data]);

    const c = !isNaN(defaultAvatar) ? colors[defaultAvatar] : {};

    function handleClick(e) {
        e.stopPropagation();
        e.preventDefault();
        navigate("/app/rooms/" + data.idRoom);
    }

    return (
        <div onClick={handleClick} className={styles.room}>
            {
                defaultAvatar != null ? (
                    <div style={{ backgroundColor: `rgb(${c.r},${c.g},${c.b})` }} className={styles.roomAvatar}>
                        <img src={MembersIcon} />
                    </div>
                ) : (null)
            }
            <div className={styles.roomInfo}>
                <span className={styles.roomName}>{data.roomName}</span>
                <span className={styles.roomMembers}>{data.membersCount} membros</span>
            </div>
        </div>
    )
}

function CustomButton({ children, icon, onClick }) {
    return (
        <div onClick={onClick} className={style.roomCustomButton}>
            <img src={icon} />
            {children}
        </div>
    )
}