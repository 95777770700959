import { useEffect, useState } from "react";

export function useLocalUser(client) {
    const [user, setUser] = useState(client.user || {profile: {}});

    useEffect(() => {
        function onUser(newUser) {
            if(newUser.id == user.id || !user.id)
                setUser({
                    ...user,
                    ...newUser
                });
        }

        if(client) {
            client.on("USER", onUser);

            if(user) {
                client.on("USER_" + user.id, onUser);
            }
        }

        return () => {
            if(client) {
                client.off("USER", onUser);

                if(user) {
                    client.off("USER_" + user.id, onUser);
                }
            }
        }
    })

    return user;
}