import '../../../shared/css/global.css';
import './profile.css';
import { AvatarEditable, BannerEditable } from '../../../shared/components/avatar/avatareditable';
import UIEvents from '../../../shared/js/events/ui'
import { useState, useEffect, useRef } from 'react';
import { useLocalUser } from '../../../hooks/useLocalUser';
import Button from '../../../shared/components/button';
import { IconBell, IconPencil, IconThreeDots, IconPersonRemove, IconTrash } from '../../../shared/img/icons';
import UserStats from '../../../shared/components/UserStats';
import { TagListContainer } from '../../../shared/components/TopHighlights';
import { useLocation, useParams } from 'react-router-dom';
import { useForeignUser } from '../../../hooks/useForeignUser';
import SpinnerLoader from '../../../shared/img/spinnerloading.svg';
import moment from 'moment';
import NotFound from '../404/notfound';
import TextArea from '../../../shared/components/textarea/textarea';
import useEditProfile from '../../../hooks/useEditProfile';
import style from './profile.module.css';
import { deserializeText } from '../../../shared/components/textarea/deserializer';
import { serialize } from '../../../shared/components/textarea/serializer';
import { AnimatePresence, motion } from 'framer-motion';
import LoadingScreen from '../../../shared/components/loadingScreen/loading';
import { AlertTip } from '../../../shared/components/alert/alert';
import CatImages, { CatMetadata } from '../../../shared/cat';
import { EmoteComponent } from '../../../shared/components/emote/emote';
import { BottomContainer } from '../../../shared/components/bottomContainer/bottomContainer';
import TranslatableText, { useTranslatableText } from '../../../localization/components/translatableText';
import { setTitle } from '../../../hooks/setTitle';
import CreatePost from '../../../shared/components/createPost/createPost';
import Post, { PostsEnd, PostsLoading } from '../../../shared/components/post/post';
import UsersRecommendation from '../../../shared/components/usersRecommendation/userRecommendation';
import { ContextMenu } from '../../../shared/components/contextMenu/contextMenu';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import LoadingLogo from '../../../shared/components/loading';
import { Dialog } from '../../../shared/components/dialog';
import { RightPanels } from '../../../shared/components/rightPanels/rightPanels';



function UserStatus({ user }) {
    const [lastTimeSeen, setLastTimeSeen] = useState(null);

    useEffect(() => {
        if (user) {
            setLastTimeSeen(moment(user.lastTimeSeen));
        }
    }, [user])

    if (!lastTimeSeen) return (
        <span>Offline</span>
    )

    const now = moment();

    const differencesInMinutes = now.diff(lastTimeSeen, "minutes");

    if (differencesInMinutes > 1) {
        return (
            <span className='userStatus'>{'Visto por último ' + lastTimeSeen.calendar().toLowerCase()}</span>
        )
    } else {
        return (
            <span className='userStatus userStatusOnline'>{'Disponível'}</span>
        )
    }
}

function SkeletonProfile() {
    return (
        <LoadingScreen open={true} />
    )
}

export default function Profile({ client }) {
    const { username } = useParams();

    const [mobileButtonsDialogIsOpen, setMobileButtonsDialogIsOpen] = useState(false)
    const [loadingProfileAction, setLoadingProfileAction] = useState(false);

    const location = useLocation();
    const localUser = useLocalUser(client);
    const [user, setUser, notFound] = useForeignUser(username, client);

    const [editingProfile, setEditingProfile] = useState(false);

    const [changesNotSaved, setChangesNotSaved] = useState(false);

    const [profileChanges, allValues, addChange, resetChanges] = useEditProfile(
        user ?
            {
                'userInfo': deserializeText(user.profile.userInfo || '')
            }
            : {},
        user
    );

    useEffect(() => {
        setUser(null);
        setEditingProfile(null);
    }, [username, location]);

    const [savingProfile, setSavingProfile] = useState(false);

    function handleUserInfo(value) {
        addChange('userInfo', value);
    }

    setTitle(null);

    if (notFound) {
        return (
            <NotFound />
        )
    }

    if (!localUser) return (null);

    if (!user) return (<SkeletonProfile />);

    setTitle(user.displayname);

    let isLocalUser = user.id === localUser.id;

    function followUser() {
        if (loadingProfileAction) return;
        setLoadingProfileAction(false);
        if (isLocalUser) return;

        setLoadingProfileAction(true);
        client.followUser(user.username).then((response) => {
            setLoadingProfileAction(false);

            const { data } = response;

            if (data.value) {
                user.profile.followers += 1;
            } else user.profile.followers -= 1;

            user.following = data.value == true;

            setUser({
                ...user
            })
        })
    }

    function handleProfileImage(files) {
        const file = files[0];

        UIEvents.emit("avatar.crop", file);
    }

    function handleBannerImage(files) {
        const file = files[0];

        UIEvents.emit("banner.crop", file);
    }

    function handleSaveProfile() {
        setChangesNotSaved(false);

        const saveData = {
            userInfo: serialize(profileChanges['userInfo'])
        }

        setSavingProfile(true);

        client.saveProfile(saveData).then((response) => {
            setUser(
                {
                    ...user,
                    profile: {
                        ...user.profile,
                        userInfo: saveData.userInfo
                    }
                }
            )

            setEditingProfile(false);
            setSavingProfile(false);
        }).catch(() => {
            setSavingProfile(false);
        })
    }

    const handleOpenButtonsDialog = () => setMobileButtonsDialogIsOpen(!mobileButtonsDialogIsOpen)
    const handleEditProfile = () => {
        if (editingProfile && Object.keys(profileChanges).length > 0) {
            setChangesNotSaved(true);
            return;
        }
        setEditingProfile(!editingProfile);
        if (editingProfile) {
            resetChanges();
        }
    }

    return (
        <>
            <LoadingScreen open={savingProfile} />
            <SaveChangesConfirm handleSave={handleSaveProfile} handleReset={() => {
                setEditingProfile(false);
                resetChanges();
                setChangesNotSaved(false);
            }} open={changesNotSaved} />

            {
                isLocalUser ?
                    (
                        <AlertTip hidder={editingProfile} style={{ bottom: 20, left: 0, right: 0, margin: "0 auto", maxWidth: "max-content" }}>
                            <img
                                style={{ verticalAlign: "middle" }}
                                src={CatImages.Star}
                                height={32}
                            />
                            <span style={{ textAlign: "center " }}><b><TranslatableText k={"tutorialTip"} args={[CatMetadata.name]} /></b>: <TranslatableText k={"profileLocalUserTip"} />
                                &nbsp;<EmoteComponent emote={":beach:"} />
                            </span>
                        </AlertTip>
                    ) :

                    (
                        <AlertTip hidder={user.following} style={{ bottom: 20, left: 0, right: 0, margin: "0 auto", maxWidth: "max-content" }}>
                            <img
                                style={{ verticalAlign: "middle" }}
                                src={CatImages.Star}
                                height={32}
                            />
                            <span style={{ textAlign: "center " }}><b><TranslatableText k={"tutorialTip"} args={[CatMetadata.name]} /></b>: <TranslatableText k={"profileExternalUserTip"} args={[user.username]} />
                                &nbsp;<EmoteComponent emote={":star_struck: "} />
                            </span>
                        </AlertTip>
                    )
            }

            <div className='content'>
                <div className='banner'>
                    <BannerEditable editable={isLocalUser && editingProfile} bannerImage={user.profile.banner} handleBannerImage={handleBannerImage} />
                    <div className='userData'>
                        <div className='avatar'>
                            <AvatarEditable editable={isLocalUser && editingProfile} profileImage={user.profile.avatar} handleProfileImage={handleProfileImage} />
                        </div>
                        <div className='userInfo'>
                            <span className='displayName'>{user.displayname}</span>
                            <span className='userName'>@{user.username}</span>
                            <UserStatus user={user} />
                        </div>
                    </div>
                </div>

                <div className='main'>
                    <div className='header'>
                        {
                            !editingProfile ?
                                (
                                    <div className='buttons'>

                                        <div className="desktopButtons">
                                            {
                                                isLocalUser ? (
                                                    <Button onClick={handleEditProfile}>
                                                        <span className="desktopEditProfile"><TranslatableText k={"editProfile"} /></span>
                                                        <div className="mobileEditProfile">
                                                            <img src={IconPencil} alt="pencil icon" />
                                                        </div>
                                                    </ Button >

                                                ) :
                                                    (
                                                        <>
                                                            <Button className='notificationsButton'>
                                                                <img src={IconBell} />
                                                            </Button>
                                                            {
                                                                user.following ? (
                                                                    <Button onClick={followUser} className=''>
                                                                        {
                                                                            loadingProfileAction ? (<img src={SpinnerLoader} />) :
                                                                                <TranslatableText k={"unfollow"} />
                                                                        }
                                                                    </Button>
                                                                ) :
                                                                    (
                                                                        <Button style={{ backgroundColor: "var(--purple4)" }} onClick={followUser} className=''>
                                                                            {
                                                                                loadingProfileAction ? (<img src={SpinnerLoader} />) :
                                                                                    <TranslatableText k={"follow"} />
                                                                            }
                                                                        </Button>
                                                                    )
                                                            }
                                                        </>
                                                    )
                                            }



                                        </div>
                                        <div className='mobileButtons'>

                                            {

                                                isLocalUser ? (
                                                    <Button onClick={handleEditProfile} >
                                                        <img src={IconPencil} />
                                                    </Button>
                                                ) :
                                                    (

                                                        <Button onClick={handleOpenButtonsDialog} >
                                                            <img className='threeDotsButton' src={IconThreeDots} />
                                                        </Button>
                                                    )
                                            }

                                            {
                                                // Only for non local users
                                                mobileButtonsDialogIsOpen &&
                                                (
                                                    <div className='mobileButtonsContainer glassContainer'>
                                                        <div className='button-item'>
                                                            <div className="buttonIconContainer"><img src={IconBell} alt="notifications" /></div>
                                                            <span>Ativar notificações</span>
                                                        </div>

                                                        <div className='button-item'>
                                                            {
                                                                user.following ? (
                                                                    <Button onClick={followUser} className=''>
                                                                        {
                                                                            loadingProfileAction ? (<img src={SpinnerLoader} />) :
                                                                                <TranslatableText k={"unfollow"} />
                                                                        }
                                                                    </Button>
                                                                ) :
                                                                    (
                                                                        <Button style={{ backgroundColor: "var(--purple4)" }} onClick={followUser} className=''>
                                                                            {
                                                                                loadingProfileAction ? (<img src={SpinnerLoader} />) :
                                                                                    <TranslatableText k={"follow"} />
                                                                            }
                                                                        </Button>
                                                                    )

                                                            }



                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>


                                    </div>
                                ) :
                                (
                                    <div className='buttons'>

                                        <div className="desktopButtons">
                                            <Button onClick={handleEditProfile}>
                                                <span className="desktopEditProfile"><TranslatableText k={"stopEditing"} /></span>
                                                <div className="mobileEditProfile">
                                                    <img src={IconPencil} alt="pencil icon" />
                                                </div>
                                            </ Button >
                                        </div>
                                    </div>
                                )
                        }
                    </div>

                    <div className='descriptionContainer'>
                        <div className="descriptionInfo">
                            <div className='userDescriptionContainer'>
                                <h2 className='subtitle'><TranslatableText k={"description"} /></h2>
                                <div className='userDescriptionField'>
                                    <div className='userDescriptionViewport'>
                                        <div>
                                            <TextArea value={allValues.userInfo} onChange={handleUserInfo} readOnly={!editingProfile} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='userStats'>
                                <UserStats followers={user.profile.followers} following={user.profile.following} communities={0} posts={user.profile.posts} />
                            </div>
                        </div>
                    </div>

                    <div className='favoriteTags'>
                        <h2 className='subtitle'><TranslatableText k={"favoriteTags"} /></h2>
                        <TagListContainer tagList={user.profile.tags} />
                    </div>
                    <div className='mainPosts'>
                        <div className='mainPostsLeft'>
                            {/*filters*/}
                            {(isLocalUser && !editingProfile) ? <CreatePost clientUser={client} /> : null}
                            {!editingProfile ? <UserPosts clientUser={client} user={user} /> : null}

                        </div>
                        <div className='mainRight'>
                            <RightPanels client={client} />
                        </div>
                    </div>
                </div>

                <AnimatePresence mode={"wait"}>
                    {
                        editingProfile && Object.keys(profileChanges).length > 0 ? <SaveProfileChanges handleSaveProfile={handleSaveProfile} handleResetChanges={() => {
                            setEditingProfile(false);
                            resetChanges();
                            setChangesNotSaved(false);
                        }} TotalChanges={Object.keys(profileChanges).length} /> : null
                    }
                </AnimatePresence>
            </div>
        </>
    )
}

function SaveChangesConfirm({ open, handleSave, handleReset }) {
    if (!open) return null;
    return (
        <Dialog onClose={handleReset} open={open}>
            <div className={style.dialogContainer}>
                <h4>Você tem alterações não salvas, você deseja salvá-las?</h4>
                <div style={{ display: "flex", width: "100%", gap: 20 }}>
                    <Button onClick={handleReset} style={{ flex: 1 }}>Descartar</Button>
                    <Button onClick={handleSave} style={{ background: "var(--purple1)", flex: 1 }}>Salvar</Button>
                </div>
            </div>
        </Dialog>
    )
}

function SaveProfileChanges({ TotalChanges, handleSaveProfile, handleResetChanges }) {
    return (
        <BottomContainer>
            <span>Cuidado — você tem {TotalChanges > 1 ? (TotalChanges + " alterações que não foram salvas.") : "1 alteração que não foi salva."}</span>
            <Button onClick={handleResetChanges}>Redefinir</Button>
            <Button onClick={handleSaveProfile}>Salvar</Button>
        </BottomContainer>
    )
}

function UserPosts({ clientUser, user }) {
    const [userPosts, setUserPosts] = useState([])
    const [skip, setSkip] = useState(0)
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const MAX = 10;

    const [contextMenu, setContextMenu] = useState(null);
    const contextMenuRef = useRef();

    useEffect(() => {
        const onPostCreated = (post) => {
            setUserPosts((current) => [post, ...current]);
            setSkip((current) => current + 1);
        }

        if (clientUser) {
            clientUser.on("POST_" + user.id + "_CREATE", onPostCreated)
        }

        return () => {
            if (clientUser) {
                clientUser.off("POST_" + user.id + "_CREATE", onPostCreated)
            }
        }
    }, [clientUser, skip])

    function onContextMenu(e, post) {
        e.preventDefault();
        e.stopPropagation();

        const { pageX, pageY } = e;

        setContextMenu({
            x: pageX,
            y: pageY,
            post
        });
    }

    useOnClickOutside(contextMenuRef, () => {
        setContextMenu(null);
    });

    useEffect(() => {
        setContextMenu(null);
    }, [userPosts])

    function onPostDeleted(post) {
        setUserPosts((current) => current.filter((a) => a.idPost !== post.idPost));
    }

    useEffect(() => {
        if (hasMore) {
            setLoading(true);
            clientUser.getUserPosts(user.username, skip).then((response) => {
                setLoading(false);
                setHasMore(response.data.posts.length >= MAX);

                if (userPosts === null) {
                    setUserPosts(response.data.posts)
                }
                else {
                    setUserPosts((prevPosts) => [...prevPosts, ...response.data.posts]);
                }
            })
        }

    }, [skip])

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        function onScroll(e) {
            if (loading) return;
            if ((e.target.scrollingElement.scrollTop / (e.target.scrollingElement.scrollHeight - e.target.scrollingElement.clientHeight)) > 0.75) {
                setSkip((prevSkip) => prevSkip + 10)
            }
        }
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    })

    function onPostDelete(post) {
        const index = userPosts.indexOf(post);
        if (index >= 0) {
            userPosts[index]['deleted'] = true;
            setUserPosts([...userPosts]);
        }

        clientUser.deletePost(post.idPost);
    }

    return (
        <div className='posts'>
            {userPosts.map((post) => (
                <Post onPostDeleted={onPostDeleted} onContextMenu={onContextMenu} key={post.idPost} clientUser={clientUser} post={post} />
            ))}

            {
                loading ? <PostsLoading /> : null
            }

            {
                !hasMore && !loading ? <PostsEnd /> : null
            }

            {
                contextMenu ? (
                    <PostContextMenu
                        reference={contextMenuRef}
                        x={contextMenu.x}
                        y={contextMenu.y}
                        post={contextMenu.post}
                        onPostDelete={onPostDelete}
                        clientUser={clientUser}
                    />
                ) : null
            }
        </div>
    )
}

function PostContextMenu({ reference, x: propX, y: propY, post, onPostDelete, clientUser }) {
    const localUser = useLocalUser(clientUser);
    if (localUser.id == post.user.idUser) {
        return (
            <ContextMenu absolute={true} x={propX} y={propY} reference={reference}>
                <div onClick={() => onPostDelete(post)} className="contextMenuItem">
                    <span className='contextMenuItemDelete'>Apagar postagem</span>
                    <img src={IconTrash} />
                </div>
            </ContextMenu>
        )
    }
}