const Translations = {
    login_header: "Login",
    login_signin: "Login",
    login_signup: "Sign up",
    login_ad: "Tagg is the perfect place for amazing stories. Come tell yours!",

    keep_connected: "Keep me connected",
    email: "Email",
    password: "Password",
    followers: "Followers",
    following: "Following",
    posts: "Posts",
    viewProfile: "View profile",
    filter: "Filter",
    explore: "Explore",
    publish: "Post",
    addTag: "+ Add Tag",
    description: "About me",
    favoriteTags: "Favorite tags",
    follow: "Follow",
    unfollow: "Unfollow",
    stopEditing: "Stop editing",
    editProfile: "Edit profile",
    content: "Content",
    sortBy: "Sort by",
    relevance: "Relevance",
    text: "Text only",
    videos: "Videos",
    images: "Images",
    moreRecents: "Newer to older",
    moreOlder: "Older to newer",
    morePopular: "Trending",
    recent: "Recent",

    filterPosts: "Filter post",
    

    menu_profile: "Profile",
    menu_notifications: "Notifications",
    menu_home: "Home",
    menu_friends: "Friends",
    menu_conversations: "Chats",
    menu_interactrooms: "Rooms",
    menu_tags: "Tags",
    menu_settings: "Settings",

    friendsSearch: "Search friends...",

    home_featuring: "Trending",

    createPost_tipPlaceholder: "What is on your mind?",

    tutorialTip: "{0}'s tip" /* {0} = Chatty */,

    profileLocalUserTip: "You can edit your profile clicking on the 'Edit profile' button!",
    profileExternalUserTip: "You can follow {0} clicking on the 'Follow' button!" /* {0} = external username*/,

    settingsMenuUser: "USER",
    settingsMenuUserAccount: "My account",
    settingsMenuUserSecurity: "Security",
    settingsMenuUserDevices: "Devices",

    settingsMenuApp: "APPLICATION",
    settingsMenuAppAppearence: "Appearance",
    settingsMenuAppLanguage: "Language",
    settingsMenuAppVoiceAndVideo: "Voice and video",

    displayName: "Display name",
    accountRemoval: "Account removal",
    accountDisableTip: "Disabling your account means you can recover it whenever you'd like.",
    changeProfilePicture: "Change profile picture",
    changeProfileBanner: "Change banner",
    disableAccount: "Disable account",
    deleteAccount: "Delete account",
    
    settingsDevicesTip: "Here are all the devices connected to your Tagg App account. You can logout of each one separately or all of them at once.",
    settingsDevicesWarning: "If you don´t recognize a post made in your account, immediately check for unknown devices connected and change your password.",

    currentDevice: "Current device",
    otherDevices: "Other devices"
};

export default Translations;