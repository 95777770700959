import { setTitle } from '../../../../../hooks/setTitle';
import { LOCALE } from '../../../../../localization';
import UIEvents from '../../../../../shared/js/events/ui';
import style from './language.module.css';

export function SettingsLanguage() {
    setTitle("Idioma");

    const availableLanguages = Object.keys(LOCALE);

    function handleLanguageChange(key) {
        console.log(key);
        UIEvents.emit("language.change", key);
    }

    return (
        <>
            <h3>Idioma</h3>
            <span className={style.tip}>SELECIONE UM IDIOMA</span>

            <div className={style.languages}>
            {
                availableLanguages.map((key) => (
                    <LanguageBox id={key} key={key} onClick={handleLanguageChange} name={LOCALE[key].name} />
                ))
            }
            </div>
        </>
    )
}

function LanguageBox({name, id, onClick}) {
    return (
        <div onClick={() => onClick(id)} className={style.languageBox}>
            {name}
        </div>
    )
}