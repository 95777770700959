import { useEffect, useState } from 'react';
import logo from '../img/logo.svg'
import rotatedLogo from '../img/rotatedLogo.svg';
import './components.css'

export default function Logo ({ height, rotated }) {
    const [preload, setPreload] = useState(false);

    useEffect(() => {
        if(!preload) {
            const imgs = [logo, rotatedLogo];
            for(let i = 0; i < imgs.length; i++) {
                const img = new Image();
                img.src = imgs[i];
            }
            setPreload(true);
        }
    }, [preload]);

    return (
        <div className='logo'>
            <img style={{height}} src={rotated ? rotatedLogo : logo} type="image/svg+xml"></img>
        </div>
    )
}