import { useEffect, useRef, useState } from "react"
import { motion } from 'framer-motion'
import styles from './draw.module.css'
import TextInput from "../../../../../shared/components/textinput";

export default function DrawGame({ user, activityHandler }) {
    const ref = useRef();
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const { roomClient } = activityHandler;

        function onChatMessage({ username, message }) {

            setMessages((messages) => [{ username, message, type: "message" }, ...messages]);

        }

        function onAlmost({ username, message }) {
            setMessages((messages) => [{ username, message, type: "almost" }, ...messages]);
        }

        function onWin({ username }) {
            setMessages((messages) => [{ username, type: "win" }, ...messages]);
        }

        function onDrawer({username}) {
            setMessages((messages) => [{ username, type: "drawer" }, ...messages]);
        }

        roomClient.on("CHAT", onChatMessage);
        roomClient.on("WIN", onWin);
        roomClient.on("ALMOST", onAlmost);
        roomClient.on("DRAWER", onDrawer);

        return () => {
            roomClient.off("CHAT", onChatMessage);
            roomClient.off("ALMOST", onAlmost);
            roomClient.off("WIN", onWin);
            roomClient.off("DRAWER", onDrawer);
        }
    });

    const { drawer, theme, started } = useDraw(ref, activityHandler.roomClient, user);

    return (
        <div className={styles.main} >

            <div className={styles.left} style={started ? {} : { display: "none" }}>
                <canvas ref={ref} width={800} height={600} style={{width: "30vw"}} />
                <DrawerWarning drawer={drawer} user={user} theme={theme} />
                <DrawChat imDrawer={drawer?.id === user.id} messages={messages} user={user} activityHandler={activityHandler} />
            </div>
            <div className={styles.waiting} style={!started ? {} : { display: "none" }}>
                <span className={styles.waitingText}>Aguardando outros jogadores...</span>
            </div>



        </div >
    )
}
function useDraw(ref, client, user) {
    const [drawer, setDrawer] = useState(null);
    const [theme, setTheme] = useState(null);
    const [synced, setSynced] = useState(false);
    const [started, setStarted] = useState(false);

    useEffect(() => {
        const canvas = ref.current;
        if (!canvas || !client) return;

        const ctx = canvas.getContext("2d");
        let drawing = false;
        let currentData = { color: "white", lineWidth: 10, start: { x: 0, y: 0 }, path: [] }

        // Initial canvas background
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, 800, 600);

        function getRelativeMousePosition(event) {
            const rect = canvas.getBoundingClientRect();
            const scaleX = canvas.width / rect.width; // Razão de escala no eixo X
            const scaleY = canvas.height / rect.height; // Razão de escala no eixo Y
        
            return {
                x: (event.clientX - rect.left) * scaleX, // Ajustando X
                y: (event.clientY - rect.top) * scaleY,  // Ajustando Y
            };
        }
        

        function startDrawing(e) {
            if (!drawer) return;
            if (drawer.id !== user.id) return;
            const { x, y } = getRelativeMousePosition(e);
            drawing = true;
            ctx.fillStyle = "black";
            ctx.lineWidth = 10;
            ctx.beginPath();
            ctx.moveTo(x, y);
            currentData = { color: ctx.fillStyle, lineWidth: ctx.lineWidth, start: { x, y }, path: [] }
        }

        function drawLine(e) {
            if (!drawer) return;
            if (drawer.id !== user.id) return;
            if (!drawing) return;
            const { x, y } = getRelativeMousePosition(e);
            ctx.lineTo(x, y);
            ctx.stroke();
            currentData.path.push({ x, y });
        }

        function endDrawing() {
            drawing = false;
            ctx.closePath();
            if (!drawer) return;
            if (drawer.id !== user.id) return;
            client.emit("DRAW", currentData);
        }

        function onDrawSync(data) {
            for (const draw of data) {
                ctx.save();
                ctx.fillStyle = draw.color;
                ctx.lineWidth = draw.lineWidth;
                ctx.beginPath();
                ctx.moveTo(draw.start.x, draw.start.y);
                for (const point of draw.path) {
                    ctx.lineTo(point.x, point.y);
                }
                ctx.stroke();
                ctx.closePath();
                ctx.restore();
            }
        }

        function onDrawClear() {
            ctx.fillStyle = "white";
            ctx.fillRect(0, 0, 1024, 768);
        }

        function onDrawer({ id, username }) {
            if (user.id === id) {
                console.log("você desenha")
            } else {
                console.log(username, "desenha")
            }
            setDrawer({ id, username });
        }

        function onTheme(theme) {
            console.log("Tema:", theme)
            setTheme(theme);
        }

        function onStarted(started) {
            console.log(started)
            setStarted(started);
        }

        client.on("DRAW_SYNC", onDrawSync);
        client.on("DRAW_CLEAR", onDrawClear);
        client.on("DRAWER", onDrawer);
        client.on("THEME", onTheme);
        client.on("STARTED", onStarted);

        canvas.addEventListener("mousedown", startDrawing);
        canvas.addEventListener("mousemove", drawLine);
        canvas.addEventListener("mouseup", endDrawing);
        canvas.addEventListener("mouseleave", endDrawing); // stop drawing when mouse leaves the canvas

        console.log("event")

        if (!synced) {
            console.log("synced")
            client.emit("DRAW_SYNC");
            setSynced(true);
        }

        // Cleanup event listeners on component unmount
        return () => {
            canvas.removeEventListener("mousedown", startDrawing);
            canvas.removeEventListener("mousemove", drawLine);
            canvas.removeEventListener("mouseup", endDrawing);
            canvas.removeEventListener("mouseleave", endDrawing);
            client.off("DRAW_SYNC", onDrawSync);
            client.off("DRAWER", onDrawer);
            client.off("DRAW_CLEAR", onDrawClear);
            client.off("THEME", onTheme);
            client.off("STARTED", onStarted);
        };

    }, [ref, client, drawer, synced, started]);

    return {
        drawer,
        theme,
        started
    }
}

function DrawerWarning({ drawer, user, theme }) {
    // A variável de estado para controlar a visibilidade do alerta
    const [isVisible, setIsVisible] = useState(true);

    if (!drawer) return null;

    // Animações do Framer Motion
    const variants = {
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        },
        hidden: {
            opacity: 0,
            y: -50,
            transition: {
                duration: 0.5
            }
        }
    };

    return (
        <motion.div
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            variants={variants}
            style={alertStyles}
        >
            {
                drawer.id === user.id ? (<span>É a sua vez de desenhar, o tema é <span style={{ color: "var(--purple2)" }}>{theme}</span>!</span>) : (<span>É a vez de <span style={{ color: "var(--purple2)" }}>{drawer.username}</span></span>)
            }
        </motion.div>
    );
}

function DrawChat({ user, activityHandler, messages, imDrawer }) {
    const { roomClient } = activityHandler;

    const [chat, setChat] = useState("");

    function handleSend(e) {
        console.log(e.key)
        if (e.key === "Enter") {
            roomClient.emit("CHAT", chat);
            setChat("");
        }
    }

    return (
        <div className={styles.chat}>
            <div className={styles.messages}>
                {
                    messages.map((message, i) => {
                        return (
                            <ChatMessage key={i} message={message} />
                        )

                    })
                }
            </div>
            <div>
                <TextInput disabled={imDrawer} style={{ fontSize: "clamp(0px, 1vw, 16px) " }} onKeyDown={handleSend} value={chat} placeholder={imDrawer ? "Você não pode digitar enquanto desenha..." :"Digite seu palpite aqui..."} onChange={setChat} />
            </div>
        </div>
    )
}

function ChatMessage({ message }) {
    if (message.type === "message") {
        function usernameToColor(username) {
            let hash = 0;

            // Cria um hash simples a partir do username
            for (let i = 0; i < username.length; i++) {
                hash = username.charCodeAt(i) + ((hash << 5) - hash);
            }

            // Converte o hash em um valor hexadecimal de 6 dígitos
            let color = '';
            for (let i = 0; i < 3; i++) {
                const value = (hash >> (i * 8)) & 0xFF;
                color += ('00' + value.toString(16)).substr(-2);
            }

            return `#${color}`;
        }

        return (
            <div className={styles.message}>
                
                💬 <span className={styles.username} style={{ color: usernameToColor(message.username) }}>{message.username}</span>
                :
                &nbsp;
                <span className={styles.text}>{message.message}</span>
            </div>
        )
    } else if (message.type === "almost") {
        return (
            <div className={styles.message} style={{ color: "orange" }}>
                🕒 {message.message} está perto!
            </div>
        )
    } else if (message.type === "win") {
        return (
            <div className={styles.message} style={{ color: "green" }}>
                ✅ {message.username} acertou!
            </div>
        )
    }else if (message.type === "drawer") {
        return (
            <div className={styles.message} style={{ color: "var(--purple2)" }}>
                ✏️ É a vez de <span style={{color: "white"}}>{message.username}</span> desenhar!
            </div>
        )
    }
}

const alertStyles = {
    position: "fixed",
    top: "10px",
    left: "42%",
    transform: "translateX(-50%)",
    padding: "10px 20px",
    backgroundColor: "rgba(0,0,0,0.7)",
    color: "white",
    borderRadius: "5px",
    zIndex: 1000,
};