import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tagg from '@taggapp/taggjs-main';

export default function useToken() {
    const [token, setToken] = useState(undefined);

    useEffect(() => {
        if(token == undefined) {
            let newToken = window.localStorage.getItem("token");
            if(newToken) {
                setToken(newToken);
            }else {
                newToken = window.localStorage.getItem("token");
                if(newToken) {
                    setToken(newToken);
                }else {
                    setToken(null);
                }
            }
        }
    });

    return token;
}