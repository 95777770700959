import useSound from 'use-sound';
import notificationSfx from '../../../sounds/notification/notification.mp3';
import { useEffect } from 'react';

export function useNotificationSounds(client) {
    const [play] = useSound(notificationSfx);

    useEffect(() => {
        function onConversationMessage() {
            play();
        }

        if(client) {
            client.on("CONVERSATION_MESSAGE", onConversationMessage)
        }

        return () => {
            if(client) {
                client.off("CONVERSATION_MESSAGE", onConversationMessage)
            }
        }
    }, [client])
}