export function deserializeText(text) {
  const paragraphs = text.split("\n");

  const nodes = paragraphs.map((paragraph) => {
    const children = deserializeInlineElements(paragraph);
    return {
      type: "paragraph",
      children,
    };
  });

  return nodes;
}

function deserializeInlineElements(text) {
  const elements = [];

  const words = text.split(" ");
  let currentElement = { text: "" };

  function addCurrentText() {
    currentElement.text += " ";
    if (currentElement.text.length > 0) elements.push(currentElement);
    currentElement = { text: "" };
  }

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (isEmote(word)) {
      addCurrentText();

      elements.push({
        type: "emote",
        children: [{ text: word }],
      });
    } else if (isHashtag(word)) {
      addCurrentText();

      elements.push({ type: "hashtag", text: word });
    } else if (isMention(word)) {
      addCurrentText();

      elements.push({ type: "mention", text: word });
    } else {
      if (i > 0) currentElement.text += " ";
      currentElement.text += word;
    }
  }

  elements.push(currentElement);

  return elements;
}

function isEmote(word) {
  // Verifica se a palavra é um emote (emoji)
  // Implemente sua lógica de verificação aqui
  return word.startsWith(":") && word.endsWith(":");
}

function isHashtag(word) {
  // Verifica se a palavra é uma hashtag
  // Implemente sua lógica de verificação aqui
  return word.startsWith("#");
}

function isMention(word) {
  // Verifica se a palavra é uma menção
  // Implemente sua lógica de verificação aqui
  return word.startsWith("@");
}
