import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./home";
import useClient from "../../hooks/useClient";
import SplashScreen from "./splash/splash";
import NotFound from "./404/notfound";
import { useEffect, useState } from "react";
import SideMenu from '../../shared/components/SideMenu'
import Profile from './profile/profile';
import Room from "./rooms/room";
import { AvatarCropper, BannerCropper } from '../../shared/components/imagepicker/imagepicker'
import './App.css';
import Settings from "./settings/settings";
import NotificationsPopup from "./notifications/notificationsPopup";
import { UsersRecent } from "../../shared/components/recent/recent";
import { usePreloadEmotes } from "../../preload/preloadEmotes";
import TagHome from "./tags/tags";
import { CreateRoomPage } from "./rooms/createRoom";
import { useMessaging } from "./Messaging";
import { useNotificationSounds } from "./hooks/useNotificationSounds";

export default function AppRouter() {
    const [connected, setConnected] = useState(false);

    const navigate = useNavigate();

    const AppClient = useClient();
    useNotificationSounds(AppClient);
    usePreloadEmotes();
    const messagingToken = useMessaging(AppClient);

    useEffect(() => {
        function onHello() {
            console.log("hello")
            setConnected(true)
        }

        function onDisconnected() {
            setConnected(false);
        }

        function onConnectError() {
            console.error("onConnectError()");
            //localStorage.removeItem("token");
            //navigate("/");
            setConnected(false);
        }

        if (AppClient) {
            AppClient.on("hello", onHello);
            AppClient.on("disconnect", onDisconnected);
            AppClient.on("connect_error", onConnectError);
        }

        return () => {
            if (AppClient) {
                AppClient.off("hello", onHello);
                AppClient.off("disconnect", onDisconnected);
                AppClient.off("connect_error", onConnectError);
            }
        }
    }, [AppClient]);

    useEffect(() => {
        if (AppClient && messagingToken) {
            console.log("messaging token", messagingToken);
            AppClient.setWebNotificationToken(messagingToken);
        }
    }, [messagingToken])

    function onAvatarChange(data) {
        data.toBlob(function (blob) {
            AppClient.setAvatar(blob).then((response) => {
                switch (response.status) {
                    case 201:
                        break;
                    default: break;
                }
            });
        })
    }

    function onBannerChange(data) {
        data.toBlob(function (blob) {
            AppClient.setBanner(blob).then((response) => {
                switch (response.status) {
                    case 201:
                        return;
                    default: break;
                }
            });
        })
    }

    let view = (null);

    if (!AppClient) {
        view = (
            <SplashScreen />
        )
    }
    else if (!connected) {
        view = (
            <SplashScreen />
        )
    } else {
        view = (
            <>
                <AvatarCropper onChange={onAvatarChange} />
                <BannerCropper onChange={onBannerChange} />
                <CreateRoomPage clientUser={AppClient} />

                <SideMenu client={AppClient} />
                <NotificationsPopup client={AppClient} />

                <Routes>
                    <Route path="/" element={<Home client={AppClient} />} />
                    <Route path="/user/:username" element={<Profile client={AppClient} />} />
                    <Route path="/rooms/:roomId/*" element={<Room client={AppClient}/>}/>
                    <Route path="/tag/:tagId" element={<TagHome client={AppClient} />} />
                    <Route path="/settings/*" element={<Settings client={AppClient} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>

            </>
        )
    }

    return (


        view


    )
}

