import { useEffect, useState } from "react";
import isEqual from 'lodash/isEqual';

export default function useEditProfile(defaultValue, user) {
    const [currentChanges, setCurrentChanges] = useState(defaultValue);

    const Keys = Object.keys(defaultValue);

    useEffect(() => {
        setCurrentChanges(defaultValue);
    }, [user])

    let differentChanges = {};

    for(let i = 0; i < Keys.length; i++) {
        const key = Keys[i];
        if(currentChanges[key] == undefined) continue;
        if(!isEqual(currentChanges[key], defaultValue[key]) ) {
            differentChanges[key] = currentChanges[key];
        }
    }

    function addChange(key, value) {
        const newChanges = {
            ...currentChanges,
        }

        newChanges[key] = value;

        setCurrentChanges(newChanges);
    }

    function resetChanges() {
        setCurrentChanges(defaultValue);
    }

    return [differentChanges, currentChanges, addChange, resetChanges];
}