import { useNavigate } from 'react-router-dom';
import { IconLeftArrow, IconMute } from '../../../../shared/img/icons';
import roomMemberStyle from './room.member.module.css'
import styles from './styles.module.css';
import { RemoteImage } from '../../../../shared/components/storage/img';
import { useEffect, useState } from 'react';
import CatImages from '../../../../shared/cat';

export function RoomCall({ roomClient, roomId, callHandler, user }) {
    const {
        callUsers,
        connections,
        talking,
        muted,
        previewStream
    } = callHandler;

    const navigate = useNavigate();

    function handleGoBack() {
        navigate("/app/rooms/" + roomId + "/");
    }

    const usersArray = Object.values(callUsers);

    return (
        <div className={styles.viewport}>
            <div className={styles.members}>
                {
                    usersArray.length > 0 ? 
                    usersArray.map((member) => {
                        return (
                            <RoomCallMember
                                key={member.idUser}
                                roomClient={roomClient}
                                member={member}
                                connection={connections[member.idUser]}
                                localTalking={talking && user.id === member.idUser}
                                localAudioMuted={muted.audio && user.id === member.idUser}
                                localVideoMuted={(muted.video && user.id === member.idUser) || user.id !== member.idUser}
                                localVideoStream={user.id === member.idUser ? previewStream : null}
                            />
                        )
                    })
                    :
                    <div className={styles.warning}>
                        <img src={CatImages.SadCatBox} style={{width: "fit-content"}} height={250} />
                        <span style={{color: "gray"}}><span style={{fontWeight: 500}}>Aparentemente</span>, ninguém se juntou à chamada.</span>
                    </div>
                }
            </div>
        </div>
    )
}

function RoomCallMember({ roomClient, member, connection, localTalking, localAudioMuted, localVideoMuted, localVideoStream }) {
    const [talking, setTalking] = useState(false);
    const [audioMuted, setAudioMuted] = useState(false);
    const [videoMuted, setVideoMuted] = useState(true);
    const [videoStream, setVideoStream] = useState(null);

    useEffect(() => {
        function onTalking({ talking }) {
            setTalking(talking);
        }

        function onMuted({ muted }) {
            setAudioMuted(muted.audio);
            setVideoMuted(muted.video);
        }

        function onStreams(connection, streams) {
            setVideoStream(streams[0]);
        }

        if (connection) {
            setAudioMuted(connection.muted.audio);
            setVideoMuted(connection.muted.video);

            if(connection.streams) {
                setVideoStream(connection.streams[0]);
            }

            connection.events.on("streams", onStreams)
            connection.events.on("TALKING", onTalking);
            connection.events.on("MUTED", onMuted);
        }

        return () => {
            if (connection) {
                connection.events.off("streams", onStreams)
                connection.events.off("TALKING", onTalking);
                connection.events.off("MUTED", onMuted);
            }
        }
    }, [connection]);

    useEffect(() => {
        setTalking(localTalking)
    }, [localTalking]);

    useEffect(() => {
        setAudioMuted(localAudioMuted)
    }, [localAudioMuted]);

    useEffect(() => {
        setVideoMuted(localVideoMuted)
    }, [localVideoMuted]);

    useEffect(() => {
        setVideoStream(localVideoStream)
    }, [localVideoStream]);

    console.log(videoStream);

    return (
        <div className={talking && !audioMuted ? `${roomMemberStyle.container} ${roomMemberStyle.talking}` : `${roomMemberStyle.container}`}>
            <div className={roomMemberStyle.displayname}>
                {audioMuted ? <img src={IconMute} height={16} /> : null}
                {member.displayname}
            </div>

        

            {
                videoMuted ? (
                    <div className={roomMemberStyle.avatar}>
                        <RemoteImage width={96} height={96} id={member.idProfilePicture} />
                    </div>
                ) : (
                    <video className={roomMemberStyle.video} ref={(ref) => {
                        if(ref && videoStream) {
                            if(!ref.srcObject || ref.srcObject.id !== videoStream.id)
                            {
                                ref.srcObject = videoStream;
                                ref.muted = true;
                                ref.play();
                            }


                        }
                    }} />
                )
            }
        </div>
    )
}