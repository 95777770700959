import { useState } from "react";
import { useEffect } from "react";
import {LOCALE} from '../index';
import UIEvents from "../../shared/js/events/ui";

export default function TranslatableText({k, args = []}) { 
    const value = useTranslatableText(k, args);

    return (
        value 
    )
}

export function useTranslatableText(k, ...args) {
    const [value, setValue] = useState(null);
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem("language") || navigator.language || navigator.userLanguage);

    useEffect(() => {
        function containsLanguage(key) {
            const arr = Object.keys(LOCALE);
            return arr.includes(key);
        }

        const language = containsLanguage(currentLanguage) ? currentLanguage : 'en-US';

        localStorage.setItem("language", language);

        if(currentLanguage !== language) {
            setCurrentLanguage(language);
        }
    }, [currentLanguage]);

    useEffect(() => {
        let value = LOCALE[currentLanguage].keys[k];
        if(value) {
            for(let i = 0; i < args.length; i++) {
                value = value.replace(`{${i}}`, args[i]);
            }
            setValue(value);
        }
    }, [k, currentLanguage]);

    function handleLanguageChange(newLanguage) {
        setCurrentLanguage(newLanguage);
    } 

    useEffect(() => {
        UIEvents.on("language.change", handleLanguageChange);

        return () => {
            UIEvents.off("language.change", handleLanguageChange);
        }
    }, [k])

    if(!value) return "";

    return (
        value 
    )
}