// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging, getToken } from "firebase/messaging";
import { useState } from "react";
import { useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyBXXk0yI38FRrWWeJi1rVSbIHThm_xwnkg",
  authDomain: "tagg-380416.firebaseapp.com",
  projectId: "tagg-380416",
  storageBucket: "tagg-380416.appspot.com",
  messagingSenderId: "410002486156",
  appId: "1:410002486156:web:ffc54232e0a475cabedca9",
  measurementId: "G-H0LBK28KX7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const messaging = getMessaging(app);

export function useMessaging(client) {
    const [permission, setPermission] = useState(null);
    const [token, setToken] = useState(null);

    useEffect(() => {
        Notification.requestPermission().then((permission) => {
            setPermission(permission);
            if (permission === 'granted') {
              getToken(messaging, {vapidKey: "BKqOZD6IvTWGwtHCpcLZHHIuelJwuuH25uMs19cwj8dF-ER27aP7PG8NMijoDrhXweC-lMW2ZEtpanJec37Bb5E"}).then((token) => {
                setToken(token);
              })
            }
          })
    }, [])

    return token;
}