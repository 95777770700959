import { useEffect, useState, useRef } from 'react';
import '../../../shared/css/global.css';
import './post.css';
import { RemoteImage } from '../storage/img';
import { useLocalUser } from '../../../hooks/useLocalUser';
import TextArea from '../textarea/textarea';
import { deserializeText } from '../textarea/deserializer';
import { IconLeftArrow, IconPostComment, IconPostLike, IconPostSave, IconRightArrow } from '../../img/icons'
import { ImgLuizRicardo } from '../../img/luizRicardo'
import Button from '../../../shared/components/button'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import GlassButton from '../glassButton';
import FileIDToStorageURL from '../../js/storage';
import CatImages from '../../cat';
import styles from './post.module.css';
import { RemoteVideo } from '../storage/video';
import staline from '../../img/staline.svg'
import luiz from '../../img/luizRicardo/luiz.png'
import Avatar from '../Avatar'
import { isEmpty } from 'lodash';
import { serialize } from '../textarea/serializer';
import LoadingLogo from '../loading';

/*
Para ver ele, lembrar de importar ele na home e colocar ele como componente com as mesmas propriedades que o createPost
*/

export function PostComments({ idPost, clientUser, user }) {
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState(deserializeText(''));
    const [commentsSkip, setCommentsSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const LIMIT = 3;
    const localUser = useLocalUser(clientUser)

    function handlePostComment() {
        const serializedText = serialize(commentText);
        if (!serializedText) return;

        setCommentText(deserializeText(''));

        clientUser.postComment(idPost, serializedText).then((response) => {
            const { comment } = response.data;

            setComments([
                {
                    ...comment,
                    user: {
                        idUser: localUser.id,
                        username: localUser.username,
                        idProfilePicture: localUser.profile.avatar
                    }
                },
                ...comments,
            ])
        })


    }

    function onKeyDown(e) {
        if (e.code === "Enter" && !e.shiftKey) {
            handlePostComment();
        }
    }

    useEffect(() => {
        if (idPost && hasMore && !loading) {
            setLoading(true);
            clientUser.getPostComments(idPost, commentsSkip, LIMIT).then((response) => {
                const { comments: newComments } = response.data;
                setLoading(false);

                if (newComments.length < LIMIT) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }

                setComments([...comments, ...newComments])
            })
        }
    }, [commentsSkip, hasMore])

    return (
        <>
            {
                comments.length == 0 ? null : (
                    <div className={styles.commentDiv}>
                        {
                            comments.map((value) => <Comment key={value.idComment} client={clientUser} data={value} />)
                        }
                        {
                            hasMore == true && !loading ? (
                                <div className={styles.seeMoreContainer}>
                                    <div className={styles.seeMoreDiv} onClick={() => {
                                        setCommentsSkip(commentsSkip + LIMIT);

                                    }}>
                                        <span className={styles.seeMoreSpan}>Ver mais</span>
                                    </div>
                                </div>
                            ) : null
                        }

                        <div style={{ margin: "10px 0px", display: "flex", justifyContent: "center", width: "100%" }}>
                            {
                                loading ? (
                                    <LoadingLogo height={48} />
                                ) : null
                            }
                        </div>

                    </div>
                )
            }

            <div className={styles.commentInput}>
                <TextArea onKeyDown={onKeyDown} value={commentText} onChange={setCommentText} placeholder={"Comente no post de @" + user.username} />
            </div>
        </>
    )
}

export default function Post({ clientUser, post, onContextMenu, onPostDeleted }) {
    const user = post.user;
    const [textContent, setTextContent] = useState(deserializeText(post.postContent));
    const [filesContent, setFilesContent] = useState(post.attachments)
    const [tagContent, setTagContent] = useState(post.tag.tagName)
    const [timeContent, setTimeContent] = useState(moment(post.createdAt).fromNow())
    const [selectEmote, setSelectEmote] = useState(false);
    const localUser = useLocalUser(clientUser);
    const [likeCount, setLikeCount] = useState(post.likeCount || 0);
    const [commentsCount, setCommentsCount] = useState(post.commentsCount || 0);
    const [iLiked, setILiked] = useState(post.iLiked > 0 || false);
    const [liking, setLiking] = useState(false);
    const [firstLikes, setFirstLikes] = useState(post.likes ? post.likes.slice(0, 3) : []);
    const [postDeleted, setPostDeleted] = useState(post.deleted || false);
    const [attachments, setAttachments] = useState(post.attachments || []);
    const [doubleClickLike, setDoubleClickLike] = useState(false);
    const [saved, setSaved] = useState(Boolean(post.iSaved));

    useEffect(() => {
        setPostDeleted(Boolean(post.deleted));
    })

    useEffect(() => {
        if (postDeleted) {
            setTimeout(() => {
                onPostDeleted(post);
            }, 2000);
        }
    }, [postDeleted]);

    let likeText = "";

    if (firstLikes.length > 0) {
        likeText += "Curtido por " + firstLikes[0].user.displayname;
    }

    if (firstLikes.length > 2) {
        likeText += " e outras " + String(firstLikes.length - 1) + " pessoas";
    }
    else if (firstLikes.length === 2) {
        likeText += " e outra " + String(firstLikes.length - 1) + " pessoa";
    }

    const navigate = useNavigate();

    function handleLike() {
        if (liking) return;

        setILiked(!iLiked);

        if (!iLiked) {
            setLikeCount((current) => current + 1);
            setFirstLikes((current) => [...current, { user: { idUser: localUser.id, displayname: localUser.displayname, idProfilePicture: localUser.profile.avatar } }])
        } else {
            setLikeCount((current) => current - 1);
            setFirstLikes((current) => current.filter((value) => value.user.idUser !== localUser.id))
        }

        setLiking(true);

        clientUser.likePost(post.idPost).then((response) => {
            setLiking(false);
            setILiked(response.data.liked);
        })
    }

    function handleTagNavigate() {
        navigate("/app/tag/" + post.tag.idTag);
    }

    function handleProfile() {
        navigate("/app/user/" + user.username);
    }

    useEffect(() => {
        if (doubleClickLike)
            setTimeout(() => {
                setDoubleClickLike(false);
            }, 1000);
    }, [doubleClickLike])

    function handleDoubleClick() {
        if (doubleClickLike) return;
        setDoubleClickLike(true);
        setLiking(true);
        if (!iLiked) {
            setLikeCount((current) => current + 1);
            setFirstLikes((current) => [...current, { user: { idUser: localUser.id, displayname: localUser.displayname, idProfilePicture: localUser.profile.avatar } }])

            clientUser.likePost(post.idPost).then((response) => {
                setLiking(false);
                setILiked(response.data.liked);
            })
        } else setLiking(false);
    }

    function handleSave() {
        setSaved(!saved);
        clientUser.savePost(post.idPost).then((response) => {
            setSaved(response.data.saved);
        })
    }

    return (
        <>
            <motion.div initial={{ opacity: 1 }} animate={postDeleted ? { opacity: 0 } : {}} transition={{ duration: 2 }} className='postBox' onContextMenu={(e) => {
                if (onContextMenu) onContextMenu(e, post)
            }}>
                <div onDoubleClick={handleDoubleClick} data-post-deleted={String(postDeleted)} className='postViewport'>
                    <div className='postBoxTop'>
                        <div className='postBoxTopLeft'>
                            <div className='userPostBox' onClick={handleProfile}>
                                <div className='userLeft'>
                                    <div className='userImg'>
                                        <RemoteImage id={user.idProfilePicture} height={100} width={100} />
                                    </div>
                                </div>
                                <div className='userRight'>
                                    <span className='userDisplayName'>{user.displayname}</span>
                                    <span className='userName'>@{user.username}</span>
                                </div>
                            </div>
                        </div>
                        <div className='postBoxTopRight'>
                            <div className='tagButton'>
                                <Button capsOff={true} fontWeight={400} onClick={handleTagNavigate} fontSize={"14px"} padding={"clamp(5px, 1vw, 5px) clamp(5px, 1.5vw, 20px)"}>{tagContent}</Button>
                            </div>
                            <div className='timePosted'>
                                <span className='timePostedText' title={post.createdAt}>{timeContent}</span>
                            </div>
                        </div>
                    </div>

                    <div className='postBoxMain'>
                        <div className='postText'>
                            <TextArea value={textContent} readOnly='true' />
                        </div>
                        <PostAttachments attachments={attachments} />

                    </div>

                    <div className='postBoxIcons'>
                        <div className='postBoxIconsLeft'>
                            <div className={iLiked ? 'postLike iLiked' : 'postLike'} onClick={handleLike}>
                                <img src={IconPostLike} className="postIcon" />
                                <span className='postNumbers'>{likeCount}</span>

                            </div>
                            <div className='postComment'>
                                <img src={IconPostComment} className="postIcon" />
                                <span className='postNumbers'>{commentsCount}</span>
                            </div>
                        </div>

                        <div className='postBoxIconsRight'>
                            <div className={saved ? 'postSave iLiked' : 'postSave'} onClick={handleSave}>
                                <img src={IconPostSave} className="postIcon" />
                            </div>
                        </div>
                    </div>
                    <PostComments user={user} clientUser={clientUser} idPost={post.idPost} />
                    {
                        likeCount ? (
                            <div className='postBoxBottom'>
                                {
                                    firstLikes.map((like) => <PostLike like={like} key={like.idUser} />)
                                }
                                <span className='postLikedText'>{likeText}</span>
                            </div>
                        ) : null
                    }
                </div>

                <AnimatePresence mode={"wait"}>
                    {
                        doubleClickLike ? (
                            <motion.div initial={{ opacity: 0, scale: 0.7 }} animate={{ opacity: 1, scale: 1.2 }} exit={{ opacity: 0, scale: 0 }} transition={{ duration: 0.25, ease: 'linear' }} className='postHeartContainer'>
                                <img src={IconPostLike} height={64} />
                            </motion.div>
                        ) : null
                    }
                </AnimatePresence>

                {
                    postDeleted ? (
                        <div className='postDeleted'>
                            <span>Postagem deletada com sucesso</span>
                        </div>
                    ) : null
                }
            </motion.div>
        </>
    )
}

function Comment({ data, client }) {
    const { user } = data;
    const [likeCount, setLikeCount] = useState(data.likeCount || 0);
    const [iLiked, setLiked] = useState(data.iLiked > 0 || false);
    const navigate = useNavigate();
    const [content] = useState(deserializeText(data.commentContent))

    const handleLike = () => setLikeCount((value) => {
        setLiked(!iLiked);
        client.likePostComment(data.idPost, data.idComment).then((response) => {
            console.log(response)
        })
        return iLiked == false ? value + 1 : value - 1
    })

    function handleProfile() {
        navigate("/app/user/" + user.username)
    }

    return (
        <div className={styles.comment}>
            <div className={styles.commentContent}>
                <div onClick={handleProfile} className={styles.commentpic}>
                    <RemoteImage id={user.idProfilePicture} height={32} width={32} />
                </div>

                <div className={styles.commenttext}>
                    <span>
                    <span onClick={handleProfile} className={styles.commentusername}>{user.username}</span> <TextArea value={content} readOnly={true} />
                    </span>
                    <div className={styles.commentBottom}>
                        <span className={styles.commentTime}>{moment(data.createdAt).fromNow()}</span>
                        {
                            likeCount > 0 ? (<span className={styles.commentLikes}>{likeCount} {likeCount > 1 ? ("curtidas") : ("curtida")}</span>) : null
                        }
                    </div>
                </div>
            </div>
            <div className={styles.commentIcons}>
                <div className={iLiked ? styles.commentLike + ' iLiked' : styles.commentLike} onClick={handleLike}
                >
                    <img src={IconPostLike} className={styles.commentIcon} />
                </div>
            </div>
        </div>
    )
}

export function PostsLoading() {
    return (
        <div className={styles.postsLoading}>
            <img src={CatImages.CatComputer} style={{ height: 96 }} />
            <span className={styles.text}>Calma lá! Estamos buscando postagens para você...</span>
        </div>
    )
}

export function PostsEnd() {
    return (
        <div className={styles.postsEnd}>
            <img src={CatImages.CatSmile} style={{ height: 96 }} />
            <span className={styles.text}>Não há mais nada por aqui!</span>
        </div>
    )
}

function PostLike({ like }) {
    const navigate = useNavigate();

    function onProfile() {
        navigate("/app/user/" + like.user.username);
    }

    return (
        <div onClick={onProfile} className='userLiked'>
            <RemoteImage width={35} height={35} id={like.user.idProfilePicture} />
        </div>
    )
}

function PostAttachments({ attachments }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const attachmentsContainer = useRef();
    const viewportContainer = useRef();

    function resetScroll() {
        if (viewportContainer.current) {
            const perImage = attachmentsContainer.current.clientWidth + 1;

            viewportContainer.current.scroll({ left: perImage * currentIndex, behavior: 'smooth' })
        }
    }

    useEffect(() => {
        setCurrentAttachment(attachments[currentIndex]);
    }, [currentIndex]);

    useEffect(() => {
        window.addEventListener("resize", resetScroll);

        resetScroll();

        return () => {
            window.removeEventListener("resize", resetScroll);
        }
    }, [currentIndex, attachmentsContainer])

    if (!currentAttachment) return null;

    function Add() {
        setCurrentIndex((value) => value + 1);
    }

    function Remove() {
        setCurrentIndex((value) => value - 1);
    }

    return (
        <div ref={attachmentsContainer} className='postAttachments'>
            <div ref={viewportContainer} className='postAttachmentsViewport'>
                {
                    attachments.map((attachment) => {
                        return <PostAttachment key={attachment.filePath} attachment={attachment} />
                    })
                }
            </div>

            {
                currentIndex > 0 ? <GlassButton onClick={Remove} style={{ position: "absolute", left: 5, top: 0, bottom: 0, margin: "auto 0" }} children={<img className='iconGlassBtn' src={IconLeftArrow}></img>}></GlassButton> : null
            }

            {
                currentIndex + 1 < attachments.length ? <GlassButton style={{ position: "absolute", right: 5, top: 0, bottom: 0, margin: "auto 0" }} onClick={Add} children={<img className='iconGlassBtn' src={IconRightArrow}></img>}></GlassButton> : null
            }

        </div>
    )
}

function PostAttachment({ attachment }) {
    if (!attachment) return null;

    const { file } = attachment;

    const mediaRegex = /^((image\/(jpeg|png|gif)))/;
    const videoRegex = /^((video\/(mp4|x-ms-wmv|x-msvideo|quicktime)))/;

    if (mediaRegex.test(file.fileMime)) {
        return (
            <motion.div initial={{ translateX: "-100%" }} animate={{ translateX: "0%" }} exit={{ translateX: "200%" }} className='postAttachment'>
                <ImageAttachment attachment={attachment} />
            </motion.div>
        )
    } else if (videoRegex.test(file.fileMime)) {
        return (

                <VideoAttachment attachment={attachment} />
        )
    }
}

function ImageAttachment({ attachment }) {
    const { file } = attachment;

    return (
        <>
            <RemoteImage imageStyle={{ objectFit: "contain" }} id={file.filePath} />
            <div className='imageAttachmentBg' style={{ backgroundImage: `url(${FileIDToStorageURL(file.filePath)})` }}></div>
        </>
    )
}

function VideoAttachment({ attachment }) {
    const [isInView, setIsInView] = useState(false);
    const { file } = attachment;

    const componentRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Update our state when the observer callback is fired
          setIsInView(entry.isIntersecting);
        },
        {
          // Here you can set the threshold and other options
          // threshold: 0.5 means when 50% of the target element is visible
          threshold: 0.8
        }
      );
  
      // Start observing the target element
      if (componentRef.current) {
        observer.observe(componentRef.current);
      }
  
      // Clean up by stopping to observe the target element
      return () => {
        if (componentRef.current) {
          observer.unobserve(componentRef.current);
        }
      };
    }, [componentRef]);

    useEffect(() => {
        if(!isInView) {
            playerRef.current.pauseVideo()
        }else {
            playerRef.current.playVideo()
        }
    }, [isInView])

    return (
        <motion.div ref={componentRef} initial={{ translateX: "-100%" }} animate={{ translateX: "0%" }} exit={{ translateX: "200%" }} className='postAttachment'>
            <RemoteVideo ref={playerRef} id={file.filePath} />
        </motion.div>
    )
}