import TranslatableText from '../../localization/components/translatableText'
import './components.css'


export default function UserStats ({ followers, following, posts }) {
    return (
        <div className='user-stats'>
            <div className='stat'>
                <span className='value'>{followers || 0}</span>
                <span className='statLabel'><TranslatableText k={"followers"} /></span>
            </div>
            <div className='stat'>
                <span className='value'>{following || 0}</span>
                <span className='statLabel'><TranslatableText k={"following"} /></span>
            </div>
            <div className='stat'>
                <span className='value'>{posts || 0}</span>
                <span className='statLabel'><TranslatableText k={"posts"} /></span>
            </div>
        </div>
    )
}