import { useEffect, useState } from "react";
import { GetNotificationEntityByAlias } from ".";
import './notifications.css';
import Avatar from "../Avatar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "../button";

export default function Notification_USER_FOLLOW({data}) {
    const [follower, setFollower] = useState(null);
    const [now] = useState(moment());
    const navigate = useNavigate();

    useEffect(() => {
        if(!follower) {
            setFollower(GetNotificationEntityByAlias(data, "FOLLOWER"));
        }
    }, [data]);

    if(follower == null) return (null);

    function handleClick() {
        navigate("/app/user/" + follower.user.username);
    }

    const date = moment(data.createdAt);
    const lastTimeSeen = moment(follower.user.lastTimeSeen);

    return (
        <div className="rightMenuItemContainer" onClick={handleClick}>
            <Avatar status={now.diff(lastTimeSeen, "minutes") < 1 ? "online" : "offline"} type={"small"} height={48} id={follower.user.idProfilePicture} />

            <div className="rightMenuItemInfo">
                <span className="rightMenuItemUsername">
                    {follower.user.username}
                    &nbsp;
                    <span className="notificationContent">começou a seguir você.</span>
                    &nbsp;
                    <span className="notificationTime">• {date.fromNow()}</span>
                </span>
            </div>
        </div> 
    )
}