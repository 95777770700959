import style from './rightMenu.module.css'
import { IconExit, IconSearch } from '../img/icons'
import Separator from './separator'
import UIEvents from '../js/events/ui';
import './components.css'
import Avatar from './Avatar'
import profile from '../../shared/img/profile.jpeg'
import { useEffect, useState } from 'react'
import Header from './RightMenuHeader'
import Searchbar from './Searchbar'
import TestImage from './tags/enterteinment example.png'
import { RemoteImage } from './storage/img';
import { useNavigate } from 'react-router-dom';
import Tagg from '@taggapp/taggjs-main';

export default function TagsMenu() {
    const navigate = useNavigate();

    const [tags, setTags] = useState(null);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (!tags) {
            Tagg.API.GetTags().then(({ tags }) => {
                setTags(tags);
            })
        }
    }, [tags])

    function onTagAccess({ idTag }) {
        navigate("/app/tag/" + idTag);
    }

    const filteredTags = tags ? tags.filter((value) => value.tagName.toLowerCase().startsWith(search.toLowerCase())) : null;

    return (
        <div className={style.container}>
            <Header title='Tags' />
            <Separator />
            <Searchbar onChange={setSearch} placeholder='Buscar tags...' />

            <div className={style.tagsListContainer}>
                {
                    filteredTags ?
                        filteredTags.map((value) => (
                            <Tag key={value.idTag} onClick={() => onTagAccess(value)} name={value.tagName} image={value.filePath} />
                        )) : null
                }
            </div>
        </div>
    )
}

function Tag({ name, image, onClick }) {
    return (
        <div className={style.tagContainer} onClick={onClick}>
            <div className={style.tagBackground}>
                <RemoteImage id={image} />
            </div>
            <div className={style.tagInfo}>
                <span>{name}</span>
            </div>
        </div>
    )
}