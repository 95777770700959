import { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import { RemoteImage } from '../../../shared/components/storage/img';
import TextInput from '../../../shared/components/textinput';
import { IconAddWhite, IconRemove } from '../../../shared/img/icons';
import styles from './styles.module.css';
import UIEvents from '../../../shared/js/events/ui';
import useFriendList from '../../../hooks/useFriendList';
import moment from 'moment';
import loadingIcon from "../../../shared/img/loading.svg";
import { useNavigate } from 'react-router-dom';

const INVITE_MAX = 10;

export function CreateRoomPage({ clientUser }) {
    const [open, setOpen] = useState(false);
    const [invites, setInvites] = useState([]);
    const [canCreate, setCanCreate] = useState(false);
    const [roomName, setRoomName] = useState("");
    const [friends] = useFriendList(clientUser);
    const [loading, setLoading] = useState(false);

    const now = moment();

    function friendListSort(a, b) {
        a.userOnline =
            now.diff(moment(a.lastTimeSeen), "minutes") < 1 ? true : false;
        b.userOnline =
            now.diff(moment(b.lastTimeSeen), "minutes") < 1 ? true : false;

        // First, sort by userOnline (true comes before false)
        if (a.userOnline && !b.userOnline) {
            return -1; // `a` comes before `b`
        }
        if (!a.userOnline && b.userOnline) {
            return 1; // `a` comes after `b`
        }

        // If userOnline is the same or both users are offline, sort by displayName
        var nameA = a.displayname.toUpperCase();
        var nameB = b.displayname.toUpperCase();

        if (nameA < nameB) {
            return -1; // `a` comes before `b`
        }
        if (nameA > nameB) {
            return 1; // `a` comes after `b`
        }
        return 0; // Names are equal
    }

    useEffect(() => {
        setInvites([]);
        setCanCreate(false);
        setRoomName("");
        setLoading(false);
    }, [open])

    useEffect(() => {
        function onRoomCreate() {
            setOpen(true);
        }

        UIEvents.on("room.create", onRoomCreate);

        return () => {
            UIEvents.off("room.create", onRoomCreate);
        }
    });

    function handleRoomName(text) {
        setRoomName(text);
    }

    function handleAddInvite(idUser) {
        const index = invites.indexOf(idUser);
        if(index === -1) setInvites((current) => [...current, idUser]);
        else setInvites((current) => {
            const newArray = [...current];
            newArray.splice(index, 1)
            return newArray;
        });
    }

    const navigate = useNavigate();

    function handleCreateRoom() {
        if(!canCreate) return;

        setLoading(true);

        clientUser.createRoom(roomName, invites).then((response) => {
            setLoading(false);
            setOpen(false);
            navigate(`/app/rooms/${response.data.idRoom}`);
        }).catch((err) => {
            console.error(err)
            setLoading(false);
        })
    }

    useEffect(() => {
        if (roomName) {
            setCanCreate(roomName.length > 0);
        } else setCanCreate(false);
    }, [roomName])

    if (!open) return (null);

    return (
        <div data-hiddable={"true"} className={styles.container} onClick={(e) => {
            if (e.target.dataset['hiddable'] === "true")
                setOpen(false)
        }}>

            <div className={styles.paper}>
                <div className={styles.header}>
                    <h3>Criar uma sala de interação</h3>
                </div>
                <div className={styles.content}>
                    <div className={styles.contentItem}>
                        <h5 className={styles.contentTitle}>INFORMAÇÕES</h5>
                        <TextInput value={roomName} onChange={handleRoomName} placeholder={"Nome da sala"} />
                    </div>
                    <div className={styles.contentItem}>
                        <h5 className={styles.contentTitle}>CONVIDAR ({invites.length} de {INVITE_MAX} membros)</h5>
                        <div className={styles.invitesViewport}>
                            <div className={styles.invites}>
                                {
                                    friends ? (
                                        friends.sort(friendListSort).map((friend) => (
                                            <FriendToInvite disabled={loading} limit={invites.length >= INVITE_MAX} onAddInvite={handleAddInvite} key={friend.idUser} data={friend} />
                                        ))
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.footer}>
                    {
                        !loading ? <Button onClick={handleCreateRoom} disabled={!canCreate} style={{ flex: 1 }}>Criar sala</Button> : (
                            <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img src={loadingIcon} height={32} />
                            </div>
                        )
                    }
                </div>
            </div>

        </div>
    )
}

function FriendToInvite({ data, onAddInvite, limit, disabled }) {
    const [selected, setSelected] = useState(false);

    const now = moment();
    const isOnline = now.diff(moment(data.lastTimeSeen), "minutes") < 1 ? true : false;

    return (
        <div className={styles.invite}>
            <div className={styles.avatar}>
                <div className={styles.viewport}>
                    <RemoteImage id={data.idProfilePicture} />
                </div>
                {
                    isOnline ? <div className={styles.status} /> : null
                }
            </div>
            <span className={styles.displayName}>{data.displayname}</span>
            <Button disabled={(limit && !selected) || disabled} onClick={() => {
                if(disabled) return;
                setSelected(!selected);
                onAddInvite(data.idUser);
            }} padding={"5px"} style={{ marginLeft: "auto", aspectRatio: 1, width: 32, height: 32 }}>
                <img src={!selected ? IconAddWhite : IconRemove} width={16} />
            </Button>
        </div>
    )
}