import '../../../shared/css/global.css';
import styles from './tags.module.css';
import TopHighlights from '../../../shared/components/TopHighlights';
import TranslatableText from '../../../localization/components/translatableText';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import Searchbar from '../../../shared/components/Searchbar';
import Button from '../../../shared/components/button';
import { IconPosts } from '../../../shared/img/icons';
import { FilterHeaderMenu } from '../../../shared/components/postFilter/postFilter';
import UsersRecommendation from '../../../shared/components/usersRecommendation/userRecommendation';
import { TrendingTopics } from '../../../shared/components/trendingTopics/tredingTopics';
import Post, { PostsEnd, PostsLoading } from '../../../shared/components/post/post';
import { RightPanels } from '../../../shared/components/rightPanels/rightPanels';

export default function TagHome({ client }) {
    const [tagData, setTagData] = useState(null);
    const { tagId } = useParams();

    useEffect(() => {
        if (!tagData) {
            client.getTag(tagId).then((response) => {
                console.log(response);
                setTagData(response.data)
            })
        }
    }, [tagData])

    useEffect(() => {
        setTagData(null);
    }, [tagId])

    if (!tagData) return (null);

    return (
        <>
            <div className="topBtn"></div>
            <div className={'content'}>
                <div className={'content-main'}>
                    <div className={'flex flexrow ' + styles['tag-header']}>
                        <h1 className={styles['highlights-title']}> {tagData.tagName} </h1>
                    </div>
                    <Header clientUser={client}>

                    </Header>
                    <main>
                        <div className={styles['mainLeft']}>
                            <MainLeftPosts tagId={tagId} clientUser={client} />
                        </div>
                        <div className={styles['mainRight']}>
                            <RightPanels client={client} />
                        </div>
                    </main>
                </div>
            </div>

        </>
    )
}


function MainLeftPosts({ clientUser, tagId }) {
    const [posts, setPosts] = useState([]);
    const [skip, setSkip] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const MAX = 10;

    function doLoad() {
        if (!hasMore) return;
        clientUser.getPostsFromTag(tagId, skip).then((response) => {
            setLoading(false);
            setHasMore(response.data.length >= MAX);
            setPosts((current) => [...current, ...response.data]);
        })
    }

    useEffect(() => {
        setLoading(true);
        doLoad();
    }, [skip]);

    useEffect(() => {
        document.addEventListener("scroll", onScroll);
        function onScroll(e) {
            if (loading || !hasMore) return;
            if ((e.target.scrollingElement.scrollTop / (e.target.scrollingElement.scrollHeight - e.target.scrollingElement.clientHeight)) > 0.75) {
                setLoading(true);
                setSkip((current) => current + MAX);
            }
        }
        return () => {
            document.removeEventListener("scroll", onScroll);
        }
    })

    return (
        <div className='posts'>
            {
                posts ? posts.map((post) => {
                    return (<Post clientUser={clientUser} post={post} key={post.idPost} />)
                }) : null
            }

            {
                loading ? (
                    <PostsLoading />
                ) : null
            }

            {
                !hasMore ? (
                    <PostsEnd />
                ) : null
            }
        </div>
    )
}

function Header({ clientUser, children }) {
    const [menuFiltersVisible, setMenuFiltersVisible] = useState(false);
    const [filters, setFilters] = useState([-1, -1, -1]);

    function handleFilters() {
        setMenuFiltersVisible(!menuFiltersVisible);
    }

    function setFilterValue(filterId = 0, value = 0) {
        const newFilters = [...filters];
        newFilters[filterId] = value;
        setFilters(newFilters);
    }

    return (
        <div className={styles['filters']}>
            <Button onClick={handleFilters} padding={"5px 20px"}>
                <img src={IconPosts} height={16} />
                &nbsp;
                <TranslatableText k={"filter"} />
            </Button>

            {
                children
            }

            <FilterHeaderMenu onClose={() => setMenuFiltersVisible(false)} setFilterValue={setFilterValue} filters={filters} open={menuFiltersVisible} />
        </div>
    )
}