import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";


export default function useFriendList(client) {
    const [friendList, setFriendList] = useState(null);
    const friendListRef = useRef(friendList);

    useEffect(() => {
        friendListRef.current = friendList;
    }, [friendList]);

    useEffect(() => {
        client.getFriendList().then((response) => {
            const friendsWithSubscription = response.data.list.map(friend => {
                const friendWithSubscription = {
                    ...friend,
                    updateFunc: (data) => {
                        if(data.profile) {
                            data['idProfilePicture'] = data.profile.avatar;
                            data['idBannerPicture'] = data.profile.banner;
                        }

                        setFriendList((currentFriends) => {
                            const updatedFriends = currentFriends.filter(f => f.idUser !== data.id);
                            updatedFriends.push({ ...friend, ...data });
                            return updatedFriends;
                        });
                    }
                };

                client.emit("SUBSCRIBE", friend.idUser, 'LOCAL', () => {
                    client.on("USER_" + friend.idUser, friendWithSubscription.updateFunc);
                });

                return friendWithSubscription;
            });

            setFriendList(friendsWithSubscription);
        }).catch((err) => {
            setFriendList([]);
        })
    }, [client]);

    useEffect(() => {
        return () => {
            const friendList = friendListRef.current;
            if (friendList) {
                for (let i = 0; i < friendList.length; i++) {
                    const friend = friendList[i];
                    client.emit("UNSUBSCRIBE", friend.idUser, 'LOCAL', () => {
                        client.off("USER_" + friend.idUser, friend.updateFunc);
                    });
                }
            }
        }
    }, []);

    let onlineCount = useMemo(() => {
        let count = 0;
        const now = moment();
        if (friendList) {
            friendList.map((a) => {
                const isOnline = now.diff(moment(a.lastTimeSeen), "minutes") < 1 ? true : false;
                if (isOnline) count++;
            })
        }
        return count;
    }, [friendList])

    return [friendList, onlineCount];
}