import { useEffect, useRef, useState } from 'react';
import style from './youtube.module.css'
import galynha from "../../../../../shared/img/galynhapentadynha.jpg"
import { useNavigate } from 'react-router-dom';
import { IconTrashWhite, IconSearch } from '../../../../../shared/img/icons';
import { SpinnerLoader } from '../../../../../shared/components/SpinnerLoader';
import ReactPlayer from 'react-player';

export default function YoutubeActivity({ activityHandler, user }) {
    const { roomClient, leader } = activityHandler

    const [videos, setVideos] = useState([
    ])

    const [playlist, setPlaylist] = useState({})

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        handleSearch()
    }, []);

    useEffect(() => {
        if(roomClient) {
            roomClient.emit("PLAYLIST", (playlist) => {
                setPlaylist(playlist)
            })
        }
    }, [roomClient])

    useEffect(() => {
        if (roomClient) {
            function onPlaylist(playlist) {
                setPlaylist(playlist)
            }

            roomClient.on("PLAYLIST", onPlaylist)

            return () => {
                roomClient.off("PLAYLIST", onPlaylist)
            }
        }
    }, [roomClient, playlist])

    function handleVideoChoosen(id, item) {
        roomClient.emit("PLAYLIST_ADD", id);
    }

    const [videoName, setVideoName] = useState('')

    function handleSearch() {
        setLoading(true)
        setVideos([])
        roomClient.emit("YOUTUBE_SEARCH", videoName, (result) => {
            setVideos(result.videos)
            setLoading(false)
        })
    }

    function handleVideoName(name) {
        setVideoName(name);
    }

    return (
        <div style={{ width: "100%", height: "100%" }} className={style.main}>
            <div className={style.mainLeft}>
                <Fila roomClient={roomClient} playlist={playlist} />
                <div className={style.leftBottom}>
                    <Player imLeader={leader === user.id} roomClient={roomClient} playlist={playlist} />
                </div>
            </div>
            <div className={style.mainRight}>
                <div className={style.rightTop}>
                    <input onKeyDown={(e) => {
                        switch (e.key) {
                            case "Enter":
                                return handleSearch();
                        }
                    }} type="text" value={videoName} onChange={(e) => handleVideoName(e.target.value)} placeholder='Pesquisar' className={style.searchBar} />
                    <div className={style.imgSearch}>
                        <img src={IconSearch} onClick={handleSearch} />
                    </div>
                </div>
                <div className={style.rightBottom}>
                    {
                        videos.map((item) => {
                            return (
                                <Video key={item.videoId} src={item.thumbnailUrl} title={item.title} channel={item.channelTitle} id={item.videoId} duration={item.duration} live={item.liveBroadcastContent === 'live'} item={item} onClick={handleVideoChoosen} />
                            )
                        })
                    }
                    {
                        loading ? (
                            <div className={style.loadingBack}>
                                <SpinnerLoader />
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

function Player({ roomClient, playlist, imLeader }) {
    const [playing, setPlaying] = useState(true);
    const [lastSecondSync, setLastSecondSync] = useState(0);
    const [localPause, setLocalPause] = useState(false);

    const ref = useRef();

    const currentVideo = Object.values(playlist)[0];

    useEffect(() => {
        if (roomClient) {
            const onSync = (data) => {
                if (!ref.current) return;

                if(!localPause) {
                    if (Math.abs(ref.current.getCurrentTime() - data.playedSeconds) > 1) {
                        ref.current.seekTo(data.playedSeconds, "seconds");
                        ref.current.player.player.player.playVideo();
                    }
    
                    if(ref.current.player.isPlaying && !data.isPlaying) {
                        ref.current.player.player.player.pauseVideo();
                    }else if(!ref.current.player.isPlaying && data.isPlaying) {
                        ref.current.player.player.player.playVideo();
                    }
                }

                setLastSecondSync(data.playedSeconds);
                setPlaying(data.isPlaying)
            }

            roomClient.on("ACTIVITY_SYNC", onSync);


            return () => {
                roomClient.off("ACTIVITY_SYNC", onSync);
            }
        }
    }, [roomClient, playing, ref.current])

    if (!currentVideo) return null;

    function handleProgress(e) {
        if (!imLeader) return;

        const { player } = ref.current;
        const { isPlaying } = player;


        roomClient.emit("ACTIVITY_SYNC", { playedSeconds: e.playedSeconds, isPlaying });

    }

    function onEnded() {
        roomClient.emit("PLAYLIST_REMOVE", currentVideo.id);
    }

    function handlePause() {
        if (!imLeader) {
            setLocalPause(true);
            return;
        }

        roomClient.emit("ACTIVITY_SYNC", { playedSeconds: ref.current.getCurrentTime(), isPlaying: false });
    }

    function handlePlay() {
        if (!imLeader) {
            setLocalPause(false);
            if(localPause)
                ref.current.seekTo(lastSecondSync, "seconds", true);
            
            return;
        }

        roomClient.emit("ACTIVITY_SYNC", { playedSeconds: ref.current.getCurrentTime(), isPlaying: true });
    }

    return (
        <ReactPlayer onEnded={onEnded} playing={playing && !localPause} onPause={handlePause} onPlay={handlePlay} ref={ref} onProgress={handleProgress} controls={true} url={"https://www.youtube.com/watch?v=" + currentVideo.id} width={"100%"} height={"100%"} />
    )
}

function Video({ item, src, title, channel, id, duration, live, onClick }) {

    function convertISO8601DurationToTime(duration) {
        const regex = /P(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = duration.match(regex);

        const days = matches[1] ? parseInt(matches[1], 10) : 0;
        const hours = matches[2] ? parseInt(matches[2], 10) : 0;
        const minutes = matches[3] ? parseInt(matches[3], 10) : 0;
        const seconds = matches[4] ? parseInt(matches[4], 10) : 0;

        const totalHours = days * 24 + hours;
        const totalMinutes = totalHours * 60 + minutes;
        const totalSeconds = totalMinutes * 60 + seconds;

        if (totalHours > 0) {
            return `${String(totalHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        } else if (totalMinutes > 0) {
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        } else {
            return `00:${String(seconds).padStart(2, '0')}`;
        }
    }

    return (
        <div className={style.video} onClick={() => onClick(id, item)}>
            <div className={style.thumb}>
                <img src={src} />
                <div className={style.thumbDuration}>{live ? "LIVE" : convertISO8601DurationToTime(duration)}</div>
            </div>
            <div className={style.infos}>
                <p>{title}</p>
                <p className={style.channelName}>{channel}</p>
            </div>
        </div>
    )
}

function Fila({ playlist, roomClient }) {
    function onRemove(videoId) {
        roomClient.emit("PLAYLIST_REMOVE", videoId);
    }

    return (
        <div className={style.leftTop}>
            {Object.values(playlist).map((item) => {
                return (
                    <div className={style.playlistItem}>
                        <div className={style.itemThumb}>
                            <img src={item.thumb} />
                        </div>
                        <div className={style.itemData}>
                            <p className={style.itemTitle}>{item.title}</p>
                            <p className={style.itemChannel}>{item.channel}</p>
                            <p className={style.itemUser}>Adicionado por {item.user}</p>
                        </div>
                        <div className={style.playlistTrash} onClick={() => onRemove(item.id)}>
                            <img src={IconTrashWhite} />
                        </div>
                    </div>
                )
            })}
        </div>
    )
}