import SadCatBox from './img/cat/sadBox.png';
import Star from './img/cat/star.png';
import CatChat from './img/cat/catChat.png';
import Floor from './img/cat/floor.svg';
import CatLazy from './img/cat/catLazy.png';
import CatSmile from './img/cat/catSmile.png';
import CatComputer from './img/cat/catComputer.png';

const CatImages = {
    SadCatBox,
    Star,
    Floor,
    CatChat,
    CatLazy,
    CatSmile,
    CatComputer
}

export default CatImages;

export const CatMetadata = {
    name: "Chatty"
};