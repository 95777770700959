import { useEffect, useRef, useState } from "react";
import CatImages, { CatMetadata } from "../../cat";
import { AlertTip } from "../alert/alert";
import styles from './recent.module.css';
import { RemoteImage } from "../storage/img";
import { AnimatePresence, motion } from 'framer-motion';
import TranslatableText from "../../../localization/components/translatableText";
import UIEvents from "../../js/events/ui";
import { isVisible } from "@testing-library/user-event/dist/utils";
import { useLocalUser } from "../../../hooks/useLocalUser";

export function UsersRecent({ client, hidden }) {
    const [userRecent, setUserRecent] = useState({});
    const localUser = useLocalUser(client);

    useEffect(() => {
        function onConversationMessage(data) {
            if(data['user']['idUser'] === localUser.id) return;

            setUserRecent((current) => {
                const novo = { ...current };

                if (novo[data.idChat]) {
                    novo[data.idChat]['count'] += 1;
                    novo[data.idChat]['data'] = data;
                } else {
                    novo[data.idChat] = {
                        type: "CONVERSATION",
                        id: data.idChat,
                        data,
                        count: 1,
                    }
                }

                return novo;
            })
        }

        function onClearRecent(id) {
            setUserRecent((current) => {
                const novo = { ...current };

                delete novo[id];

                return novo;
            })
        }

        UIEvents.on("RECENT_CLEAR", onClearRecent);

        if (client) {
            client.on("CONVERSATION_MESSAGE", onConversationMessage);
        }

        return () => {
            UIEvents.off("RECENT_CLEAR", onClearRecent);

            if (client) {
                client.off("CONVERSATION_MESSAGE", onConversationMessage);
            }
        }
    }, [client])

    const keys = Object.keys(userRecent);

    return (
        <>
            {
                /*
            <AlertTip style={{ bottom: 20, left: 0, right: 0, margin: "0 auto", maxWidth: "max-content", zIndex: 100001 }}>
                <img
                    style={{ verticalAlign: "middle" }}
                    src={CatImages.Star}
                    height={32}
                />
                <span style={{ textAlign: "center " }}><b>Dica do {CatMetadata.name}</b>: Você pode conferir as últimas atualizações dos seus amigos no menu lateral esquerdo.
                </span>
            </AlertTip>
                */
            }

            <AnimatePresence mode="wait">
                {
                    keys.length > 0 ?
                        (
                            <motion.div key={"show"} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={styles.recentsContainer}>
                                <h4><TranslatableText k={"recent"} /></h4>
                                <div className={styles.recentsBox}>
                                    {keys.map((key, i) => {
                                        const value = userRecent[key];

                                        function handleHide() {
                                            setUserRecent((current) => {
                                                const novo = { ...current };

                                                delete novo[key];

                                                return novo;
                                            })
                                        }

                                        switch (value.type) {
                                            case "CONVERSATION":
                                                return (
                                                    <Conversation onClick={handleHide} count={value.count} data={value.data} key={value.id || i} index={i} />
                                                )
                                        }
                                    })}
                                </div>
                                <div className={styles.recentsGradient}></div>
                            </motion.div>
                        )
                        :
                        (
                            <motion.div key={"hidden"} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={styles.recentsContainer}>

                            </motion.div>
                        )
                }
            </AnimatePresence>

        </>
    )
}

function Conversation({ data, count, onClick }) {
    const [tipIsVisible, setTipIsVisible] = useState(false);
    const hideTimer = useRef(null);

    function handleClick() {
        UIEvents.emit("right_menu.location", "chats/" + data.idChat);
        onClick()
    }

    const { user } = data;

    const circleWidth = 54;

    useEffect(() => {
        if(hideTimer.current) {
            clearTimeout(hideTimer.current);
            hideTimer.current = null;
        }
        setTipIsVisible(true);
    }, [data]);

    return (
        <div onClick={handleClick} className={styles.recentBoxItem}>
            <div className={styles.imageContainer}>
                <RemoteImage
                    style={{ width: "100%", height: "100%" }}
                    id={user.idProfilePicture}
                    options={{ height: circleWidth, width: circleWidth }}
                />
            </div>

            <div className={styles.unreadContainer}>
                {count}
            </div>

            <AnimatePresence>
                {
                    tipIsVisible && (
                        <motion.div onAnimationComplete={() => {
                            hideTimer.current = setTimeout(() => {
                                setTipIsVisible(false);
                            }, 3000);
                        }} key={data.idChatMessage} initial={{ opacity: 0, scaleX: "0%", translateY: "-50%" }} transition={{duration: 0.2}} animate={{ opacity: 1, scaleX: "100%" }} exit={{ opacity: 0, scaleX: "0%", translateY: "-50%" }}  className={styles.tipContainer}>
                            <div className={styles.tipContainerArrow} />
                            <span className={styles.tipContainerText}>{data.messageContent}</span>
                        </motion.div>
                    )
                }
            </AnimatePresence>
        </div>
    )
}