import { useEffect, useState } from "react";
import data from "emojibase-data/en/data.json";

export function usePreloadEmotes() {
    const [preloaded, setPreloaded] = useState(null);

    useEffect(() => {
        const preloadEmotes = () => {
          const emotePaths = data
            .flat()
            .map((emote) => "/img/emotes/svg/" + emote.hexcode + ".svg"); // Adjust the property name to match your data structure
    
          emotePaths.forEach((path) => {
            const link = document.createElement("link");
            link.rel = "preload";
            link.href = path;
            link.as = "image";
            document.head.appendChild(link);
          });

          setPreloaded(true);
        };

        if(!preloadEmotes) preloadEmotes();
      }, [preloaded]);
}