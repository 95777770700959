import { ShortCodeToEmote } from "../textarea/emoteDatabase";
import styles from "./emote.module.css";

export function EmoteComponent({ emote, emoteData }) {
  if (emote && !emoteData) {
    emoteData = ShortCodeToEmote(String(emote).substring(1, emote.length - 1));
  }

  if (!emoteData) return null;

  return (
    <img
      style={{
        width: 24,
        height: 24,
      }}
      src={`/img/emotes/svg/${String(emoteData.hexcode).toLowerCase()}.svg`}
      data-name={emote}
      data-type={"emoji"}
      draggable="false"
      alt={emoteData.unicode}
      contentEditable={false}
    />
  );
}

export function EmoteRaw({ hexCode }) {
  return (
    <div
      style={{
        width: 28,
        height: 28,
      }}
      data-src={`/img/emotes/svg/${String(hexCode).toLowerCase()}.svg`}
    ></div>
  );
}
