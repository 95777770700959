import moment from "moment";
import { useEffect, useState } from "react";

export function useRoomData(roomClient) {
    const [roomData, setRoomData] = useState(null);

    useEffect(() => {
        if(roomClient) {
            const onData = (data) => {
                setRoomData(data);
            }

            roomClient.on("data", onData)

            return () => {
                roomClient.off("data", onData);
            }
        }
    }, [roomClient]);

    return roomData;
}

export function useRoomMembers(roomClient) {
    const [members, setMembers] = useState(null);

    useEffect(() => {
        if(roomClient) {
            const onData = (data) => {
                setMembers(data.members);
            }

            function onMemberHeartbeat(data) {
                const {idUser, lastTimeSeen} = data;
                if(members) {
                    setMembers(members => {
                        return members.map(member => {
                            if(member.idUser === idUser) {
                                return {
                                    ...member,
                                    lastTimeSeen
                                }
                            }
                            return member;
                        })
                    });
                }
            }

            roomClient.on("data", onData);
            roomClient.on("MEMBER_HEARTBEAT", onMemberHeartbeat);

            return () => {
                roomClient.off("data", onData);
                roomClient.off("MEMBER_HEARTBEAT", onMemberHeartbeat);
            }
        }
    }, [roomClient, members]);

    const sortedMembers = members ? members.sort((a, b) => {
        const now = moment();
    
        const aInRoom = a.lastTimeSeen ? now.diff(moment(a.lastTimeSeen), "minutes") < 1 : false;
        const bInRoom = b.lastTimeSeen ? now.diff(moment(b.lastTimeSeen), "minutes") < 1 : false;
    
        if (aInRoom && !bInRoom) return -1;
        if (!aInRoom && bInRoom) return 1;
    
        if (a.isAdmin && !b.isAdmin) return -1;
        if (!a.isAdmin && b.isAdmin) return 1;
    
        if (a.pending && !b.pending) return 1;
        if (!a.pending && b.pending) return -1;
    
        if (a.displayname < b.displayname) return -1;
        if (a.displayname > b.displayname) return 1;
    
        return 0;
    }) : null;
    

    return sortedMembers;
}