import { useEffect, useState } from 'react';
import CatImages from '../../../shared/cat';
import LoadingLogo from '../../../shared/components/loading';
import '../../../shared/css/global.css';
import './splash.css';
import Tagg from '@taggapp/taggjs-main';
import randomNumber from '../../../utils/randomNumber';
import { motion } from 'framer-motion';

export default function SplashScreen() {
    const [tips, setTips] = useState(null);
    const [currentTip, setCurrentTip] = useState(null);

    useEffect(() => {
        if (!tips) {
            Tagg.API.GetTips().then((response) => {
                setTips(response.tips);

                if (!currentTip) setCurrentTip(response.tips[randomNumber(0, response.tips.length)])
            }).catch((err) => {
                setTips([]);
            })
        }
    }, [tips])

    return (
        <div className={"start-background"}>
            <div className='flexcol flexcenter divider'>
                <div className='division' style={{ alignItems: "flex-end" }}>
                    <LoadingLogo height={"100px"} />
                </div>
                <div className='division' style={{ alignItems: "flex-start" }}>
                    {
                        currentTip ? (
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} id="tips" className='flexcol flexcenter'>
                                <span className='tipHeader'>DICA DO DIA</span>
                                <span className='tip'>{currentTip.message}</span>

                                <img height={100} src={CatImages.Star} />
                            </motion.div>
                        ) : (null)
                    }
                </div>
            </div>
        </div>
    )
}