import "./components.css";
import { RemoteImage } from "./storage/img";
import GenericProfile from "../img/profile.jpeg";
import { useNavigate } from "react-router-dom";
import TranslatableText from "../../localization/components/translatableText";

export default function Avatar({
  profileId,
  className = "",
  height,
  type,
  id,
  src,
  status,
  style,
  statusStyle,
}) {
  const navigate = useNavigate();

  let classType = "";

  switch (type) {
    case "small":
      classType = "avatar-container-small";
  }

  classType += " " + className;

  if (profileId) {
    classType += " avatar-container-clickable";
  }

  function handleProfile() {
    navigate("/app/user/" + profileId);
  }

  return (
    <div className={"avatar-container " + classType} style={style}>
      {profileId ? (
        <div onClick={handleProfile} className={"imagePlaceholder"}>
          <TranslatableText k={"viewProfile"} />
        </div>
      ) : null}
      <RemoteImage
        style={{ width: "100%", height: "100%" }}
        id={id}
        src={src || GenericProfile}
        options={{ height: height, width: height }}
      />
      {
        status ? (<div style={statusStyle} className={`user-status ${status}`} />) : null
      }
    </div>
  );
}
