import FileUploader from '../fileUploader';
import { RemoteImage } from '../storage/img';
import style from './avatareditable.module.css';

export function AvatarEditable({ editable, handleProfileImage, profileImage, local = false }) {
    const props = {};

    if(local) props['src'] = profileImage;
    else props['id'] = profileImage;    

    if(editable) {
        return (
            <FileUploader onChange={handleProfileImage} accept={"image/gif, image/jpeg, image/png"}>
                <div className={style.imageEditContainer}>
                    <div className={style.imageContainer} editable={String(editable)}>
    
                        <div className={style.imagePlaceholder}>Alterar<br /> imagem</div>
                        {
                            profileImage ? (
                                <div className={style.profileImg}>
                                    <RemoteImage width={120} height={120} alt="" {...props}  />
                                </div>
                            ) : (null)
                        }
                    </div>
                </div>
            </FileUploader>
        )
    }else {
        return (
                <div className={style.imageEditContainer}>
                    <div className={style.imageContainer} editable={String(editable)}>
                        {
                            profileImage ? (
                                <div className={style.profileImg}>
                                    <RemoteImage width={120} height={120} alt="" {...props}  />
                                </div>
                            ) : (null)
                        }
                    </div>
                </div>
        )
    }
    
}

export function BannerEditable({ editable, handleBannerImage, bannerImage, local = false }) {
    const props = {};

    if(local) props['src'] = bannerImage;
    else props['id'] = bannerImage;

    if(editable) {
        return (
            <FileUploader onChange={handleBannerImage} accept='image/gif, image/jpeg, image/png'>
                

                <div className={style.bannerContainer} editable={String(editable)}>
                <div className={style.imagePlaceholder}>
                    <p>Clique para alterar</p>
                </div>
                    {bannerImage ? (
                        <div className={style.bannerImage}>
                            <RemoteImage alt="" {...props} />
                        </div>
                    ) : (null)}
    
                </div>
            </FileUploader>
        )
    }else {
        return (
            <div className={style.bannerContainer} editable={String(editable)}>

                    {bannerImage ? (
                        <div className={style.bannerImage}>
                            <RemoteImage alt="" {...props} />
                        </div>
                    ) : (null)}
    
            </div>
        )
    }
}