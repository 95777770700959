import Logo from '../../shared/components/logo'
import styles from './privacy.module.css'

export function PrivacyPage() {
    return (
        <div className={"start-background"} style={{overflowX: "hidden"}}>
            <div className={styles.container}>
                <div className={styles.logo}>
                <Logo height={60} />
                </div>

                <h1 className={styles.header}>Política de Privacidade do Tagg App</h1>
                <p className={styles.paragraph}>Data da última atualização: <span style={{color: "lightgray"}}>04/12/2023</span></p>

                <h2 className={styles.header}>1. INTRODUÇÃO</h2>
                <p className={styles.paragraph}>Bem-vindo ao Tagg App, a sua plataforma confiável de rede social que conecta pessoas ao redor do mundo. Nós valorizamos profundamente a privacidade e a segurança dos nossos usuários e estamos comprometidos em proteger suas informações pessoais e utilizá-las de maneira responsável.</p>
                <p className={styles.paragraph}>Esta Política de Privacidade foi elaborada para fornecer a você transparência total sobre como suas informações são coletadas, usadas, processadas e compartilhadas ao usar o Tagg App. Nosso objetivo é não apenas cumprir as leis de privacidade aplicáveis, mas também ganhar sua confiança, proporcionando controle e segurança no gerenciamento das suas informações pessoais.</p>
                <p className={styles.paragraph}>Ao acessar ou utilizar o Tagg App, você reconhece que leu, compreendeu e concorda com os termos desta Política de Privacidade. Se você tiver alguma dúvida ou preocupação, estamos sempre disponíveis para ajudar.</p>


                <h2 className={styles.header}>2. INFORMAÇÕES QUE COLETAMOS</h2>
                <h3 className={styles.header}>2.1 Informações fornecidas por você</h3>
                <ul>
                    <li className={styles.paragraph}><strong>Informações de Conta</strong>: Incluem nome, e-mail, senha, data de nascimento, interesses do usuário, imagem de perfil, banner e nome de usuário.</li>
                    <li className={styles.paragraph}><strong>Conteúdo Publicado</strong>: Informações, imagens, comentários e outros conteúdos que você publica no Tagg App.</li>
                    <li className={styles.paragraph}><strong>Comunicações com outros Usuários</strong>: Conversas e interações com outros usuários do Tagg App seja de forma privada ou pública.</li>
                </ul>
                <h3 className={styles.header}>2.2 Informações coletadas automaticamente</h3>
                <ul>
                    <li className={styles.paragraph}><strong>Informações de Uso</strong>: Dados sobre como você utiliza o Tagg App, como preferências e configurações.</li>
                    <li className={styles.paragraph}><strong>Informações de Dispositivo</strong>: Informações sobre o dispositivo que você está utilizando, incluindo o sistema operacional, IP e a versão.</li>
                    <li className={styles.paragraph}><strong>Dados de Localização</strong>: Utilizamos para rastrear possíveis contas invasoras e deixar isso visível em nossa aba de Dispositivos disponível nas configurações.</li>
                </ul>

                <h2 className={styles.header}>3. COMO UTILIZAMOS SUAS INFORMAÇÕES</h2>
                <ul>
                    <li className={styles.paragraph}><strong>Fornecer o Serviço</strong>: Para oferecer e personalizar nossos serviços.</li>
                    <li className={styles.paragraph}><strong>Comunicação</strong>: Para comunicar-se com você sobre atualizações, ofertas e promoções.</li>
                    <li className={styles.paragraph}><strong>Segurança</strong>: Para proteger a segurança e a integridade do Tagg App.</li>
                    <li className={styles.paragraph}><strong>Pesquisa e Desenvolvimento</strong>: Para desenvolver novos recursos e aprimorar o aplicativo.</li>
                </ul>

                <h2 className={styles.header}>4. COMO PROTEGEMOS SUAS INFORMAÇÕES</h2>
                <ul>
                    <li className={styles.paragraph}>Tomamos várias medidas para ajudar a proteger suas informações. Todas as informações enviadas em nossos serviços são criptografadas em estado de trânsito e em estado inativo. Por exemplo, usamos Transport Layer Security ("TLS") para criptografar texto e imagens em trânsito, garantindo que suas comunicações sejam seguras e protegidas. Além disso, utilizamos métodos avançados de criptografia para as senhas, garantindo que elas sejam armazenadas de forma segura em nossos sistemas. Também impomos controles de acesso administrativo e técnico para limitar quais de nossos funcionários e contratados têm acesso a informações pessoais não públicas.</li>
                </ul>

                <h2 className={styles.header}>5. COMPARTILHAMENTO DE INFORMAÇÕES</h2>
                <ul>
                    <li className={styles.paragraph}><strong>Com Outros Usuários</strong>: Suas informações públicas e conteúdos publicados são visíveis para outros usuários.</li>
                    <li className={styles.paragraph}><strong>Com Terceiros</strong>: Podemos compartilhar informações com parceiros para fins de publicidade e análise.</li>
                    <li className={styles.paragraph}><strong>Em Caso de Transação Comercial</strong>: No caso de fusão ou venda da empresa.</li>
                </ul>

                <h2 className={styles.header}>6. SEUS DIREITOS E OPÇÕES</h2>
                <ul>
                    <li className={styles.paragraph}><strong>Acesso e Correção</strong>: Você pode acessar, atualizar ou excluir suas informações a qualquer momento.</li>
                    <li className={styles.paragraph}><strong>Preferências de Compartilhamento</strong>: Você tem o controle sobre suas preferências de compartilhamento e notificações.</li>
                    <li className={styles.paragraph}><strong>Revogação de Consentimento</strong>: Você pode retirar seu consentimento para coleta de dados específicos.</li>
                    <li className={styles.paragraph}><strong>Exclusão de Conta</strong>: Você tem o direito de solicitar a exclusão da sua conta no Tagg App. Ao solicitar a exclusão, todas as suas informações pessoais serão removidas de nossos sistemas, exceto em casos onde somos obrigados a reter dados por motivos legais. Para excluir sua conta, entre em contato com nosso suporte ou use a funcionalidade disponível nas configurações do aplicativo.</li>
                </ul>

                <h2 className={styles.header}>7. ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE</h2>
                <p className={styles.paragraph}>Podemos atualizar esta Política de Privacidade periodicamente. A versão mais recente será sempre postada no nosso aplicativo.</p>

                <h2 className={styles.header}>8. CONTATO</h2>
                <p className={styles.paragraph}>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco em: <a href="mailto:mail@tagg.chat">mail@tagg.chat</a>.</p>
                <p className={styles.paragraph}>Ao utilizar o Tagg App, você concorda com a coleta e o uso de informações de acordo com esta Política de Privacidade.</p>

            </div>

        </div>
    )
}