import styles from './bottomContainer.module.css';
import { motion } from 'framer-motion';

export function BottomContainer({ children }) {
    return (
        <motion.div key={"bottomContainer"} initial={{ bottom: "-64px" }} exit={{ bottom: "-64px" }} animate={{ bottom: "10px" }} className={styles.saveProfileContainer}>
            <div className={styles.container}>
                {
                    children
                }
            </div>
        </motion.div>
    )
}