import ptBR from './pt-BR';
import enUS from './en-US';

export const LOCALE = {
    'pt-BR': {
        name: "Português (Brasil)",
        keys: ptBR
    },
    'en-US': {
        name: "English (United States)",
        keys: enUS
    }
}